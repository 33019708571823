.modal {
  &__points-button {
    background: #F9D606;
    border-radius: 30px;
    height: 64px;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #333333;
    max-width: 264px;
    min-width: 264px;
    border: none;
    cursor: pointer;
    margin-top: 64px;
  }

  &__points-text {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
  }

  &__points-title {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
  }
}

.css-1wnsr1i {
  border: none !important;
  transform: translate(-50%, 0%) !important;
  bottom: 0;
  outline: none !important;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25) !important;
  border-radius: 16px;
  width: 100% !important;
}

@media screen and (min-width: 1366px) {
  .css-1wnsr1i {
    width: 600px !important;
    height: 330px !important;
    transform: translate(-50%, -50%) !important;
    top: 50% !important;
  }
}
