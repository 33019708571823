@import '~@styles/colors.scss';
@import '~@styles/variables.scss';
@import '~@styles/responsive-design.scss';
@import '~@styles/mixins.scss';

.AppPage {
  position: absolute;
  top: $menuHeight;
  left: 0;
  z-index: 9999;
  background-color: $alabaster;
  width: 100%;
  height: calc(100% - #{$menuHeight});
  display: flex;

  &--desktop-scroll {
    &:after {
      content:'';
      position: absolute;
      z-index: -1;
      height: calc(100% - 50px);
      top: 25px;
      right: 15px;
      width: 2px;
      background: $gallery;
    }

    .AppPage__Container--desktop {
      margin-right: 15px;
    }
  }

  .AppPage__SideBar {
    overflow-y: auto;
    overflow-x: hidden;
    @include scrollbar();

    @include media('<desktop') {
      display: none;
    }
  }

  &__Container {
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
    height: auto;
    width: 100%;

    &--desktop {
      @include scrollbar2();
    }
  }

  &__Content {
    width: 100%;
    flex: 1;
  }

  &__Title {
    @include text(28px, $shark, 28px, bold);
  }
}
