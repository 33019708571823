.BusinessPromotion {
  padding: 10px;
  border-radius: 60px;
  background-color: white;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.25);
}

.main-bl {
  display: none !important;
}

.BusinessPromotion--opened {
  padding: 30px 30px 15px 30px;
  border-radius: 20px;
}

.BusinessPromotion--hidden {
  display: none;
}

.BusinessPromotion__title {
  font-family: "Muller";
  font-size: 24px;
  line-height: 24px;
  text-align: left;
  font-weight: 500;
  color: #222222;
  margin: 0;
  margin-bottom: 15px;

  &-slider {
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    color: #333333;
    padding: 0 20px;
    margin-bottom: 33px;
    margin-top: 30px;
  }
}

.BusinessPromotion--feature {
  background: #5000C1;
  box-shadow: 0px 4px 15px rgba(6, 6, 6, 0.15);
  border-radius: 10px;
  width: 100%;
  height: 193px;
  margin-bottom: 36px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 14px;
  cursor: pointer;

  div {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: #FFFFFF;
  }

  h3 {
    font-style: normal;
    font-weight: 800;
    font-size: 30px;
    line-height: 30px;
    color: #FFFFFF;

  }
}

.BusinessPromotion__description {
  font-size: 16px;
  color: #333333;
  font-weight: 400;
  text-align: left;
  line-height: 19px;
  margin-bottom: 30px;
}

.BusinessPromotion--minimized {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 136px;
}

@media (max-width: 800px) {
  .BusinessPromotion--minimized {
    width: 100%;
  }

  .business-promotion-container {
    padding: 0 20px;
    padding-top: 20px;

    .search {
      margin-top: 20px;
      margin-bottom: 20px;
      position: relative;

      .search__icon {
        position: absolute;
        right: 10px;
      }
    }
  }
}

@media (min-width: 800px) {
  .business-promotion-container {

    .search {
      display: none;
    }

    .BusinessPromotion--feature {
      display: none;
    }
  }

  .main-bl {
    display: block !important;
  }

  .BusinessPromotion--feature {
    width: 335px;
    right: 30px;
    top: 125px;
    position: absolute;
    z-index: 2;
  }
}

.BusinessPromotion--maximized {
  width: calc(305px - 60px);
  margin-top: 90px;
}

@media (max-width: 800px) {
  .BusinessPromotion--maximized {
    width: 100%;
  }
}

.BusinessPromotion--minimized .BusinessPromotion__button {
  width: 40px;
  height: 40px;
  max-width: 40px;
  max-height: 40px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: none;
  border: none;
}

.BusinessPromotion--maximized .BusinessPromotion__button {
  color: black;
  height: 40px;
  padding-top: 8px;
  padding-bottom: 8px;
  box-shadow: none;
  border: none;
}

.BusinessPromotion--minimized .BusinessPromotion__button+.BusinessPromotion__button {
  margin-left: 10px;
}

.BusinessPromotion--minimized .BusinessPromotion__button-group {
  display: inline-flex;
}

.BusinessPromotion--minimized .BusinessPromotion__trigger .BusinessPromotion__trigger-text {
  display: none;
}

.BusinessPromotion--minimized .BusinessPromotion__trigger .BusinessPromotion__trigger-icon {
  transform: scale(1.2) translate(4px);
}

.BusinessPromotion--maximized .BusinessPromotion__button-group {
  display: flex;
  flex-direction: column;

  button {
    margin-bottom: 24px;
    text-align: left;
    display: flex;
    justify-content: flex-start;
    height: 60px;
    background-color: #FFFFFF;
    border: 1px solid #333333;
    border-radius: 30px;
  }

  a {
    text-decoration: none;

    button {
      background: #F5F5F5;
    }
  }
}

.BusinessPromotion--maximized .BusinessPromotion__button-text {
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;
  color: #222222;
  margin-left: 12px;
}

.BusinessPromotion--maximized .BusinessPromotion__button+.BusinessPromotion__button {
  margin-top: 10px;
  margin-bottom: 15px;
}

.BusinessPromotion--minimized .BusinessPromotion__button--yellow,
.BusinessPromotion--maximized .BusinessPromotion__button--yellow {
  background-color: #F9D606;
}

.BusinessPromotion__button--grey {
  background-color: #333333;
  opacity: 0.05;
}


.BusinessPromotion__trigger {
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
  color: #222222;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: auto;
}

@media (max-width: 800px) {
  .BusinessPromotion__trigger {
    width: auto;
    margin-right: 5px;
  }
}

.BusinessPromotion--maximized .BusinessPromotion__trigger {
  height: 12px;
  font-size: 12px;
  width: 100%;
}


.BusinessPromotion__trigger-icon {
  transform: scale(1.2);
}

.BusinessPromotion--maximized .BusinessPromotion__trigger-icon {
  margin-left: 8px;
  transform: rotate(180deg) translateY(1px);
}

.BusinessPromotion__description-add-dot {
  display: none;
}

@media (max-width: 800px) {
  .BusinessPromotion--minimized .BusinessPromotion__trigger .BusinessPromotion__trigger-text {
    display: inline-block;
  }

  .BusinessPromotion--minimized .BusinessPromotion__trigger .BusinessPromotion__trigger-icon {
    margin-left: 10px;
    margin-right: 5px;
  }
}

.BusinessPromotion__trigger:hover {
  opacity: 0.6;
}

@media (min-width: 800px) {
  .business-promotion-container {
    max-width: 390px;
    position: relative;
    height: 100vh;
    padding: 52px 20px;
  }

  .search {
    padding: 0;
  }

  .sc-htpNat.bDLZdG {
    height: 100vh;
    position: absolute;
    right: -392px !important;
    order: 2;

    .noList & {
      position: relative !important;
      width: 100%;
      right: 0 !important;
    }
  }

  .map_container {
    height: 100%;
  }

  .services-block-wrapper {
    left: 50%;
    top: 200px;
    height: 200px;
    width: 500px;
  }

  .BusinessPromotion--maximized {
    width: 100%;
    padding: 0;
  }

  .BusinessPromotion__description-add-dot {
    display: block;
    margin-top: 10px;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 20px;
    font-family: 'Muller';
  }
}
