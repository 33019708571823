@import '~@styles/mixins.scss';
@import '~@styles/colors.scss';

.Link, .Link--gold {
  @include text(16px, $mineShaft, 16px, 500);
  text-decoration: none;
  white-space: nowrap;
  transition: all 0.2s ease-out;
  border: none;
  outline: none;
  cursor: pointer;
  background-color: transparent;
  text-align: left;
  
  &:hover {
    color: $doveGray;
  }

  &--gold {
    color: $gold;
    text-decoration: underline;
    font-weight: 400;

    &:hover {
      color: $mineShaft;
    }
  }
}

.LargeButton {
  border-radius: 30px;
  height: 60px;
  font-size: 18px;
  line-height: 18px;
  font-weight: 500;

  &:not(:disabled) {
    box-shadow: 0px 2px 5px rgba(249, 214, 6, 0.3);
  }
}

.RubleIcon {
  width: auto;
  height: 0.6875em;
}
