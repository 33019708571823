$black: #000;
$white: #fff;
$mineShaft: #333;
$shark: #222;
$gold: #F9D606;
$doveGray: #666666;
$silver: #bababa;
$silverChalice: #B0B0B0;
$wildSand: #f4f4f4;
$gallery: #EBEBEB;
$alabaster: #FBFBFB;
$cornflower: #F5F5F5;
$cinnabarapprox: #EA4335;
$success: #00B47E;
$flamingo: #F45028;
$jungleGreen: #33B576;
$mercury: #e5e5e5;
$frenchGray: #cccccc;
$blackMiddle: #060606;
$superGray: #323F4B;
$middleGray: #DCDCDC;
