@import '~@styles/mixins.scss';
@import '~@styles/colors.scss';
@import '~@styles/responsive-design.scss';


.PrizeDialog {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 40px 0;

  @include media('<tablet') {
    padding: 10px 0;
  };

  &__Content {
    display: flex;
    margin-bottom: 60px;
    max-width: 520px;
    width: 100%;

    @include media('<tablet') {
      flex-direction: column;
      align-items: center;
      margin-bottom: 25px;
    };

    .UserPrizeDialog & {
      margin-bottom: 0;
    }
  }

  &__Title {
    @include text(10px, $mineShaft, 1);
    margin: 10px 0;
    opacity: 0.3;

    @include media('<tablet') {
      display: none;
    };
  }

  &__Text {
    white-space: pre-wrap;
    @include text(16px, $mineShaft, 1.2);

    @include media('<tablet') {
      font-size: 15px;
    };
  }

  &__Image {
    margin-right: 25px;
    width: 100%;
    max-width: 170px;

    @include media('<tablet') {
      margin-right: 0;
      margin-bottom: 25px;
    };

    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  &__Button {
    max-width: 520px;

    svg {
      position: relative;
      top: -2px;
      margin: 0 10/21 + em;
    }

    & + & {
      margin-top: 15px;
    }
  }
}
