.team {
  &__wrapper-img {
    width: 160px;
    height: 160px;
    border-radius: 50%;
    overflow: hidden;
    margin-bottom: 40px;
  }

  &__card {
    margin-bottom: 40px;
  }

  &__name {
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
    color: #222222;
  }

  &__profession {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #222222;
  }

  &__wrapper {
    display: flex;
    flex-wrap: wrap;
  }
}

@media screen and (min-width: 600px) {
    .team {
        &__card {
            margin-right: 40px;
            width: 360px;
        }
    }
}

@media screen and (min-width: 1200px) {
    .team {
        &__card {
            &:nth-child(3) {
                margin-right: 0;
            }
        
            &:last-child {
                margin-right: 0;
            }
          }
    }
}

@media screen and (min-width: 1366px) {
    .team {
       .CONTAINER {
           max-width: 1200px;
           margin: 0 auto;
       }

       &__wrapper {
           margin-bottom: 196px;
       }
    }
}