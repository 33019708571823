@import '~@styles/mixins.scss';
@import '~@styles/colors.scss';
@import '~@styles/variables.scss';
@import '~@styles/responsive-design.scss';

.prix {
  margin-bottom: 50px;

  .prix_in__title {
    margin-bottom: 30px;
    margin-top: 15px;
  }

  a.default {
    background: #ffffff;
    border: 1px solid #060606;
    margin-bottom: 10px;
  }

  &__price-text {
    color: #777777;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
  }

  &__container {
    padding: 0 20px;
  }

  p {
    font-family: 'CoFo Robert';
    font-weight: 400;
    font-size: 20px;
    line-height: 120%;
    color: #000000;
  }
}

@media (min-width: 1200px) {
  .prix {
    &__wrapper {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin-top: 50px;
    }

    .prix_in__title {
      margin-top: 0;
    }

    .prix__container {
      max-width: 1170px;
      margin: 0 auto;
    }

    &__left-block {
      max-width: 570px;
    }

    &__right-block {
      order: 2;
      width: 40%;
      height: auto;
    }

    .default {
      display: none;
    }

    .prix_in__nav {
      width: 340px;
      margin-top: 80px;
      cursor: pointer;
    }

    &__price-text {
      display: none;
    }
  }
}
