@import '~@styles/colors.scss';
@import '~@styles/responsive-design.scss';
@import '~@styles/mixins.scss';

.input-wrapper-field {
  width: 100% !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.dots-stage_2 {
  &__desc {
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;

    &.social,
    &.small {
      margin-top: 35px !important;
    }
  }

  &__formControl {
    margin-bottom: 0px !important;

    .dots-stage_2 & {
      margin-bottom: 68px !important;
    }
  }

  &__img {
    margin-bottom: 40px;
  }
}

@media (min-width: 1366px) {

  .dots-stage_2__map {
    height: 300px;
    position: relative;
    overflow-y: hidden;

    .sc-htpNat {
      top: 0 !important;
      left: 0 !important;
      right: 0 !important;
      position: relative;
    }
  }

  .dots-stage_2 {
    margin-bottom: 100px;
  }

  .dots-stage_2__desc {
    font-size: 24px;
    line-height: 24px;

    &.desktop {
      font-weight: 700;
      font-size: 32px;
      line-height: 38px;
      margin-bottom: 35px;
    }
  }

  .dots__radio {
    margin-bottom: 15px;
  }

  .dots-stage_2 .dots__save-late {
    display: none;
  }
}
