@import '~@styles/colors.scss';
@import '~@styles/mixins.scss';

$maxWidth: 237px;
$width: 40px;
$height: 40px;

.ShrinkingButton {
  cursor: pointer;
  height: $height;
  width: $width;
  border-radius: 20px;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: $mineShaft;
  transition: width 500ms ease-in-out, background-color 300ms ease;
  border: none;
  @include center();
  outline: none;
  padding: 0;
  overflow: hidden;
  position: relative;

  &__Container {
    + .ShrinkingButton__Container {
      padding-left: 10px;
    }
  }

  &__Icon {
    @include fixedSize($width, $height);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    transition: background-color 300ms ease;
    z-index: 9999;
    position: absolute;
    left: 0;
  }

  &:not(.ShrinkingButton--highlighted) {
    .ShrinkingButton__Icon {
      background-color: inherit;
    }
  }

  &--highlighted {
    &:hover {
      background-color: $shark;
      color: $white;

      .ShrinkingButton__Icon {
        color: $white;
      }
    }
  }

  &--hovered {
    width: $maxWidth;
    display: flex;
    justify-content: flex-start;

    .ShrinkingButton__Content {
      width: 182px;
      overflow: visible;
      opacity: 1;
      display: flex;
      white-space: nowrap;
      transition: opacity 400ms ease 100ms;
    }
  }
  
  &__Content {
    width: 0;
    overflow: hidden;
    opacity: 0;
    display: none;
    transition: opacity 300ms ease;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin: 0 40px 0 40px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  
  &--primary {
    background-color: rgb(245, 245, 245);
  }

  &--secondary {
    background-color: $gold;
  }
}
