@import '~@styles/colors.scss';
@import '~@styles/mixins.scss';
@import '~@styles/responsive-design.scss';


.TaskPreviewDialog {
  &__Root {
    @include media('<tablet') {
      .UIDialog__Root {
        padding: 0;
        background-color: transparent;
        box-shadow: none;
      }

      .UIDialog__Close-Icon {
        top: 25px;
        right: 25px;
      }
    }
  }

  &__Content {
    flex-direction: column;
    align-items: center;
    display: flex;
    padding-top: 40px;

    @include media('<tablet') {
      padding-top: 0;
    }
  }

  &__Segment {
    margin-bottom: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    @include media('<tablet') {
      margin-bottom: 15px;
      padding: 45px 20px 30px;
      background-color: $white;
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
      border-radius: 5px;
    }
  }

  &__Title {
    @include text(28px, $shark, 28px, 800);
    margin: 0 0 10px;
    width: 100%;
    max-width: 520px;

    @include media('<tablet') {
      font-size: 24px;
    }
  }

  &__Description {
    @include text(16px, $shark, 20px);
    margin-bottom: 60px;
    width: 100%;
    max-width: 520px;

    p {
      > * {
        width: 100%!important;
      }

      &:first-child {
        margin-top: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }

    @include media('<tablet') {
      margin-bottom: 30px;
      font-size: 15px;
    }
  }

  &__Button {
    @include text(18px, $shark, 18px, 500);
    width: 100%;
    max-width: 520px;
    white-space: normal;

    @include media('<tablet') {
      width: auto;
    }
  }
}
