@import '~@styles/colors.scss';
@import '~@styles/responsive-design.scss';

@mixin hamburgerInner {
  position: absolute;
  width: 20px;
  height: 2px;
  transition-timing-function: ease;
  transition-duration: .15s;
  transition-property: transform;
  border-radius: 0px;
  background-color: $black;
}

.Hamburger {
  font: inherit;
  display: inline-block;
  overflow: visible;
  margin: 0;
  cursor: pointer;
  transition-timing-function: linear;
  transition-duration: .15s;
  transition-property: opacity, filter;
  text-transform: none;
  color: inherit;
  border: 0;
  background-color: transparent;

  &__Box {
    position: relative;
    display: inline-flex;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  &--hidden {
    display: none;
  }

  @include media('>=tablet') {
    &.Hamburger--active {
      .Hamburger__FirstInner {
        &:before {
          transform: translate3d(-4.3px, 2.4px, 0) rotate(-45deg) scaleX(0.6);
        }
        &:after {
          transform: translate3d(-4.3px, -2.4px, 0) rotate(45deg) scaleX(0.6);
        }
      }
      .Hamburger__SecondInner {
        &:before {
          transform: translate3d(4px, -4px, 0) rotate(-45deg) scaleX(0.62);
        }
        &:after {
          transform: translate3d(4px, 4px, 0) rotate(45deg) scaleX(0.62);
        }
      }
    }
  }

  @include media('<tablet') {
    &.Hamburger--active {
      .Hamburger__FirstInner {
        transition-delay: .12s;
        transition-timing-function: cubic-bezier(.215,.61,.355,1);
        transform: rotate(45deg);

        &:before {
          top: 0;
          transition: top 75ms ease,opacity 75ms ease .12s;
        }
        &:after {
          bottom: 0;
          transition: bottom 75ms ease,transform 75ms cubic-bezier(.215,.61,.355,1) .12s;
          transform: rotate(-90deg)
        }
      }
      .Hamburger__SecondInner {
        display: none;
      }
    }
  }

  &__FirstInner, &__SecondInner {
    top: 50%;
    display: block;
    margin-top: -2px
  }

  &__FirstInner {
    @include hamburgerInner();
    background-color: transparent;
    &:before, &:after {
      @include hamburgerInner();
      display: block;
      content: ""
    }

    &:before {
      top: -5px;

      @include media('<tablet') {
        transition: top 75ms ease .12s,opacity 75ms ease;
      }
    }
  
    &:after {
      bottom: -7px;

      @include media('<tablet') {
        transition: bottom 75ms ease .12s,transform 75ms cubic-bezier(.55,.055,.675,.19)
      }
    }
  }

  &__SecondInner {
    @include hamburgerInner();
    height: 0;
    &:before, &:after {
      @include hamburgerInner();
      top: 1px;
      display: block;
      content: ""
    }
  }
}
