.slick-slider {
  margin-bottom: 55px;
}

.slide-prizes__wrapper {
  display: flex;
}

.slide-prizes__title {
  width: 160px;
  margin-bottom: 20px;
  color: #060606;
}

.slider-prizes {
  .slick-arrow {
    display: none !important;
  }
}

.slide-prizes__status {
  border: 1px solid #000000;
  border-radius: 6px;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  height: 25px;
  text-align: center;
  padding: 5px 0;
}

.slide__wrapper-prizes-image {
  margin-right: 20px;
}

.slide {
  &__title {
    font-size: 16px;
    line-height: 100%;
    text-align: center;
    color: #333333;
    font-family: 'Muller';
    font-style: normal;
    font-weight: 400;
  }

  &__wrapper-prizes-image {
    width: 88px;
    height: 90px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__wrapper-image {
    height: 70px;
    width: 70px;
    margin-bottom: 18px;
    cursor: pointer;
  }

  &__image {
    width: 100%;
    height: 100%
  }
}

;

.slick-dots {
  bottom: -30px;

  li {
    height: 8px;
    width: 8px;
    list-style: none;
  }

  li.slick-active button {
    border: none;
    background: #C4C4C4;
    border: 1px solid transparent;
  }

  li button {
    width: 8px;
    height: 8px;
    font-size: 10px;
    border: 1px solid #000000;
    border-radius: 50%;
    position: relative;

    &:before {
      height: 8px;
      width: 8px;
      background: transparent;
      font-size: 0;
    }
  }
}

.slick-next {
  background-image: url(./Shape.svg) !important;
  background-repeat: no-repeat !important;
  background-position-x: center !important;
  right: -8px;
  top: 35px;

  &::before {
    content: none;
  }

  &:hover,
  &:focus {
    color: black !important;
  }
}

.slick-prev {
  background-image: url(./Shape.svg) !important;
  background-repeat: no-repeat !important;
  background-position-x: center !important;
  left: -8px;
  top: 25px;
  transform: rotate(180deg);

  &::before {
    content: none;
  }

  &:hover,
  &:focus {
    color: black !important;
  }
}

.slider-tasks {
  .slide__title {
    text-align: left;
  }

  .slide__description-block {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
  }

  .slide__points-block {
    margin-right: 30px;
  }

  .slick-next {
    right: -8px;
    top: 106%;
    transform: none !important;
  }

  .slick-prev {
    left: -8px;
    top: 105%;
    transform: none !important;
    transform: rotate(180deg) !important;
  }
}

.slider__title {
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
}

.slide__points {
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  font-family: 'Muller';
  color: #999999;

}

.buttonTask {
  display: none;
}

.slide__text p span,
.slide__text span {
  font-weight: 700;
  font-size: 16px !important;
  line-height: 140%;
}

.slider-poi {
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  margin-bottom: 5px;
}

@media (min-width: 800px) {
  .buttonTask {
    background: #F5F5F5;
    border-radius: 20px;
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Muller';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    text-decoration: none;
    color: #333333;
    margin-top: 10px;
  }
}

.scrollBar>div {
  margin-right: 0 !important;
  margin-bottom: 0 !important;
}

.slider-prizes {
  .slick-list {
    width: 400px;
  }
}

.slider-feature {
  border-radius: 10px;
  cursor: pointer;
  max-width: 350px;
  width: 350px;
  height: 190px;

  .slide__wrapper-image {
    height: 100%;
    border-radius: 10px;
    width: 100%;
    object-fit: cover;
    overflow: hidden;

    img {
      object-fit: contain;
    }
  }

  .slide__title {
    color: white;
  }

  .slick-prev {
    top: 80px;
    left: -20px;
    background: white;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-position-y: 10px;
    background-size: 10px;
    z-index: 1;
  }

  .slick-next {
    top: 100px;
    right: -20px;
    background: white;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-position-y: 10px;
    background-size: 10px;
    z-index: 1;
  }
}

.Main {
  .slider-feature {
    position: absolute;
    right: 20px;
    top: 120px;

    .slick-dots {
      & li.slick-active {
        & button {
          background: #000000;
        }
      }
    }
  }
}

@media screen and (min-width: 801px) {
  .slider-feature {
    display: block;
  }
}

@media screen and (max-width: 800px) {
  .slider-feature {
    display: none;
  }
}

.business-promotion-container {
  .slider-feature {
    position: static;

    .slick-next {
      top: 92px;
      right: 0;
      display: none !important;
    }

    .slick-prev {
      top: 180px;
      left: 0;
      display: none !important;
    }

    .slick-dots {
      top: 215px !important;
      left: 0;
      & li.slick-active {
        & button {
          background: #000000;
        }
      }
    }
  }

  @media screen and (max-width: 800px) {
    .slider-feature {
      display: block;
      width: 100%;
      margin: 0 auto;
      margin-bottom: 40px !important;
      margin-top: 0px !important;

      .slick-prev {
        top: 80px;
        left: 0;
        z-index: 1;
      }
  
      .slick-next {
        top: 92px;
        right: 0;
        z-index: 1;
      }
  
      .slick-dots {
        top: 180px;
        left: 0;
      }
    }
  }

  @media screen and (min-width: 801px) {
    .slider-feature {
      display: none;
    }
  }
}