p.accordion-header {
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  color: #000000;
}

p.accordion-content {
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  color: #000000;
}

.accordion-button-plus {
  background: #F9D606;
  border-radius: 50%;
  width: 33px;
  height: 33px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;

  svg {
    width: 35px;
    height: 20px;
    font-size: 65px;
  }
}

.expanded-acc {
  .accordion-button-plus {
    background: #060606;

    svg {
      transform: rotate(45deg);

      line {
        stroke: #ffffff;
      }
    }
  }
}

@media screen and (min-width: 1366px) {
    .ask {
        .CONTAINER {
            max-width: 1170px;
            margin: 0 auto;
        }
    }
}
