@import '~@styles/colors.scss';
@import '~@styles/mixins.scss';
@import '~@styles/responsive-design.scss';


.TaskDialog {
  display: flex;
  justify-content: center;

  &__Content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 0;
    width: 100%;
    max-width: 520px;

    @include media('<tablet') {
      padding: 25px 0 10px;
    };
  }

  &__Status {
    position: relative;
    height: 0;

    .Shield {
      position: absolute;
      top: -10px;
      left: -50px;
    }

    &--message {
      display: flex;
      align-items: center;
      height: 40px;
      color: $flamingo;

      .Shield {
        top: 0;
      }
    }
  }

  &__Title {
    @include text(28px, $shark, 28px, 800);
    margin: 0;
    margin-bottom: 15px;
    width: 100%;
    max-width: 500px;

    @include media('<tablet') {
      margin-bottom: 2px;
      font-size: 24px;
    };
  }

  &__Description {
    @include text(16px, $shark, 20px);
    margin-bottom: 25px;
    width: 100%;
    max-width: 500px;

    @include media('<tablet') {
      font-size: 15px;
      line-height: 1;
    };

    p {
      > * {
        width: 100%!important;
      }

      &:first-child {
        margin-top: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }

    .TaskDialog__Confirm & {
      margin-bottom: 0;
    }
  }

  &__Text {
    @include text(16px, $shark, 1.2);
    @include media('<tablet') {
      font-size: 15px;
    };
  }

  &__Dropzone, &__Image {
    margin-top: 10px;
  }

  &__Image {
    border-radius: 5px;
    overflow: hidden;

    img {
      display: block;
      width: 100%;
    }
  }

  &__Message {
    margin-bottom: 20px;
    width: 100%;
    max-width: 500px;
  }

  &__List {
    margin: 0;
    padding: 0;
    width: 100%;
    max-width: 500px;
    list-style: none;

    li {
      margin-bottom: 30px;

      @include media('<tablet') {
        margin-bottom: 25px;
      };

      &:last-child {
        margin-bottom: 0;
      }
    }

    & + & {
      margin-top: 20px;
    }
  }

  &__Controls {
    padding-top: 60px;
    width: 100%;

    @include media('<tablet') {
      padding-top: 30px;
    };

    button {
      width: 100%;

      & + button {
        margin-top: 15px;
      }
    }
  }
}
