@import '~@styles/colors.scss';
@import '~@styles/responsive-design.scss';
@import '~@styles/mixins.scss';
@import '~@styles/variables.scss';

.UIDialog {
  position: absolute;
  z-index: 10000;
  right: 0px;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: calc(100% - 60px);
  overflow: auto;

  &__Content {
    padding: 0 10px;
    text-align: left;
    
    @include media('<tablet') {
      padding: 0;
    }
  }

  &__Title {
    @include text(28px, $shark, 30px, bold);
    margin: 0 0 10px;

    @include media('<tablet') {
      @include text(24px, $shark, 24px, bold);
    }
  }

  &__Description {
    @include text(16px, $mineShaft, 21px);
    margin: 0 0 60px;

    @include media('<tablet') {
      margin: 0 0 30px;
      @include text(15px, $mineShaft, 18px);
    }
  }

  &__Row {
    display: flex;
    justify-content: center;
  }

  &__Wrapper {
    padding: 40px 113px 25px 112px;

    @include media('>=tablet', '<desktop') {
      padding: 40px 77px 25px 81px;
    }
  
    @include media('<tablet') {
      padding: 25px 0 0;
    }
  }

  &__Container {
    height: 100%;
    outline: 0;
    overflow-x: hidden;
    overflow-y: auto;
    text-align: center;

    &:after {
      width: 0;
      height: 100%;
      content: "";
      display: inline-block;
      vertical-align: middle;

      @media (pointer:none), (pointer:coarse) {
        margin-left: 0px;
      }
    }

    @include scrollbar();
  }

  &:not(.UIDialog--blurred) {
    background-color: $gold;
  }

  &__Root {
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    width: 100vw;
    max-width: 785px;
    padding: 20px;
    background-color: $white;
    display: inline-block;
    text-align: left;
    vertical-align: middle;
    margin: 25px 0;
    position: relative;
    overflow-y: auto;
  
    @include media('>=tablet', '<desktop') {
      width: calc(100vw - 50px);
    }
  
    @include media('<tablet') {
      margin: 23px 0 15px;
      width: calc(100vw - 30px);
    }
  }

  &__Close-Icon {
    @include fixedSize(15px);
    color: $doveGray;
    position: absolute;
    right: 29.5px;
    top: 29.5px;
    cursor: pointer;

    &:hover {
      color: $black;
    }
  }
}

.UIDialog--blurred {
  &.UIDialog-enter,
  &.UIDialog-exit-done {
    backdrop-filter: blur(0);
    .UIDialog__Content {
      opacity: 0;
    }
  }
  &.UIDialog-enter-active {
    backdrop-filter: blur(45px);
    transition: backdrop-filter 0ms linear;
    .UIDialog__Content {
      opacity: 1;
      transition: opacity 0ms ease;
    }
  }
  &.UIDialog-enter-done,
  &.UIDialog-exit {
    backdrop-filter: blur(45px);
    .UIDialog__Content {
      opacity: 1;
    }
  }
  &.UIDialog-exit-active {
    backdrop-filter: blur(0);
    transition: backdrop-filter 0ms linear;
    .UIDialog__Content {
      opacity: 0;
      transition: opacity 0ms ease;
    }
  }
}

