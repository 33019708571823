@import '~@styles/mixins.scss';

.PasswordInput {
  &__Button {
    position: absolute;
    right: 1px;
    bottom: 4px;
    border: none;
    background-color: transparent;
    padding: 0;
    @include size(24px);
    outline: none;
    cursor: pointer;
    color: $mineShaft;
    opacity: 0.5;

    &:hover {
      opacity: 1;
    }
  }
}