@import '~@styles/mixins.scss';
@import '~@styles/colors.scss';
@import '~@styles/variables.scss';
@import '~@styles/responsive-design.scss';

.prizes {
  width: 100%;
  margin-bottom: 100px;

  a {
    text-decoration: none;
  }

  &__title {
    font-size: 20px;
    line-height: 100%;
    letter-spacing: 0.01em;
    color: #060606;
    padding-bottom: 20px;
    border-bottom: 1px solid #060606;
    margin-bottom: 24px;
    margin-top: 20px;
  }

  &__price {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 16px;
    border-bottom: 1px solid #060606;
    margin-bottom: 10px;
  }

  &__block-price {
    display: flex;
  }

  &__logo {
    margin-right: 15px;
  }

  &__number {
    font-weight: 700;
    font-size: 25px;
    line-height: 30px;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: #060606;
    display: flex;

    &__euro {
      margin-left: 6px;
    }
  }

  &__title-your-prize {
    font-size: 30px;
    line-height: 30px;
    letter-spacing: 0.01em;
    color: #060606;
    font-weight: 350;
    margin-bottom: 20px;
  }

  &__title-popular {
    font-weight: 700;
    font-size: 30px;
    line-height: 30px;
    color: #060606;
    margin-bottom: 35px;
  }

  &__cards {
    display: flex;
    margin-bottom: 30px;
  }

  &__container {
    padding: 0 20px;
  }

  &__card {
    height: 384px;
    min-width: 256px;
    border: 0.5px solid #777777;
    padding: 40px;
    padding-bottom: 34px;
  }

  &__subtitle {
    color: #060606;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 14px;
  }

  &__your {
    font-size: 14px;
    line-height: 17px;
    color: #777777;
    font-weight: 400;
    margin-bottom: 12px;
  }

  &__price-e {
    font-weight: 700;
    font-size: 25px;
    line-height: 30px;
    display: flex;
    color: #060606;

    svg {
      height: 15px;
    }
  }

  &__card-price {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__cards-default {
    display: flex;
    flex-wrap: wrap;
  }

  &__card-default {
    width: 50%;
    border: 0.5px solid #999999;
    padding: 28px 20px;
    height: 300px;
    cursor: pointer;

    &.late {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 28px;

      div {
        text-align: center;
      }
    }
  }

  &__clock {
    margin-bottom: 26px;
    margin-top: 20px;
  }

  &__title-text {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #060606;
    margin-bottom: 8px;
  }

  &__title-purchases {
    color: #3577EA;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 14px;
  }

  &__title-number {
    color: #060606;
    font-size: 20px;
    line-height: 24px;
    display: flex;

    svg {
      height: 12px;
    }
  }

  &__slide-img {
    margin-bottom: 10px;
  }

  &__card-wrapper-img {
    width: 140px;
    height: 140px;
    margin-bottom: 22px;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

@media (min-width: 1024px) {
  .prizes {
    .mobile {
      display: none;
    }

    &__tabs-list {
      display: flex;
    }

    &__tabs-item {
      margin-right: 50px;

      a {
        font-size: 20px;
        line-height: 20px;
        text-transform: uppercase;
        color: #999999;

        &.active {
          color: #060606;
        }
      }
    }

    &__tabs {
      border-bottom: 1px solid #060606;
      margin-bottom: 30px;
    }

    &__card {
      border: none;
      width: 19%;
      min-width: auto;
      max-width: 13.572%;
      box-sizing: content-box;
      height: auto;
    }
  }
}
