@import '~@styles/colors.scss';
@import '~@styles/mixins.scss';


.Shield {
  @include text(11px, transparentize($black, 0.3), 11px);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: $cornflower;

  &__Icon {
    width: 20px;
    height: 20px;
  }

  &--secondary {
    background-color: $gold;
  }

  &--alert {
    color: $white;
    background-color: $flamingo;
  }
}
