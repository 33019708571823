.smi {
  width: 100%;
}

.qwdqw .MuiPaper-root {
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 30px;

  svg {
    height: 14px;
    position: absolute;
    right: 20px;
    top: 20px;
    color: #060606;
    cursor: pointer;
  }
}

.CONTAINER {
  padding: 0 20px;

  .breadcrumbs {
    margin-top: 25px;
    margin-bottom: 50px;
    display: flex;
    align-items: center;

    a {
      color: #777777;
      font-weight: 400;
      font-size: 10px;
      line-height: 12px;
      cursor: pointer;
    }

    .breadcrumbs_arrow {
      margin: 0 5px;
    }
  }
}

.about-sub {
  margin-bottom: 60px;

  &__title {
    font-weight: 700;
    font-size: 36px;
    line-height: 43px;
    color: #222222;
  }

  a {
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    color: #060606;
    text-decoration: none;
    cursor: pointer;
  }

  &__footer-text {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    text-decoration-line: underline;
    color: #222222;
  }

  &__description {
    p {
      font-weight: 400;
      font-size: 24px;
      line-height: 34px;
      color: #222222;
    }
  }

  &__link-arrow {
    display: flex;
    align-items: center;

    svg {
      margin: 0 5px;
    }
  }

  &__desc {
    margin: 40px 0;
  }
}

@media (min-width: 1366px) {
  .about-sub {
    &__title {
      font-size: 50px;
      line-height: 60px;
    }

    &__desc {
      max-width: 783px;
      font-size: 30px;
      line-height: 36px;
    }

    &__description a {
      font-size: 20px;
      line-height: 20px;
    }

    &__wrapper-link {
      display: flex;

      p {
        margin-right: 95px;
      }
    }

    &__footer-text {
      margin-top: 40px;
    }

    .CONTAINER {
      max-width: 1170px;
      margin: 0 auto;
    }
  }
}