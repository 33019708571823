@import '~@styles/colors.scss';
@import '~@styles/responsive-design.scss';
@import '~@styles/mixins.scss';

.dots-stage_3 {
    &__box {
        margin-bottom: 30px;
    }
}

.dots__button--transparent {
    background-color: white;
    border: 1px solid #EBEBEB;
}

@media (max-width: 1366px) {
    .input-wrapper-field {
        position: relative;
    }

    .icon-star {
        position: absolute;
        right: 0;
        top: 0;
    }

    .ecocoin {
        position: absolute;
        right: 0;
        bottom: 40px;
        width: 95px;
        height: 19px;
        background: #F9D606;
        border-radius: 10px;
        font-weight: 400;
        font-size: 10px;
        line-height: 20px;
        color: #333333;
        text-align: center;
    }
}