.favoritePlace__wrapper {
    display: flex;
    flex-wrap: wrap;

    .favoritePlace__card {
        width: 49%;
        background: #FAD600;
        border-radius: 20px;
        padding: 40px;
        margin-bottom: 10px;
        position: relative;

        span {
            position: absolute;
            left: 24px;
            top: 24px;
            width: 34px;
            height: 34px;
            background-color: white;
            border-radius: 50%;
            font-size: 23px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        div {
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: #333333;
            max-width: 229px;
        }

        &:first-child {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            svg {
                margin-right: 82px;
            }
        }

        &:nth-child(2),
        &:nth-child(4) {
            margin-right: 10px;
        }
    }
}

.favoritePlace {

    .dots__text-first-block-d {
        position: relative;

        span {
            position: absolute;
            left: -14px;
            top: -14px;
            background: #F9D606;
            border-radius: 10px;
            padding: 4px 16px;
            width: 275px;
            font-size: 16px;
        }
    }

    .dots__wrapper-image-first-block {
        margin-bottom: 0;
    }

    .dots__grey-text {
        opacity: 0.7;
    }

    .dots__firs-block-cosmetic {
        border: 1px solid #D9D9D9;
        border-radius: 33px;
        padding: 35px;
    }

    .dots__text-first-block {
        margin-bottom: 0;
        margin-top: auto;
    }

    .dots__wrapper-image-second>div>div {
        width: 49%;
        height: 100%;
        margin-bottom: 10px;

        &:first-child {
            margin-right: 10px;
        }

        &:nth-child(3) {
            margin-right: 10px;
        }
    }

    .dots__wrapper-image-second {
        display: flex;
        flex-wrap: wrap;
    }

    .f__wrapper-image {
        width: 47%;
        border-radius: 20px;
        margin-bottom: 30px;
        height: 275px;
        position: relative;

        p {
            position: absolute;
            bottom: 25px;
            left: 20px;
            color: white;
            font-size: 20px;
            margin: 0;
            line-height: 28px;
            font-weight: 400;
        }

        span {
            position: absolute;
            top: 20px;
            right: 20px;
            padding: 4px 20px;
            background: #F9D606;
            border-radius: 10px;
            font-weight: 400;
            font-size: 16px;
        }

        img {
            width: 100%;
            border-radius: 20px;
            height: 100%;
        }

        &:first-child {
            margin-right: 30px;
        }

        &:nth-child(3) {
            margin-right: 30px;
        }
    }

    .fw__wrapper-image {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    }
}

@media screen and (max-width: 1365px) {
    .favoritePlace {
        width: 100%;

        .dots__wrapper-images-second  {
            overflow: none;
        }

        .dots__firs-block-cosmetic {
            margin-bottom: 30px;
        }

        .dots__grey-text {
            opacity: 0.7;
            width: 100%;
            font-size: 14px;
        }

        .dots__text-first-block-d span {
            top: -267px;
        }

        .f__wrapper-image {
            margin-right: 0;
            width: 100%;

            &:nth-child(3) {
                margin-right: 0;
            }

            &:nth-child(1) {
                margin-right: 0;
            }
        }

        .favoritePlace__wrapper {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;

            .favoritePlace__card {
                svg {
                    display: flex;    
                    margin: 0 auto !important;
                    margin-bottom: 30px !important;
                }

                width: 100%;
                background: #FAD600;
                border-radius: 20px;
                padding: 20px;
                margin-bottom: 10px;
                position: relative;
                overflow: hidden;

                span {
                    position: absolute;
                    left: 24px;
                    top: 24px;
                    width: 34px;
                    height: 34px;
                    background-color: white;
                    border-radius: 50%;
                    font-size: 23px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                div {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 19px;
                    color: #333333;
                    max-width: 270px;
                    padding: 0 20px;
                }

                &:first-child {
                    width: 100%;
                    display: flex;
                    flex-direction: column;

                    svg {
                        margin-right: 0;
                        margin-bottom: 30px;
                    }
                }

                &:nth-child(2),
                &:nth-child(4) {
                    margin-right: 6px;
                }
            }
        }
    }
}