@import '~@styles/colors.scss';

.UserAvatar {
  display: inline-flex;

  &__Container {
    background-color: $gallery;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $mineShaft;
  }
}