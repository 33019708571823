@import '~@styles/colors.scss';
@import '~@styles/responsive-design.scss';
@import '~@styles/mixins.scss';

.dots-stage_2__desc .social {
    margin-top: 30px !important;
}

.dots-action {
    &__desc-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 40px;
    }
}

.dots__promocode {
    position: relative;
    width: 100% !important;

    .icon-info-promocode {
        position: absolute;
        right: 0;
        bottom: 10px;
        cursor: pointer;
        z-index: 1;
    }
}

.dots-action__desc-container {
    .dots-stage_2__desc {
        margin: 0;
    }

    .dots__coins {
        background: #F9D606;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 95px;
        height: 19px;
        font-size: 10px;
    }
}

.dots__promocode-text {
    font-size: 14px;
    line-height: 20px;
}

.dots__promocode-info {
    position: absolute;
    width: 247px;
    height: 132px;
    right: -210px;
    top: 11px;
    background: #FFFFFF;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
    padding: 15px;

    .icon-info-promocode {
        position: relative;
    }

}