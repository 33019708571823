@import '~@styles/colors.scss';
@import '~@styles/responsive-design.scss';
@import '~@styles/mixins.scss';

.dots-stage_3 {
  &__box {
    margin-bottom: 30px;
  }
}

.dots__input {
  width: 100% !important;
  margin-bottom: 10px !important;
}

.dots__social {
  margin-left: 0 !important;
  margin-right: 0 !important;
  width: 100% !important;
}

.dots__button--transparent {
  background-color: white;
  border: 1px solid #EBEBEB;
  color: #333333;
}

.dots-stage_2__desc+textarea {
  margin-right: 0 !important;
  margin-left: 0 !important;

  &::placeholder {
    color: #333333;
    opacity: 0.3;
  }
}

.dots__input {
  width: 100% !important;
  margin-left: 0 !important;
  margin-right: 0 !important;

  &>div:before {
    border-bottom: 1px solid #EBEBEB;
  }

  label {
    padding-left: 10px;
    color: #333333;
    opacity: 0.3;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;

    &+div {
      padding-left: 0 !important;
    }
  }

  fieldset {
    border: none;
    border-bottom: 1px solid #EBEBEB;
  }
}




@media (min-width: 1366px) {
  .input-wrapper-field {
    position: relative;
    width: 100% !important;

    &.wi50 {
      width: 47% !important;
      margin-right: 34px !important;
    }

    &.wi50:nth-child(2) {
      margin-right: 0 !important;
    }

    &.wi50:nth-child(4) {
      margin-right: 0 !important;
    }

    .social-input {
      width: 100% !important;
    }

    .icon-star {
      position: absolute;
      right: 0px;
      top: 0px;
    }

    .ecocoin {
      position: absolute;
      right: 0;
      bottom: 40px;
      width: 95px;
      height: 19px;
      background: #F9D606;
      border-radius: 10px;
      font-weight: 400;
      font-size: 10px;
      line-height: 20px;
      color: #333333;
      text-align: center;
    }
  }

  .dots-stage_3 {
    margin-bottom: 279px;
  }

  .dots__input {
    width: 100% !important;

    label {
      padding-left: 10px;

      &+div {
        padding-left: 0 !important;
      }
    }

    fieldset {
      border: none;
      border-bottom: 1px solid #EBEBEB;
    }

    &>div {
      padding-left: 0 !important;
    }

    &>div:before {
      border-bottom: 1px solid #EBEBEB;
    }
  }

  .dots__save-late {
    text-align: left;
  }

  .dots-stage_3 .dots__flex-container {
    margin-bottom: 20px;
  }
}
