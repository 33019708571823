@import '~@styles/colors.scss';
@import '~@styles/mixins.scss';

.ReactVirtualized__Grid__innerScrollContainer {
  overflow-x: auto!important;
  @include scrollbar3();
}

.Autocomplete {
  width: 100%;
  position: relative;

  @media (pointer:none), (pointer:coarse) {
    cursor: pointer;
  }

  &__FakeInput {
    display: none;
  }

  &__ArrowIcon {
    position: absolute;
    width: 10px;
    right: 0;
    top: 0;
    height: 100%;
    @include center();
    color: $mineShaft;
    cursor: pointer;
  }

  &:focus-within {
    .Autocomplete__ArrowIcon {
      color: $gold;
    }
  }
}

.SuggestionsList {
  padding: 20px;
  padding-right: 15px;
  margin: 0;
  list-style: none;
  text-align: left;
  background-color: $white;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  outline: none;
  z-index: inherit;
  position: absolute!important;

  @include appearing();

  &__List {
    outline: none;
    @include scrollbar2();
  }

  &__Item {
    @include text(16px, $mineShaft, 30px, 500);
    height: 35px;
    white-space: nowrap;

    &:hover {
      cursor: pointer;
      color: $gold;
    }
  }

  &__Portal {
    width: auto;
    height: auto;
  }
}
