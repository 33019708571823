@import '~@styles/mixins.scss';
@import '~@styles/colors.scss';
@import '~@styles/variables.scss';
@import '~@styles/responsive-design.scss';

.prize_error {
  &__title {
    margin-top: 73px;
    margin-bottom: 81px;

    span {
      font-weight: 700;
      font-size: 96px;
      line-height: 96px;
      color: #060606;
    }
  }

  &__text {
    font-family: 'Mabry Pro';
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    color: #999999;
  }

  &__container {
    padding: 0 20px;
    text-align: center;
  }

  .prix_in__title {
    margin-bottom: 80px;
  }

  &__text {
    margin-bottom: 114px;
  }
}

@media (min-width: 1200px) {
  .prize_error {

    &__wrapper-button {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-bottom: 73px;

      a {
        width: 340px;
        cursor: pointer;
      }
    }
  }
}
