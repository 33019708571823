@import '~@styles/colors.scss';
@import '~@styles/responsive-design.scss';
@import '~@styles/mixins.scss';

.SideBar {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
  z-index: 10001;
  
  &__Portal {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }

  &-enter {
    backdrop-filter: blur(0);

    .SideBar__Container {
      transform: translateX(-103%);
    }
  }

  &-enter-active {
    backdrop-filter: blur(20px);
    transition: backdrop-filter 230ms linear;

    .SideBar__Container {
      transform: none;
      transition: transform 230ms ease-out;
    }
  }

  &-enter-done {
    backdrop-filter: blur(20px);
    .SideBar__Container {
      pointer-events: auto;
    }
  }

  &-exit {
    backdrop-filter: blur(20px);

    .SideBar__Container {
      transform: none;
    }
  }

  &-exit-active {
    backdrop-filter: blur(0);
    transition: backdrop-filter 230ms ease-out;
    
    .SideBar__Container {
      transform: translateX(-103%);
      transition: transform 230ms ease-out;
    }
  }

  &__Container {
    z-index: 10001;
    position: relative;
    max-width: 375px;
    display: flex;
    justify-content: flex-end;
    top: 95px;
    width: 90%;
    
    will-change: transform;
    background-color: transparent;
    height: calc(100% - 90px);

    
    
    @include media('<tablet') {
      > div {
        width: 100%;
      }

      width: 100%;
      max-width: 100%;
      top: 60px;
      
      height: calc(var(--vh, 1vh) * 100 - 60px);
    }
  }
}