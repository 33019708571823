@import '~@styles/colors.scss';

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-family: 'Muller';
  font-size: 17px;
  line-height: 1;
  font-weight: 500;
  color: #000000;
}

html {
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  -webkit-tap-highlight-color: transparent;
  scroll-behavior: smooth;
}

html,body {
  height: 100%; /* Needed for container's min-height  */
  overflow: hidden;
}
* {
  box-sizing: border-box;
}

.business-promotion-container {
  z-index: 9999;
}

.filter-block-points .ReactVirtualized__List::-webkit-scrollbar {
  width: 14px;
}
.filter-block-points .ReactVirtualized__List::-webkit-scrollbar-thumb {
  height: 30px;
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  -webkit-border-radius: 7px;
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
}


.filter-block-points .ReactVirtualized__List::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}
.filter-block-points .ReactVirtualized__List::-webkit-scrollbar-corner {
  background-color: transparent;
}

.form-item {
  border-radius: 0;
}

.form-fake-input {
  border-bottom: 1px solid #f9d606;
  font-size: 16px;
  color: #dad8d8;
  font-weight: 400;
  line-height: 32px;
}

.form-group__icon {
  height: 31px;
  width: 32px;
  border-bottom: 1px solid #f9d606;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-navigation__wrapper {
  height: 23px;
  width: 25px;
  border-radius: 3px;
  background-color: transparent;
  transition: background-color 0.3s ease-out;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-navigation__wrapper > div {
  width: 25px;
  height: 25px;
}
.icon-navigation__wrapper--active {
  background-color: #f7d40c;
}

.icon-covid19 {
  background: url(../img/iconfinder_Radiation_Virus_5958285.png);
  background-repeat: no-repeat;
  background-size: 40px 40px;
  background-size: contain;
  background-position: 0 0;
  display: block;
  width: 40px;
  height: 40px;
}

.icon-navigation {
  background: url(../img/navigation.svg);
  background-repeat: no-repeat;
  background-size: 20px 22px;
  background-position: 1px -4px;
  cursor: pointer;
  display: block;
  width: 18px;
  height: 18px;
}

.navigation-btn {
  border-radius: 50%;
  background-color: white;
  width: 38px;
  height: 35px;
  border: 2px solid transparent;
  cursor: pointer;
  box-shadow: 0 3px 4px 0px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-in-out;
}

.navigation-btn:active,
.navigation-btn:hover {
  border-color: #f9d606;
}

.navigation-btn > svg {
  width: 25px;
  height: 25px;
  position: absolute;
  top: 2px;
  left: 6px;
}

.placemark-me {
  width: 24px;
  height: 24px;
  background-color: #f9d606;
  border-radius: 50%;
  border: 4px solid white;
  left: -12px;
  position: absolute;
  top: -12px;
  z-index: 999;
}

.placemark-me-shadow {
  width: 38.4px;
  height: 38.4px;
  opacity: 0.4;
  background-color: #f9d606;
  content: ' ';
  position: absolute;
  left: -19.2px;
  top: -19.2px;
  z-index: 998;
  border-radius: 50%;
}

.user-actions {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.user-actions > div:not(:last-child) {
  margin-bottom: 20px;
}

.form-group {
  display: flex;
}

.form-group > input {
  width: calc(100% - 32px);
}

.form-item,
.form-textarea {
  width: 100%;
  border: 0;
  border-bottom: 1px solid #f9d606;
  outline: transparent;
  font-family: 'Muller';
  font-size: 16px;
  line-height: 20px;
  font-weight: normal;
  color: #000000;
  padding: 4px 0;
  -webkit-appearance: none;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
}
.add-point__form-item img {
  position: absolute;
  pointer-events: none;
  width: 40px;
  right: 20px;
}
.form-item::-webkit-input-placeholder,
.form-textarea::-webkit-input-placeholder {
  color: #cccccc;
}
.form-item:-moz-placeholder,
.form-textarea:-moz-placeholder {
  color: #cccccc;
}
.form-item::-moz-placeholder,
.form-textarea::-moz-placeholder {
  color: #cccccc;
}
.form-item:-ms-input-placeholder,
.form-textarea:-ms-input-placeholder {
  color: #cccccc;
}
.form-item::placeholder,
.form-textarea::placeholder {
  color: #cccccc;
}
.error .form-item,
.error .form-textarea,
.error .form-group__icon {
  color: #ff001d;
  border-color: #ff001d;
}

.error .form-fake-input {
  border-color: #ff001d;
}

.form-textarea {
  border: 1px solid #f9d606;
  -webkit-border-radius: 22px;
          border-radius: 22px;
  height: 244px;
  padding: 12px 17px;
}



.form-select {
  width: 100%;
  border: 0;
  border-bottom: 1px solid #f9d606;
  outline: transparent;
  font-family: 'Muller';
  font-size: 18px;
  line-height: 1.5;
  font-weight: normal;
  color: #000000;
  padding: 9px 0;
}
.error .form-select {
  border-color: #ff001d;
  color: #ff001d;
}

.form-submit {
  font-family: 'Muller';
  font-size: 14px;
  line-height: 1;
  font-weight: 400;
  text-decoration: none;
  display: inline-block;
  -webkit-border-radius: 22px;
          border-radius: 22px;
  padding: 10px 17px;
  -webkit-box-shadow: 0 3px 3px 0px rgba(0, 0, 0, 0.2);
          box-shadow: 0 3px 3px 0px rgba(0, 0, 0, 0.2);
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  outline: transparent;
  cursor: pointer;
  border: 0;
  text-align: center;
  background: #f9d606;
  color: #000000;
  border: 2px solid #f9d606;
  min-width: 120px;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
}

.reviews-links > .form-submit{
  margin-left: 10px;
}

.form-submit:hover,
.form-submit:focus {
  background: #000000;
  border-color: #000000;
  color: #ffffff;
}
.form-submit:active {
  background: #000000;
  border-color: #000000;
  color: #ffffff;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.form-submit:disabled {
  background: #f9d606;
  color: #000000;
  border: 2px solid #f9d606;
  opacity: 0.5;
  cursor: not-allowed;
}
.form-submit:hover,
.form-submit:focus {
  background: #000000;
  border-color: #000000;
  color: #ffffff;
}
.form-submit:active {
  background: #000000;
  border-color: #000000;
  color: #ffffff;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.form-submit:disabled {
  background: #f9d606;
  color: #000000;
  border: 2px solid #f9d606;
  opacity: 0.5;
  cursor: not-allowed;
}
.asterisk {
  width: 7px;
  height: 7px;
  background: url(../img/svg/asterisk.svg) no-repeat center / contain;
  position: absolute;
  bottom: 13px;
  left: -20px;
}
.error .asterisk,
.error-asterisk {
  background-image: url(../img/svg/mark.svg);
  background-repeat: no-repeat;
  height: 15px;
  top: 6px;
}
.error .asterisk.asterisk--percent {
  top: 40%
}
.form-checkbox {
  vertical-align: top;
  margin: 0 3px 0 0;
  width: 27px;
  height: 27px;
}
.form-checkbox-label {
  cursor: pointer;
  font-size: 12px;
  line-height: 20px;
  font-weight: normal;
}
.form-checkbox:not(checked) {
  position: absolute;
  opacity: 0;
  z-index: 9999;
}
.form-checkbox:not(checked) + .form-checkbox-label {
  position: relative;
  padding: 0px 0 0 24px;
}
.form-checkbox:not(checked) + .form-checkbox-label:before {
  content: '';
  position: absolute;
  top: -4px;
  left: 0;
  width: 16px;
  height: 16px;
  background: url(../img/svg/checkbox.svg) no-repeat center / contain;
}
.form-checkbox:disabled + .form-checkbox-label {
  color: #cccccc;
}
.form-checkbox:not(checked):disabled + .form-checkbox-label:before {
  background-image: url(../img/svg/checkbox-dis.svg);
}
.form-checkbox:checked + .form-checkbox-label:before {
  top: -7px;
  left: -1px;
  width: 19px;
  height: 19px;
  background: url(../img/svg/checkbox-checked.svg) no-repeat center / contain;
}
.form-checkbox:checked:disabled + .form-checkbox-label:before {
  background-image: url(../img/svg/checkbox-checked-dis.svg);
}

.form-checkbox:not(checked) + .error.form-checkbox-label:before {
  background: url(../img/svg/checkboxErr.svg) no-repeat center / contain;
}
.white-button {
  font-family: 'Muller';
  font-size: 14px;
  line-height: 1;
  font-weight: 400;
  text-decoration: none;
  display: inline-block;
  padding: 10px 17px;
  transition: all 0.2s ease-in-out;
  outline: transparent;
  cursor: pointer;
  border: 0;
  text-align: center;
  background: #ffffff;
  color: #000000;
  border: 2px solid #ffffff;
  white-space: nowrap;
}
.white-button:hover,
.white-button:active {
  color: #666666;
  border-color: #ffffff;
}
.white-button:disabled {
  border-color: #ffffff;
  opacity: 0.5;
  cursor: not-allowed;
}
.orange-button {
  font-family: 'Muller';
  font-size: 17px;
  line-height: 1;
  font-weight: 500;
  text-decoration: none;
  display: inline-block;
  -webkit-border-radius: 22px;
          border-radius: 22px;
  padding: 10px 17px;
  -webkit-box-shadow: 0 3px 25px -7px rgba(0, 0, 0, 0.2);
          box-shadow: 0 3px 25px -7px rgba(0, 0, 0, 0.2);
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  outline: transparent;
  cursor: pointer;
  border: 0;
  text-align: center;
  background: #f9d606;
  color: #000000;
  border: 2px solid #f9d606;
}
.orange-button:hover,
.orange-button:focus {
  background: #000000;
  border-color: #000000;
  color: #ffffff;
}
.orange-button:active {
  background: #000000;
  border-color: #000000;
  color: #ffffff;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.orange-button:disabled {
  background: #f9d606;
  color: #000000;
  border: 2px solid #f9d606;
  opacity: 0.5;
  cursor: not-allowed;
}

.unstyled-list {
  list-style: none;
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0;
}

.social-list {
  width: 130px;
  justify-content: space-between
}

.TopBox__links-list > li:not(:last-child) {
  margin-right: 24px;
}

.add-point-btn-box {
  border-right: #dcdcdc;
  border-right-width: 1px;
  border-right-style: solid;
  height: 30px;
  display: flex;
  align-items: center;
}

.social-list {
  list-style: none;
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0 5px;
  width: 110px;
  justify-content: space-between
}

.social-list__item {
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  width: 20px;
  height: 20px;
  display: block;
  background-size: contain;
  background-repeat: no-repeat;
  transform: scale(1);
  transition: transform .2s ease-in;
}

.icon:hover {
  transform: scale(1.1);
}

.icon-fb {
  background-image: url(../img/social/facebook.svg);
  background-position-x: 7px;
}

.icon-vk {
  background-image: url(../img/social/vk.svg);
  width: 30px;
  height: 30px;
  background-position-y: 7px;
}

.icon-instagram {
  background-image: url(../img/social/instagram.svg);
}

.icon-ok {
  background-image: url(../img/social/odnoklassniki.svg);
}

div.search.hidden {
  display: none;
}

.search {
  // margin-top: 30px;
  // margin-bottom: 30px;
  width: 100%;
  height: 50px;
}

.scrollBar {
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05);
  min-height: -webkit-fill-available !important;
}

.search__form {
  z-index: 998;
  height: 50px;
  width: 100%;
  display: flex;
  border-radius: 22px;
  transition: all 0.2s ease-in-out;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.25);
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border: 1px solid transparent;
}

.search__field {
  font-family: 'Muller';
  font-size: 16px;
  line-height: 16px;
  color: #222222;
  font-weight: 400;
  -webkit-border-radius: 22px;
          border-radius: 22px;
  padding: 13px 42px 9px 30px;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  outline: transparent;
  width: 100%;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  border: 1px solid transparent;
}
.search__field::-webkit-input-placeholder {
  color: #222222;
  opacity: 0.3;
  line-height: 16px;
}
.search__field:-moz-placeholder {
  color: #222222;
  opacity: 0.3;
}
.search__field::-moz-placeholder {
  color: #222222;
  opacity: 0.3;
}
.search__field:-ms-input-placeholder {
  color: #222222;
  opacity: 0.3;
}
.search__field::placeholder {
  color: #222222;
  opacity: 0.3;
  line-height: 16px;
}
.search__field:focus + .search__icon {
  color: $gold;
}
.search__icon {
  width: 21px;
  height: 21px;
  padding: 0;
  border: 0;
  color: $black;
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: transparent;
  position: absolute;
  right: 217px;
  cursor: pointer;
  transition: color 0.2s ease-in-out;
  background-color: transparent;
}
.close-button {
  font-family: 'Muller';
  font-size: 17px;
  line-height: 1;
  font-weight: 500;
  text-decoration: none;
  display: inline-block;
  -webkit-border-radius: 22px;
          border-radius: 22px;
  padding: 10px 17px;
  -webkit-box-shadow: 0 3px 25px -7px rgba(0, 0, 0, 0.2);
          box-shadow: 0 3px 25px -7px rgba(0, 0, 0, 0.2);
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  outline: transparent;
  cursor: pointer;
  border: 0;
  text-align: center;
  width: 50px;
  height: 50px;
  -webkit-border-radius: 50%;
          border-radius: 50%;
  background: #000000 url(../img/svg/close-white.svg) no-repeat center / 20px;
}
.close-button:hover,
.close-button:focus {
  background-color: #ff4b00;
}
.close-button:active {
  background-color: #000000;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.close-button:disabled {
  background-color: #000000;
  opacity: 0.5;
  cursor: not-allowed;
}
.services-block-block {
  position: absolute;
  z-index: 9999;
  left: 40px;
  bottom: 40px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.services-block-wrapper {
  position: absolute;
  padding: 2px;
  background-color: #FFF;
  border-radius: 15px;
  left: 40px;
  bottom: 40px;
  z-index: 9999;
  display: flex;
  align-items: flex-end;
  box-shadow: 0 3px 4px 0px rgba(0, 0, 0, 0.2);
}

.services-block-container {
  display: flex;
  align-items: flex-end;
  margin: auto;
}

.services-block-wrapper .services-block--selected {
  width: auto;
}

.services-block-wrapper .services-block__button {
  width: 100%;
}

.services-block-wrapper .services-block__info {
  width: 330px;
}

.services-block {
  width: 100%;
}

.services-block__info {
  display: none;
  width: 330px;
  margin-bottom: 30px;
  position: absolute;
  bottom: 100%;
  left: 0;
}
.services-block__list {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  width: 100%;
  max-height: 420px;
  list-style-type: none;
  padding: 0;
  margin: 0;
  -webkit-border-radius: 22px;
          border-radius: 22px;
  background: #ffffff;
  -webkit-box-shadow: 0 3px 25px -7px rgba(0, 0, 0, 0.2);
          box-shadow: 0 3px 25px -7px rgba(0, 0, 0, 0.2);
}

.services-block__list-item {
  width: 330px;
  height: auto;
}
.services-block__list-item:first-child {
  margin-top: 10px;
}
.services-block__list-item-div {
  font-size: 17px !important;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 8px 19px;
  color: #000000;
  text-decoration: none;
}
.services-block--online .services-block__list-item-div {
  justify-content: flex-start;
}
.services-block__list-item div:hover {
  background-color: #f9d606;
}
.services-block__list-item a:hover .services-block__svg {
  fill: #000000;
}
.services-block__list-item:first-child a {
  -webkit-border-top-left-radius: 22px;
          border-top-left-radius: 22px;
  -webkit-border-top-right-radius: 22px;
          border-top-right-radius: 22px;
}
.services-block__list-item:last-child a {
  -webkit-border-bottom-left-radius: 22px;
          border-bottom-left-radius: 22px;
  -webkit-border-bottom-right-radius: 22px;
          border-bottom-right-radius: 22px;
}
.services-block__list-item--complete > a {
  background-image: url(../img/arrow-right-icon.png);
  background-repeat: no-repeat;
  background-position: right 12px center;
}
.services-block__list-item--complete .services-block__list {
  display: none;
}
.services-block__list-item--complete:hover > .services-block__list {
  display: block;
  z-index: 2;
  position: absolute;
  left: calc(100% - 10px);
  bottom: -90px;
}
.services-block__list-item--more {
  position: static;
}
.services-block__list-item--more .services-block__more {
  display: block;
}
.services-block__list-item--disabled .services-block__svg {
  fill: #cccccc;
}
.services-block__list-item--disabled .services-block__list {
  display: none;
}
.services-block__list-item--disabled:hover > .services-block__list {
  display: none;
}
.services-block__list-item--disabled > div {
  color: #cccccc;
  background: none;
}
.services-block__list-item--disabled > div:hover {
  background-color: transparent;
}
.services-block__list-item--disabled > div:hover .services-block__svg {
  fill: #cccccc;
}
.services-block__list-item-image {
  max-width: 30px;
  width: 100%;
  height: 30px;
  text-align: center;
  margin-right: 13px;
}
.services-block__list-item-image img,
.services-block__list-item-image svg {
  max-width: 85%;
}

.services-block__selected-item .services-block__list-item-image img {
  width: 30px;
}

.services-block__list-item-image:hover {
  background: url("../img/Asset 1.svg") no-repeat;
  background-size: 29px;
}
.services-block__list-item-image:hover img {
  display: none;
  width: 29px;
}

.services-block__list-item-image:hover + .services-block__list-item--disabled  {
  background: transparent;
}
.services-block__list-item-image:hover .services-block__list-item--disabled img {
}

.services-block__button {
  font-family: 'Muller';
  font-size: 14px !important;
  line-height: 1;
  font-weight: 400;
  text-decoration: none;
  display: inline-block;
  margin: 5px 16px auto;
  padding: 5px 8px;
  outline: transparent;
  cursor: pointer;
  border: 0;
  text-align: center;
  background: #ffffff;
  color: #000000;
  border: 2px solid #ffffff;
  font-size: 19px;
  max-width: 110px;
  margin-bottom: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.services-block__button > img {
  margin-bottom: 14px;
  width: calc(100% - 4px);
}

.services-block__button:active {
  color: #666666;
  border-color: #ffffff;
}
.services-block__button:disabled {
  border-color: #ffffff;
  opacity: 0.5;
  cursor: not-allowed;
}
.services-block__clearIconWrapper {
  width: 30px;
  height: 30px;
  position: absolute;
  right: -8px;
  top: -8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  transition: background-color 0.3;
  z-index: 9999;
}
.services-block__clearIconWrapper--mobile {
  width: 20px;
  height: 20px;
}
.services-block__clearIconWrapper:hover {
  background-color: #f1f1f1;
  opacity: 0.8;
}
.services-block__clearIcon {
  background-image: url(../img/cross.svg);
  background-repeat: no-repeat;
  background-size: contain;
  width: 15px;
  height: 15px;
}

.services-block__button-close {
  font-family: 'Muller';
  position: absolute;
  left: 100%;
  font-size: 17px;
  line-height: 1;
  font-weight: 500;
  text-decoration: none;
  display: inline-block;
          border-radius: 22px;
  padding: 10px 17px;
          box-shadow: 0 3px 25px -7px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-in-out;
  outline: transparent;
  cursor: pointer;
  border: 0;
  text-align: center;
  width: 50px;
  height: 50px;
  -webkit-border-radius: 50%;
          border-radius: 50%;
  background: #000000 url(../img/svg/close-white.svg) no-repeat center / 20px;
}
.services-block__button-close:hover,
.services-block__button-close:focus {
  background-color: #ff4b00;
}
.services-block__button-close:active {
  background-color: #000000;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.services-block__button-close:disabled {
  background-color: #000000;
  opacity: 0.5;
  cursor: not-allowed;
}
.services-block__button-close:hover,
.services-block__button-close:focus {
  background-color: #ff4b00;
}
.services-block__button-close:active {
  background-color: #000000;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.services-block__button-close:disabled {
  background-color: #000000;
  opacity: 0.5;
  cursor: not-allowed;
}
.services-block__button--online {
  position: absolute;
  z-index: 9998;
  bottom: 40px;
  right: 100px;
  opacity: 1;
  background-image: none;
  padding: 13px 24px;
}
.services-block__selected-item {
  font-family: 'Muller';
  font-size: 17px;
  line-height: 1;
  font-weight: 500;
  white-space: nowrap;
  text-decoration: none;
  display: inline-block;
  -webkit-border-radius: 22px;
          border-radius: 22px;
  padding: 10px 17px;
  -webkit-box-shadow: 0 3px 25px -7px rgba(0, 0, 0, 0.2);
          box-shadow: 0 3px 25px -7px rgba(0, 0, 0, 0.2);
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  outline: transparent;
  cursor: pointer;
  border: 0;
  text-align: center;
  background: #f9d606;
  color: #000000;
  border: 2px solid #f9d606;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 6px 19px;
  margin-right: 11px;
  font-size: 19px;
}
.services-block__selected-item:hover,
.services-block__selected-item:focus {
  background: #000000;
  border-color: #000000;
  color: #ffffff;
}
.services-block__selected-item:active {
  background: #000000;
  border-color: #000000;
  color: #ffffff;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.services-block__selected-item:disabled {
  background: #f9d606;
  color: #000000;
  border: 2px solid #f9d606;
  opacity: 0.5;
  cursor: not-allowed;
}
.services-block__selected-item:hover,
.services-block__selected-item:focus {
  background: #000000;
  border-color: #000000;
  color: #ffffff;
}
.services-block__selected-item:active {
  background: #000000;
  border-color: #000000;
  color: #ffffff;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.services-block__selected-item:disabled {
  background: #f9d606;
  color: #000000;
  border: 2px solid #f9d606;
  opacity: 0.5;
  cursor: not-allowed;
}
.services-block__selected-item .services-block__svg {
  fill: #000000;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.services-block__selected-item:hover .services-block__svg {
  fill: #f9d606;
}
.services-block__more {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #f9d606;
  -webkit-border-radius: 22px;
          border-radius: 22px;
  z-index: 3;
  padding: 22px;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
}
.services-block__more-close {
  position: absolute;
  top: 19px;
  right: 21px;
  width: 21px;
  height: 21px;
  background: url(../img/svg/close-black.svg) no-repeat center / contain;
  cursor: pointer;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.services-block__more-close:hover {
  background-image: url(../img/svg/close-white.svg);
}
.services-block__more-title {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  max-width: 80%;
  font-size: 21px;
  font-weight: bold;
  margin-bottom: 20px;
}
.services-block__more-title-img {
  width: 35px;
  margin-right: 20px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
     -moz-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
}
.services-block__more-title-img .services-block__svg {
  max-width: 100%;
  fill: #000000;
}
.services-block__more-info {
  font-size: 17px;
  line-height: 1.5;
}
.services-block--active .services-block__info {
  display: block;
}
.services-block--active .services-block__button {
  border-color: #f9d606;
}
.services-block--main .services-block__list-item a:hover {
  background-image: url(../img/question.png);
  background-repeat: no-repeat;
  background-position: left 20px center;
}
.services-block--main .services-block__list-item a:hover .services-block__list-item-image {
  z-index: -1;
}
.services-block--main .services-block__list-item--complete > a:hover {
  background-image: url(../img/arrow-right-icon.png), url(../img/question.png);
  background-repeat: no-repeat;
  background-position: right 12px center, left 20px center;
}
.services-block--selected {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
     -moz-box-orient: horizontal;
     -moz-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}
.services-block--online {
  position: absolute;
  z-index: 9999;
  right: 90px;
  bottom: 80px;
  left: auto;
}
.services-block--online .services-block__info {
  left: auto;
  right: 0;
  display: block;
}
.services-block--online .services-block__button {
  background-image: none;
}

.nav-bar-container {
  display:none;
  position: absolute;
  z-index: 99999;
  top: 0;
  width: 100%;
  min-height: 54px;
  background: #f9d606;
  border-radius: 0px 0px 10px 10px;
  -webkit-box-shadow: 0 3px 25px -7px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0px 5px 1px rgba(0, 0, 0, 0.2);
}

.nav-bar {
  display: flex;
  margin: 0px 10px;
}

.loading__line{
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
  height: 5px;
  z-index: 9999;
  background: rgb(145, 130, 1);
  animation:
    pulse_main 1s ease-in-out infinite alternate;
}

.nav-bar__line {
  position: absolute;
  top: 0;
  left: 0;
  width: 70px;
  height: 5px;
  z-index: 9999;
  background: rgb(145, 130, 1);
  animation:
    pulse 1s ease-in-out infinite alternate;
}

.nav-bar__menu-button {
  width: 22px;
  margin: 10px;
  margin-top: 15px;
}
.nav-bar__menu-button img {
  max-width: 100%;
}

.nav-bar__logo {
  width: 141px;
  margin-left: auto;
  margin-top: 13px;
}
.nav-bar__logo img {
  max-width: 100%;
}

.nav-bar__city {
  width: 45px;
  margin-top: 5px;
  margin-right: 5px;
  margin-left: auto;
}
.nav-bar__city img {
  width: 35px;
  margin-top: 5px;
  margin-left: 16px;
}

.nav-bar__city {
  font-size: 17px;
  padding-right: 10px;
  cursor: pointer;
}

.nav-bar-search {
  display: flex;
  flex-flow: column;
  margin: 10px
}


.nav-bar-search__logo {
  width: 141px;
  padding-left: 7px;
  margin-top: 5px;
}
.nav-bar-search__logo img {
  max-width: 100%;
}

.nav-bar-search__input {
  margin: auto;
}
.nav-bar-search__input input {
  border: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  background: transparent;
  border-bottom: 2px solid #000000;
  color: #000000;
  font-size: 25px;
  font-weight: 500;
  width: 283px;
  outline: transparent;
  padding-top: 20px;
}
.nav-bar-search__autocomplete {
  width: 100%;
  padding-top: 10px;
  margin-bottom: 15px;
  /* TODO */
  height: -webkit-fill-available;
}
.nav-bar-search__autocomplete-list {
  list-style: none;
  padding: 0;
  margin: 0;
}
.nav-bar-search__autocomplete-list-item div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 5px 15px 6px 35px;
  font-size: 22px;
  font-weight: 600;
  color: #000000;
  text-decoration: none;
}
.nav-bar-search__autocomplete-list-item.active {
  background: #ebcb08;
}
.nav-bar-search__autocomplete-list-item.active .nav-bar-search__autocomplete-mark {
  display: block;
}
.nav-bar-search__autocomplete-list-item:hover {
  background: #ebcb08;
}
.nav-bar-search__autocomplete-mark {
  font-size: 13px;
  line-height: 1;
  color: #ffffff;
  background: #000000;
  padding: 7px 14px;
  -webkit-border-radius: 22px;
          border-radius: 22px;
  display: none;
}

.nav-bar-search__close-search {
  width: 20px;
  height: 30px;
  position: absolute;
  top: 15px;
  right: 20px;
  background: url(../img/svg/close-black.svg) no-repeat center / contain;
  cursor: pointer;
}

.nav-bar-menu {
  display: flex;
  flex-flow: column;
  height: -webkit-fill-available;
}

.nav-bar-menu__list{
  list-style: none;
  padding: 0;
  padding-top: 20px;
  margin: 0;
}

.nav-bar-menu-SocialList > .social-list {
  width: 200px;
}

.nav-bar-menu__list > * {
  border-top: 1px solid  #ebcb08;
}

.nav-bar-menu__list > :last-child {
  border-bottom: 1px solid  #ebcb08;
}

.nav-bar-menu_link{
  text-decoration: none;
  color: #000000;
}

.nav-bar-menu__list-item{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0px 12px 35px;
  font-size: 27px;
  font-weight: 600;
  color: #000000;
  text-decoration: none;

  > a, div {
    line-height: 30px;
    text-decoration: none;
    color: black;
    cursor: pointer;
  }
}

.nav-bar-menu__logo {
  width: 141px;
  position: relative;
  top: 10px;
  left: 30px;
}
.nav-bar-menu__logo img {
  max-width: 100%;
}

.button-search{
    background-color: #000000; /* Green */
    border: none;
    border-radius: 22px;
    color: #f9d606;
    padding: 5px 5px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin-right: auto;
    margin-top: auto;
    height: 30px;
    width: 100px;
}

.button-search__deactivated{
  background-color: #ebcb08;
  color: #333;
}

.nav-bar-search__input-search-point {
  display: flex;
  flex-flow: row;
}

.nav-bar-search__input-search-point input {
  border: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  background: transparent;
  border-bottom: 2px solid #000000;
  color: #000000;
  font-size: 25px;
  font-weight: 500;
  width: 200px;
  outline: transparent;
  padding-top: 20px;
  margin-left: auto;
  margin-right: auto;
}
.nav-bar-search__input-search-point input::placeholder {
  color: #d7b606;
}

.portal-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &:empty {
    z-index: -1!important;
  }
}

.nav-bar-menu__close-menu {
  width: 20px;
  height: 30px;
  position: absolute;
  top: 15px;
  right: 20px;
  background: url(../img/svg/close-black.svg) no-repeat center / contain;
  cursor: pointer;
}

.main-block__title {
  width: 300px;
  padding: 0px 5px;
  display: flex;
  background: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  position: relative;
}
.main-block__right {
  display: flex;
  align-items: center;
  padding: 0 5px;
}

.main-block__left {
  display: flex;
  align-items: center;
  padding: 0 5px;
}
.main-block__small-logo {
  width: 90px;
  height: 10px;
  margin-bottom: 4px;
}
.main-block__small-logo img {
  max-width: 100%;
}
.main-block__logo {
  margin-right: 15px;
}
.main-block__logo img {
  height: 30px;
}
.main-block__city {
  font-size: 14px;
  padding-right: 21px;
  background: url(../img/arrow-bottom-icon.png) no-repeat right 1px center;
  cursor: pointer;
  background-size: 13px;
}
.main-block__input {
  margin-top: 10px;
  margin-bottom: 11px;
}

.main-block-search__logo {
  margin-right: auto;
  padding-top: 8px;
  padding-left: 23px;

}

.main-block-search__logo img {
  width: 110px;
}



.main-block__input input {
  border: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  background: transparent;
  border-bottom: 2px solid #000000;
  color: #000000;
  font-family: 'Muller';
  font-size: 18px;
  font-weight: 400;
  outline: transparent;
  padding: 9px 0;
  padding-bottom: 5px;
}
.main-block__autocomplete {
  width: 100%;
  position: absolute;
  top: 100%;
  background-color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
}
.main-block__autocomplete-list {
  list-style: none;
  padding: 0;
  margin: 0;
}
.main-block__autocomplete-list-item div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 9px 15px 6px 25px;
  font-family: 'Muller';
  font-size: 18px;
  font-weight: 600;
  color: #000000;
  text-decoration: none;
}
.main-block__autocomplete-list-item.active {
  background: #ebcb08;
}
.main-block__autocomplete-list-item.active .main-block__autocomplete-mark {
  display: block;
}
.main-block__autocomplete-list-item:hover {
  background: #ebcb08;
}
.main-block__autocomplete-mark {
  font-size: 13px;
  line-height: 1;
  color: #ffffff;
  background: #000000;
  padding: 7px 14px;
  -webkit-border-radius: 22px;
          border-radius: 22px;
  display: none;
}
.search-placemark {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
     -moz-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
     -moz-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
.search-placemark__icon {
  position: relative;
  margin-right: 8px;
  cursor: pointer;
}
.search-placemark__background {
  min-width: 44px;
}
.search-placemark__rubric-icon {
  width: 30px;
  position: absolute;
  top: 8px;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
      -ms-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
}
.search-placemark__text {
  position: absolute;
  top: calc(50% - 8px);
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  text-align: center;
  color: #000000;
  font-size: 22px;
  line-height: 1;
}
.search-placemark__info {
  background: #f9d606;
  -webkit-box-shadow: 0 3px 25px -7px rgba(0, 0, 0, 0.2);
          box-shadow: 0 3px 25px -7px rgba(0, 0, 0, 0.2);
  padding: 10px 5px 10px 16px;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-border-radius: 15px;
          border-radius: 15px;
  word-wrap: break-word !important;
  width: 280px!important;
}
.search-placemark__info-title {
  font-family: 'Muller';
  font-size: 14px;
  word-wrap: break-word !important;
  line-height: 1;
  font-weight: bold;
  margin-bottom: 5px;
  white-space: normal !important;
  width: 250px !important;
}
.search-placemark__info-content {
  font-family: 'Muller';
  text-align: left !important;
  word-wrap: break-word !important;
  white-space: normal !important;
  font-size: 11px;
  line-height: 1.2;
  width: 250px !important;
}
.search-placemark--active .search-placemark__info {
  display: block;
}
.search-placemark--top {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.search-placemark--top .search-placemark__icon {
  margin-right: 0;
  margin-top: 13px;
  margin-left: 15px;
  -webkit-box-ordinal-group: 2;
  -webkit-order: 1;
     -moz-box-ordinal-group: 2;
      -ms-flex-order: 1;
          order: 1;
}
.search-placemark--top .search-placemark__info {
  -webkit-box-ordinal-group: 1;
  -webkit-order: 0;
     -moz-box-ordinal-group: 1;
      -ms-flex-order: 0;
          order: 0;
}
.search-placemark--left .search-placemark__icon {
  margin-right: 0;
  margin-top: 0;
  margin-left: 5px;
  -webkit-box-ordinal-group: 2;
  -webkit-order: 1;
     -moz-box-ordinal-group: 2;
      -ms-flex-order: 1;
          order: 1;
}
.search-placemark--left .search-placemark__info {
  -webkit-box-ordinal-group: 1;
  -webkit-order: 0;
     -moz-box-ordinal-group: 1;
      -ms-flex-order: 0;
          order: 0;
}
.search-placemark--big .search-placemark__background {
  min-width: 56px;
}
.city-info {
    left: 0;
    z-index: 999999;
    padding: 37px 0 0;
    width: 100vw;
    box-sizing: border-box;
    background: #f9d606;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.city-info__close {
  z-index: 5;
  width: 37px;
  height: 37px;
  position: absolute;
  top: 42px;
  right: 42px;
  background: url(../img/svg/close-black.svg) no-repeat center / contain;
  cursor: pointer;
}
.city-info__close:hover {
  background-image: url(../img/svg/close-white.svg);
}
.city-info__small-logo {
  width: 296px;
  margin-bottom: 25px;
  content:url(../img/svg/+1_black2.svg);
}
.city-info__small-logo img {
  max-width: 100%;
}
.city-info__logo {
  display: none;
  width: 870px;
  margin-bottom: 92px;
}
.city-info__logo img {
  max-width: 100%;
}
.city-info__content {
  padding: 0 125px 50px 160px;
  font-size: 48px;
  font-weight: 500;
  line-height: 1;
}
/* ссылка на скачивание приложений */
.city-info__content .mobile-link {
  width: 240px;
}
.city-info__content-small {
  font-family: 'Muller';
  font-size: 19px;
  line-height: 27px;
  font-weight: normal;
  width: 500px;
}



.city-info__content-main {
  margin-top: 74px;
  line-height: 49.66px;
  margin-bottom: 72px;
}
.city-info__content-small a {
  color: #000000;
}
.city-info__content-main a{
  color: #000000;
}
.city-info__link {
  margin-top: 63px;
}
.city-info__link a {
  background: #ffffff;
  -webkit-border-radius: 40px;
          border-radius: 40px;
  -webkit-box-shadow: 0 3px 4px 0px rgba(0, 0, 0, 0.2);
          box-shadow: 0 3px 4px 0px rgba(0, 0, 0, 0.2);
  color: #000000;
  font-family: 'Muller';
  font-size: 21px;
  border: none;
  width: 100%;
  display: block;
  padding: 16px 0;
  cursor: pointer;
  font-weight: 400;
  letter-spacing: 0.3px;
  text-align: center;
}
.modal {
  background: #ffffff;
  width: 745px;
  position: relative;
  margin: 0 auto;
  -webkit-border-radius: 35px;
          border-radius: 35px;
  -webkit-box-shadow: 0 3px 25px -7px rgba(0, 0, 0, 0.2);
          box-shadow: 0 3px 25px -7px rgba(0, 0, 0, 0.2);
  padding: 45px 65px 45px 65px;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
}

.modal__left_arrow,
.modal__right_arrow {
  position: absolute;
  top: 0px;
  bottom: 0px;
  width: 50px;
  cursor: pointer;
  display: flex;
  align-items: center;
  background-repeat: no-repeat;
  background-position: center center;
}

.modal__left_arrow {
  left: -60px;
  background-image: url('../img/svg/arrow-left.svg');
}

.modal__right_arrow {
  right: -60px;
  background-image: url('../img/svg/arrow-right.svg');
}

.modal-blog {
  background: #ffffff;
  width: 745px;
  margin: 0 auto;
  -webkit-border-radius: 35px;
  border-radius: 35px;
  -webkit-box-shadow: 0 3px 25px -7px rgba(0, 0, 0, 0.2);
  box-shadow: 0 3px 25px -7px rgba(0, 0, 0, 0.2);
  padding: 0 65px 45px 65px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.relativ {
  position: relative;
}

.main-block-search__close {
  width: 15px;
  height: 15px;
  position: absolute;
  top: 10px;
  right: 5px;
  background: url(../img/svg/close-black.svg) no-repeat center / contain;
  cursor: pointer;
}


.modal__close-serchh {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 13px;
  right: 0;
  background: url(../img/svg/close-black.svg) no-repeat center / contain;
  cursor: pointer;
}
.modal__close {
  width: 31px;
  height: 31px;
  position: absolute;
  top: -28px;
  right: -30px;
  background: url(../img/svg/close-black.svg) no-repeat center / contain;
  cursor: pointer;
  color: black;
}

.modal__close__sm {
  width: 20px;
  top: 30px;
  height: 20px;
  right: 20px;
}

.modal__close:not(.modal__close__sm):hover {
  background-image: url(../img/svg/close-white.svg);
}

.modal__content {
  overflow-y: auto;
  height: 100%;
}
.modal__content--big {
  max-height: 715px;
}
.modal__content-top {
  margin-bottom: 14px;
  display: flex;
  justify-content: space-between;
}

.modal__content-center {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
     -moz-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  padding-left: 20px;
  border-bottom: 2px solid #e1e1e1;
  padding-bottom: 40px;
  margin-bottom: 28px;
}
.modal__content-center--without-line {
  border: 0;
  padding-bottom: 0;
  margin-bottom: 0;
}
.modal__content-center-left {
  width: 545px;
  margin-right: 53px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
     -moz-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
}
.modal__content-center-main {
  width: 230px;
  margin-right: 60px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
     -moz-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
}
.modal__content-item {
  border-bottom: 1px solid #ebebeb;
  padding-bottom: 20px;
  padding-top:    10px;
  margin-bottom: 20px;
  margin-top: 20px;
  padding-left: 20px;
  padding-right: 23px;
  display: block;
  text-decoration: none;
  color: inherit;
}
.modal__content-item--small-padding {
  padding-bottom: 15px;
}
.modal__description {
  font-size: 21px;
  line-height: 1.65;
  font-weight: normal;
  margin-bottom: 66px;
}
.modal__form {
  width: 100%;
  margin-bottom: 14px;
}
.modal__media-info {
  padding: 0 60px 40px 49px;
}
.modal__media img {
  max-width: 100%;
  display: block;
  margin: 0 auto;
}
.modal__media--in-top {
  margin-bottom: 48px;
}
.modal__media--in-top img {
  -webkit-border-top-left-radius: 35px;
          border-top-left-radius: 35px;
  -webkit-border-top-right-radius: 35px;
          border-top-right-radius: 35px;
}
.modal__replay {
  margin-top: 114px;
  min-height: 540px;
}
.modal--big {
  position: absolute;
  z-index: 99999;
  bottom: 40px;
  width: 1205px;
  padding: 30px 24px;
}
.modal--big .modal__close {
  top: -45px;
  right: 9px;
}
.modal--big .modal__content {
  padding-right: 0;
}
.modal--without-padding {
  padding-left: 0;
  padding-right: 0;
}
.modal--without-padding .modal__content {
  padding: 0;
}
.modal--without-bpadding {
  padding-bottom: 0;
}
.modal--without-tpadding {
  padding-top: 0;
}
.add-point__title {
  font-size: 35px;
  line-height: 1;
  font-weight: 500;
  margin: 50px;
}
.add-point__title--small {
  font-size: 47px;
  margin-top: 17px;
}
.add-point__form-title {
  position: relative;
  margin: 15px;
  margin-top:5px;
  margin-left:10px;
  margin-bottom: 15px;
  font-size: 24px;
  line-height: 27px;
}
.add-point__form-item {
  position: relative;
  margin: 20px;
  margin-left: 10px;
}
.add-point__form-item--textarea {
  margin-top: 48px;
}
.add-point__form-link {
  margin-top: 50px;
  margin-left: 10px;
  justify-content: center;
  display: flex;
}
.add-point__form-checkbox {
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 20px;
  position: relative;
}
.reviews-title {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
     -moz-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
.reviews-title__img {
  width: 43px;
  height: 43px;
  border-radius: 50%;
  display: flex;
  min-width: 43px;
  margin-right: 20px;
  align-items: center;
  justify-content: center;
  background: #f9d609;
}
.reviews-title__img_custom {
  background: none;
}
.reviews-title__img svg,
.reviews-title__img img {
  max-width: 43px;
  width: 43px;
  max-height: 43px;
}
.reviews-title__img--small {
  width: 43px;
}

.reviews-title__content{
  padding-top: 34px;
  padding-bottom: 10px;
}

.reviews-title__content-title {
  font-family: 'Muller';
  font-size: 24px;
  line-height: 27px;
  font-weight: bold;
  color: #000000;
  margin-bottom: 5px;
  margin-top: 0;
}
.reviews-title__content-description {
  font-size: 16px;
  line-height: 1;
  font-weight: 400;
  color: #808080;
}
.reviews-title__content-description span {
  position: relative;
  margin-left: 20px;
}
.reviews-title__content-description span::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: -12px;
  width: 1px;
  height: 100%;
  background: #808080;
}

.reviews-filter-content {
  font-size: 15px;
  line-height: 20px;
  font-weight: normal;
  padding-left: 67px;
  margin-top: -15px;
}

.reviews-content {
  font-family: 'Muller';
  font-size: 15px;
  line-height: 19px;
  font-weight: normal;
  width: 100%;
  word-break: break-word;
  position: relative;
}

.reviews-content__schedule {
  font-weight: bold;
  margin-top: 0px;
  margin-bottom: auto;
}
.reviews-content a {
  text-decoration: underline;
  color: #000000;
  word-wrap: break-all;
  width: 100%;
}
.reviews-content p {
  margin: 0;
}
.reviews-content p + p {
  margin-top: 21px;
}
.reviews-content__title {
  font-size: 27px;
  font-weight: bold;
}

.reviews-links {
  margin: auto;
}

.reviews-links-left.reviews-links {
  margin-right: auto;
  margin-left: 0;
}

.reviews-links-left.reviews-links a {
  margin-left: 0;
}


.reviews-links div,
.reviews-links a {
  cursor: pointer;
  font-family: 'Muller';
  font-size: 14px;
  line-height: 1;
  font-weight: 400;
  text-decoration: none;
  display: inline-block;
  -webkit-border-radius: 22px;
          border-radius: 22px;
  margin: 0px 20px;
  padding: 10px 17px;
  -webkit-box-shadow: 0 3px 3px 0px rgba(0, 0, 0, 0.2);
          box-shadow: 0 3px 3px 0px rgba(0, 0, 0, 0.2);
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  outline: transparent;
  cursor: pointer;
  border: 0;
  text-align: center;
  background: #f9d606;
  color: #000000;
  border: 2px solid #f9d606;
  min-width: 100px;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
}
.reviews-links div:hover,
.reviews-links div:focus {
  background: #000000;
  border-color: #000000;
  color: #ffffff;
}
.reviews-links div:active {
  background: #000000;
  border-color: #000000;
  color: #ffffff;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.reviews-links div:disabled {
  background: #f9d606;
  color: #000000;
  border: 2px solid #f9d606;
  opacity: 0.5;
  cursor: not-allowed;
}
.reviews-links div:hover,
.reviews-links a:focus {
  background: #000000;
  border-color: #000000;
  color: #ffffff;
}
.reviews-links div:active {
  background: #000000;
  border-color: #000000;
  color: #ffffff;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.reviews-links div:disabled {
  background: #f9d606;
  color: #000000;
  border: 2px solid #f9d606;
  opacity: 0.5;
  cursor: not-allowed;
}
.reviews-links a + a {
  margin-top: 10px;
}
.reviews-list {
  position: relative;
  padding: 0 70px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
     -moz-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}
.reviews-list__prev,
.reviews-list__next {
  position: absolute;
  top: 50%;
  left: 15px;
  -webkit-transform: translate(0, -50%);
      -ms-transform: translate(0, -50%);
          transform: translate(0, -50%);
  width: 21px;
  height: 41px;
  background: url(../img/svg/arrow-left.svg) no-repeat center / contain;
  cursor: pointer;
}
.reviews-list__next {
  left: auto;
  right: 15px;
  background-image: url(../img/svg/arrow-right.svg);
}
.reviews-list__item {
  margin-bottom: 20px;
}
.reviews-list__item:last-child,
.reviews-list__item:nth-child(2n) {
  margin-right: 0;
}
.reviews-list__item--full {
  margin-right: 0;
  width: 100%;
}
.reviews-list__item-title {
  font-size: 18px;
  line-height: 1.2;
  font-weight: bold;
  margin-bottom: 10px;
}
.info-block__head {
  margin-bottom: -15px;
}
.info-block__head--single {
  margin-bottom: 0;
}
.replay {
  text-align: center;
}
.replay__title {
  font-size: 34px;
  line-height: 1;
  font-weight: 500;
  margin-bottom: 6px;
}
.replay__description {
  font-size: 20px;
  line-height: 1;
  margin-bottom: 61px;
}
.replay__link button {
  font-family: 'Muller';
  font-size: 17px;
  line-height: 1;
  font-weight: 500;
  text-decoration: none;
  display: inline-block;
  -webkit-border-radius: 22px;
          border-radius: 22px;
  padding: 10px 17px;
  -webkit-box-shadow: 0 3px 25px -7px rgba(0, 0, 0, 0.2);
          box-shadow: 0 3px 25px -7px rgba(0, 0, 0, 0.2);
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  outline: transparent;
  cursor: pointer;
  border: 0;
  text-align: center;
  background: #f9d606;
  color: #000000;
  border: 2px solid #f9d606;
  width: 50%;
  font-size: 27px;
  padding: 20px 17px;
  -webkit-border-radius: 40px;
          border-radius: 40px;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
}
.replay__link button:hover,
.replay__link button:focus {
  background: #000000;
  border-color: #000000;
  color: #ffffff;
}
.replay__link button:active {
  background: #000000;
  border-color: #000000;
  color: #ffffff;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.replay__link button:disabled {
  background: #f9d606;
  color: #000000;
  border: 2px solid #f9d606;
  opacity: 0.5;
  cursor: not-allowed;
}
.replay__link button:hover,
.replay__link button:focus {
  background: #000000;
  border-color: #000000;
  color: #ffffff;
}
.replay__link button:active {
  background: #000000;
  border-color: #000000;
  color: #ffffff;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.replay__link button:disabled {
  background: #f9d606;
  color: #000000;
  border: 2px solid #f9d606;
  opacity: 0.5;
  cursor: not-allowed;
}
.controls {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  background-color: #ffffff;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 38px;
  border: 2px solid transparent;
  -webkit-box-shadow: 0 3px 4px 0px rgba(0, 0, 0, 0.2);
          box-shadow: 0 3px 4px 0px rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 35px;
          border-radius: 35px;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.controls__item {
  cursor: pointer;
  padding: 5px 5px;
  text-align: center;
  height: 29px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.controls__item + .controls__item {
  border-top: 2px solid #d8d8d8;
}
.controls__item svg {
  max-width: 22px;
  width: 22px;
}
.controls__item--active svg {
  fill: #333;
}
.controls:hover,
.controls:focus,
.controls--active {
  border-color: #f9d606;
}


.filter-model {
  position: absolute;
  top: 100px;
  bottom: 0;
  left: 0;
  z-index: 9999;
  overflow: hidden;
  width: 380px;
  height: 100vh;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  background: #FFFFFF;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.filter-blocks {
  width: 100%;
}

.filter-block {
  background: #f9d606;
  border-top: 1px solid #ebcb08;
  border-bottom: 0px;
  border-left: 0px;
  border-right: 0px;
}

.filter-block:not(:empty) {
  padding: 20px;
}

div.modal__content-item.filter-block-point {
  margin-top:0;
  margin-bottom:0;
}

.filter-block-points {
  padding: 0px;
  border-top: 1px solid #bbb;
  border-bottom: 0px;
  border-left: 0px;
  border-right: 0px;
  overflow: hidden;
}

.filter-button {
  font-family: 'Muller';
  font-size: 14px;
  line-height: 1;
  font-weight: 400;
  text-decoration: none;
  display: inline-block;
  -webkit-border-radius: 22px;
          border-radius: 22px;
  margin: 3px;
  padding: 8px 17px;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  outline: transparent;
  cursor: pointer;
  border: 0;
  text-align: center;
  background: #f9d606;
  border: 1px solid #333;
}

.filter-button-activated {
  font-family: 'Muller';
  font-size: 14px;
  line-height: 1;
  font-weight: 400;
  text-decoration: none;
  display: inline-block;
  -webkit-border-radius: 22px;
          border-radius: 22px;
  margin: 3px;
  padding: 8px 17px;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  outline: transparent;
  cursor: pointer;
  border: 0;
  text-align: center;
  background: #d7b605;
  border: 1px solid #333;
}

.filter-button:hover {
  background: #fbe351;
  border-color: #000000;
  color: #000;
}

.filter-button-activated:hover {
  background: #fbe351;
  border-color: #000000;
  color: #000;
}


.filter-close-button {
  width: 100%;
  font-family: 'Muller';
  font-size: 20px;
  font-weight: 500;
  line-height: 1;
  padding: 10px 17px;
  cursor: pointer;
  background: #f9d606;
  border: 0px;
  margin: 0;
}

.dNone {
  &:before {content: none !important;}
}

.filter-close-button:before {
  content: "";
  display: block;
  width: 25px;
  height: 25px;
  float: left;
  margin: 0 6px 0 0;
  background: url("../img/svg/arrow-left-square.svg") no-repeat;
  background-size: 12px;
}

.filter-block-point__content-item {
  border-bottom: 1px solid #ebebeb;
  padding-bottom: 20px;
  padding-top:    20px;
  margin-bottom: 0px;
  margin-top: 0px;
  padding-left: 25px;
  padding-right: 25px;
  display: block;
  text-decoration: none;
  color: inherit;
}

.filter-point-info-head {
  display: flex;
  flex-flow: row;

}

.filter-point-info-head__content{
  margin-top: auto;
  margin-bottom: 5px;
  margin-right: auto;
  font-size: 17px;
  font-weight: 600;
  line-height: 21px;
}

.filter-point-info-head__img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  min-width: 32px;
  margin-right: 17px;
  align-items: center;
  justify-content: center;
  /* background: #f9d609; */
}
.filter-point-info-head__img_custom {
  background: none;
}
.filter-point-info-head__img svg,
.filter-point-info-head__img img {
  max-width: 32px;
  width: 32px;
  max-height: 32px;
}
.filter-point-info-head__img--small {
  width: 32px;
}

.filter-point-info-body__content {
  font-size: 15px;
  font-weight: 400;
  padding-left: 49px;
  padding-top: 5px;
  line-height: 19px;
}

.filter-point-info-body__content > p {
  margin:0;
}

.filter-point-online-mark {
  color: #b5b5b5;
  font-size: 12px;
  border: 1px solid #b5b5b5;
  border-radius: 7px;
  margin-left: 49px;
  margin-bottom: 5px;
  padding: 3px 10px 3px 10px;
  width: max-content;
}

.cluster-active-icon {
  background-color: black;
  color: #f8d508;
  width: 43px;
  height: 43px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.point-modal {
  position: absolute;
  top: 97px;
  bottom: 0;
  left: 0;
  z-index: 9999;
  width: 380px;
  /* height: 100vh; */
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background: #FFFFFF;
  height: calc(100vh);

}
.point-modal--transparent {
  z-index: -1;
}

.SweetPanel {
  width: 100%;
  padding: 5px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 3px 4px 0 rgba(0,0,0,.2);
}

.AddressPanel {
  position: fixed;
  bottom: 0;
  width: 400px;
  left: calc(50% - 200px);
}

.AddressPanel--desktop > .SweetPanel {
  padding: 15px;
}

.AddressPanel--mobile > .SweetPanel {
  padding: 10px 15px;
}
.AddressPanel--mobile .add-point__form-item {
  margin: 10px;
  margin-bottom: 15px;
}

.AddressPanel.AddressPanel--mobile {
  width: 100%;
  left: 0;
}

.AddressPanel--desktop .add-point__form-item {
  margin: 5px;
  margin-bottom: 15px;
}

.AddressPanel--desktop .button {
  padding: 10px 20px;
}

.point-modal__close {
  width: 18px;
  height: 18px;
  position: absolute;
  top: 35px;
  right: 25px;
  background: url(../img/svg/close-black.svg) no-repeat center / contain;
  cursor: pointer;
}
.point-modal__close--white {
  background: url(../img/svg/close-white.svg) no-repeat center / contain;
}

.point-modal__content-top {
  padding: 25px;
  padding-left: 15px;
  padding-right: 70px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ebebeb;
}

.point-modal__content-top--padding {
  padding-left: 60px;
}

.point-modal__arrow-left::before {
  position: absolute;
  content: ' ';
  height: 20px;
  width: 30px;
  background: url("../img/svg/arrow-left-square.svg") no-repeat center;
  left: 15px;
  top: 35px;
  background-size: 12px;
  cursor: pointer;
}

.point-modal__content-block {
  padding: 25px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ebebeb;
  position: relative;

  &__content {
    display: flex;
    align-items: center;
    &--hidden {
      display: none;
    }
  }
}

.point-modal__content-block button:not(:last-child) {
  margin-right: 10px;
}

.point-modal__content-block--narrow {
  padding: 18px;
}

.point-modal__content-bottom--donate {
  padding: 25px 18px;
}


.point-modal__content-bottom {
  padding: 25px;
}

.content-block-l2 {
  padding-right: 10px;
  width:100px;
}

// .Main.prizes .scrollBar {
//   display: none;
// }

.stars {
  font-size: 18px;
}

.RoundedButton.RoundedButton--yellow {
  background-color: #f9d606;
  color: black;
}

.Feedback-List {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}

.Feedback-List .RoundedButton {
  white-space: nowrap;
}

.Feedback-List button:not(:last-child) {
  margin-bottom: 10px;
}

.Feedback-List__header,
.Social-List__header {
  font-size: 24.3px;
  display: block;
  margin-bottom: 15px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.12;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
}

.Social-List__button {
  width: 100%;
  margin-right: 30px;
}

.Social-List__content {
  display: flex;
  flex-direction: column;
}

.Social-List__content > button:not(:last-child) {
  padding-right: 0;
}

.Social-List > .Social-List__content > *:not(:last-child) {
  margin-bottom: 10px;
}

.NoPointDialog {
  position: absolute;
  background: transparent;
  height: 150px;
  z-index: 1;
  width: 271.4px;
  top: 0;
  bottom: 0;
  margin: auto;
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.NoPointDialog__content {
  height: 106px;
  border-radius: 19px;
  box-shadow: 0 12.7px 12.7px 0 rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  margin-bottom: 5px;
  border-radius: 10px;
  padding: 0 28px 4px 28px;
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.NoPointDialog > button {
  background-color: #f9d606;
  font-weight: 500;
}

.Check-In__container {
  display: flex
}

.Bordered-button {
  font-weight: 600;
  padding: 8px 10px;
  font-size: 15px;
  background-color: white;
  border-width: 8px;
  border-radius: 4px;
  cursor: pointer;
  outline: none;
  border-style: solid;
}

.Bordered-button:active {
  border-style: solid;
  box-shadow: 0px 1px 0px #00000033;
}

.Bordered-button--yellow {
  border-color: rgba(252, 232, 78, 1);
}

.Bordered-button--shadow {
  box-shadow: 0px 2px 0px #00000033;
}

.star__block {
  display: flex;
  align-items: center;
  margin-right: -5px;
}

.star__block > img {
  object-fit: cover;
  width: 55px;
  height: 55px;
  border-radius: 100px;
}

.star__title {
  font-size:18px;
  font-weight: 600;
  line-height: 21px;
  margin-bottom: 10px;
  margin-top: -5px;
}

div.star__wrapper {
  display: flex;
}


.modal-form-description {
  font-family: 'Muller';
  font-weight: normal;
  margin: 10px;
  margin-left:18px;
  margin-right: 0px;
  font-size: 15px;
  line-height: 17px;
}

div.react-select__placeholder{
  font-size: 16px;
}

.react-select__value-container {
  margin-bottom: -10px;
  margin-left: -10px;
}

span.react-select__indicator-separator{
  opacity: 0;
}

.react-select__dropdown-indicator{
  margin-bottom: -10px;
}

.react-select__input{
  margin-bottom: -10px;
}

.modal-form-radio {
  font-size: 16px;
  font-weight: 400;
  margin: 10px;
  margin-left: 0px;
  margin-top: 24px;
  padding-bottom: 5px;
}

.modal-form-radio > label {
  cursor: pointer;
  padding-right: 20px;
  margin-left: 5px;
  margin-top: 10px;
}

.modal-form-radio > label:last-child {
  padding-right: 0;
}

.modal-form-radio > label >input[type="radio"]:checked {
  opacity: 0;
  padding: 0px;
  margin: 0px;
}

.modal-form-radio > label > input[type="radio"] {
  opacity: 0;
  padding: 0px;
  margin: 0px;
}

.modal-form-radio > label > input[type="radio"]:checked + span:before {
  content: '';
  position: absolute;
  margin-left:-25px;
  width: 16px;
  height: 16px;
  background: url(../img/svg/radio_on.svg) no-repeat center / contain;
}

.modal-form-radio > label > input[type="radio"] + span:before {
  content: '';
  position: absolute;
  margin-left:-25px;
  width: 16px;
  height: 16px;
  background: url(../img/svg/radio_off.svg) no-repeat center / contain;
}



.modal-form-radio > input {
  margin: 10px;
}

.services-block-wrapper__close-mobile {
  width: 100%;
  height: 30px;
  border-radius: 15px 15px 0 0;
  background: url("../img/svg/arrow-down-grey.svg") no-repeat center;
  background-size: 30px;
  display: none;
}

div.services-block-wrapper-close {
  cursor: pointer;
  border: 2px solid;
  border-color: #f1f1f1;
  border-radius: 0 15px 15px 0;
  padding: 0;
  height: 175px;
  width: 30px;
  background: url("../img/svg/arrow-left-tail.svg") no-repeat center;
  background-color: #f1f1f1;
  transition: all 0.2s ease-in-out;
}

div.services-block-wrapper-close:hover{
  background-color: #f9d606;
  border-color: #f9d606;
}

.services-button-block{
  position: fixed;
  left: 3vh;
  bottom: 40px;
  z-index: 101;
}

.services-button-block__button{
  border-radius: 50%;
  width: 67px;
  height: 67px;
  border: 2px solid #FFF;
  box-shadow: 0 3px 4px 0px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-in-out;
  background: url("../img/svg/filter.svg") no-repeat center;
  background-position-x: 14px;
  background-position-y: 18px;
  background-color: #FFF
}

.services-button-block__button:hover {
  border-color: #f9d606;
}

.services-button-block__button:focus {
  outline:0;
}

@keyframes pulse {
  from {left: -70px; }
  to {left: 100%;}
}

@keyframes pulse_main {
  from {left: 0px; }
  to {left: 198px;}
}




@media (max-width: 800px) {
  .services-button-block {
    position: fixed;
    bottom: 40px;
  }
  .nav-bar-container{
    display: block;
  }

  .city-info{
    padding: 0;
  }

  /* TODO: Скорее всего это нужно будет переделать в отдельный див с отдельными логотипами
  и чтобы у дива flex был row. */
  .city-info__logo{
    display: none;
  }
  .city-info__small-logo{
    padding: 0;
    margin: 0;
    padding-top: 13px;
    padding-left: 17px;
    margin-right: auto;
    padding-bottom: 7px;
    width: 159px;
    content:url(../img/svg/+1_black2.svg);
  }
  .city-info__close{
    width: 20px;
    top: 11px;
    right: 20px;
  }

  .city-info__content{
    padding: 0;
  }
  /* ссылка на скачивание приложений */
  .city-info__content .mobile-link {
    width: 42vw;
  }

  .city-info__content-small{
    font-size: 12.6px;
    line-height: 16px;
    width: 90vw;
  }

  .city-info__link{
    padding: 0;
  }

  .city-info__link a {
    width: 90vw;
    margin: auto;
    font-size: 13px;
    margin-bottom: 80px;
    padding: 10px 0;
  }
  /* TODO */
  .point-modal > .point-modal{
    top:0;
  }
  .add-point__form-link {
    padding-bottom: 50px;
  }

  .reviews-links {
    padding-bottom: 70px;
  }
  .reviews-content__schedule {
    margin-top: 0;
  }
  .point-modal__content-block {
    align-items: baseline;
  }

  .point-modal__close{
    top: 32px;
    right: 32px;
  }

  .reviews-title__content-description{
    font-size: 20px;
  }

  .react-select__multi-value__label{
    width: 200px;
  }

  .point-modal > .modal__close {
    display: none;
  }
  div.services-block-wrapper-close{
    display: none;
  }
  .services-block-container {
    margin: auto;
  }
  .filter-block{
    background: #fff;
    border-color: #eee;
  }

  .filter-model{
    top: 50vh;
    height: 50vh;
    width: 100vw;
    z-index: 500;
  }

  button.filter-button {
    font-size:12;
    padding: 6px 17px;
    border-radius: 12px;
    border-color: #cccccc;
    background-color: #fff;

  }

  button.filter-button-activated{
    font-size:12;
    padding: 6px 17px;
    border-radius: 12px;
    border-color: #cccccc;
    background-color: #eeeeee;
  }

  button.filter-button:hover {
    border-radius: 12px;
    border-color: #cccccc;
    background-color: #fff;

  }

  button.filter-button-activated:hover{
    border-radius: 12px;
    border-color: #cccccc;
    background-color: #eeeeee;
  }

  .filter-close-button{
    font-weight: 600;
    text-align: left;
    background: #fff;

  }

  .filter-block-points{
    border-color: #eee;
  }

  .filter-close-button:before {
    content: "";
    display: block;
    width: 25px;
    height: 25px;
    float: left;
    margin: 0 6px 0 0;
    background: url("../img/svg/arrow-left-square.svg") no-repeat;
    background-size: 12px;
  }

  div.filter-blocks{
    height: 45vh;
  }

  .services-block-wrapper__close-mobile{
    display: block;
  }

  div.services-block-wrapper {
    flex-flow:column;
    border-radius: 15px 15px 0 0 ;
  }

  .services-block__button {
    padding: 0;
    max-width: 125px;
  }

  .services-block-container > a > img {
    width: 15vw;
  }

  .services-block__button{
    margin:auto;
    margin-top: 0;
    margin-bottom: auto;
  }

  .nav-bar-search__input input {
    font-weight: 400;
    font-size: 17px;
    padding-bottom: 10px;
    width: 90vw;
  }

  .nav-bar-search__input div {
    padding-left: 5vw;
  }
  .nav-bar-search__autocomplete-list-item div {
    padding: 13px 12px 5px 2vw;
  }


}

.GeoContainer {
  position: absolute;
  z-index: 99;
  width: 300px;
  height: 70px;

  right: 40px;
  top: 155px;
}

.GeoContainer--mobile {
  width: 200px;
}

.GeoMessage {
  width: calc(100% - 50px);
  color: white;
  font-weight: 600;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
}

.GeoMessage::after {
  position: absolute;
  content: ' ';
  height: 100%;
  width: 100%;
  background-color: #777777;
  opacity: 0.8;
  border-radius: 10px;
}

.GeoCloseIcon {
  width: 15px;
  height: 15px;
  z-index: 999;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.GeoMessageText {
  position: absolute;
  z-index: 998;
  padding: 10px;
  font-size: 18px;
  line-height: 20px;
}

.GeoMessageText--mobile {
  font-size: 16px;
  font-weight: 400;
}

.GeoButton {
  position: absolute;
  right: 0;
  bottom: 0;
}

.AnnouncementsContainer {
  position: absolute;
  right: -120px;
  display: block;
}

.AnnouncementsContainer--hidden {
  display: none;
}

.AnnoucementsDialogButton {
  border: none;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  outline: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding: 0;
  width: 150px;
  background-color: transparent;
}

.AnnoucementsDialogButton--mobile {
  width: 100%;
  font-size: inherit;
  font-weight: 600;
  text-align: left;
}

@media (min-width: 800px) {
  .Main {
   flex-direction: row !important;
   overflow: hidden !important;
  }
}

.Main.map, .Main.categories {
  .business-promotion-container {
    .slider-tasks {
      display: none;
    }

    .BusinessPromotion--maximized {
      display: none;
    }

    .slider__title {
      display: none;
    }
  }
}

.Main.tasks, .Main.prizes  {
  overflow: initial !important;
  height: 100vh;
}

.Main.prizes {
  overflow: hidden !important;
  position: relative;
}

.App.prizes {
  overflow: hidden !important;
}
