@font-face {
  font-family: 'Mabry Pro';
  src: url('mabry-pro/Mabry\ Pro.otf') format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Mabry Pro';
  src: url('mabry-pro/Mabry\ Pro\ Medium.otf') format("opentype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Muller';
  src: url('muller/muller-regular/MullerRegular.eot') format('eot');
  src: url('muller/muller-regular/MullerRegular.woff') format('woff'),
  url('muller/muller-regular/MullerRegular.ttf') format('ttf'),
  url('muller/muller-regular/MullerRegular.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Muller';
  src: url('muller/muller-light/MullerLight.eot') format('eot');
  src: url('muller/muller-light/MullerLight.woff') format('woff'),
  url('muller/muller-light/MullerLight.ttf') format('ttf'),
  url('muller/muller-light/MullerLight.svg') format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Muller';
  src: url('muller/muller-medium/MullerMedium.eot') format('eot');
  src: url('muller/muller-medium/MullerMedium.woff') format('woff'),
  url('muller/muller-medium/MullerMedium.ttf') format('ttf'),
  url('muller/muller-medium/MullerMedium.svg') format('svg');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Muller';
  src: url('muller/muller-bold/MullerBold.eot') format('eot');
  src: url('muller/muller-bold/MullerBold.woff') format('woff'),
  url('muller/muller-bold/MullerBold.ttf') format('ttf'),
  url('muller/muller-bold/MullerBold.svg') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Normalidad UltraExtended Var';
  src: url('normalidad/NormalidadUltraExtended-Thin.eot');
  src: local('Normalidad UltraExtended Var'), local('NormalidadUltraExtended-Thin'),
      url('normalidad/NormalidadUltraExtended-Thin.eot?#iefix') format('embedded-opentype'),
      url('normalidad/NormalidadUltraExtended-Thin.woff2') format('woff2'),
      url('normalidad/NormalidadUltraExtended-Thin.woff') format('woff'),
      url('normalidad/NormalidadUltraExtended-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}