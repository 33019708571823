@import '~@styles/colors.scss';
@import '~@styles/responsive-design.scss';
@import '~@styles/mixins.scss';

.dots-stage_3 {
  &__box {
    margin-bottom: 30px;
  }
}

.select-label {
  color: #333333;
  opacity: 0.3;
  font-weight: 400;
  font-size: 16px !important;
  line-height: 20px;
}

.MuiInput-underline {
  &:before {
    border-bottom: 1px solid #EBEBEB !important;
  }
}

.dots__button--transparent {
  background-color: white;
  border: 1px solid #EBEBEB;
}

.dots-radio-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row !important;
}

@media (min-width: 1366px) {
  .dots-stage_2__formControl {
    width: 70%;
  }

  .social {
    margin-top: 20px !important;
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
  }

  .dots__social {
    display: flex;
    flex-wrap: wrap;
    width: 100% !important;
  }

  .social-input {
    width: 47% !important;

    &.margin {
      margin-right: 30px !important;
    }
  }

  .marginZero {
    margin-bottom: 0 !important;
  }

  .dots-stage_2__desc.small,
  .dots-stage_2__desc.social {
    font-size: 16px;
    line-height: 20px;
    color: #333333;
    font-weight: 400;
    margin-top: 32px;
  }
}

.rec .ecocoin {
  bottom: 0 !important;
}
