@import '~@styles/mixins.scss';
@import '~@styles/colors.scss';
@import '~@styles/variables.scss';
@import '~@styles/responsive-design.scss';

.PrizesPage {
  @include page(790px);

  // page layout
  margin-left: 60px;
  @include media('>=desktop') {
    margin-top: 60px;
    margin-bottom: 120px;
  }
  @include media('>=tablet', '<desktop') {
    margin: 45px auto 120px;
  }
  @include media('<tablet') {
    margin: 30px 0;
    margin: 0 10px;
  }
  //--------

  &.UserPrizesPage {
    margin-bottom: 30px;
  }

  @include media('<desktop') {
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: 610px;
  };

  @include media('<tablet') {
    margin: 30px 10px 120px;
  };

  &__PrizesList + .PrizesPage__Content {
    margin-top: 60px;

    @include media('<desktop') {
      margin-top: 30px;
    }
  }

  &__Content {
    margin-bottom: 30px;
    margin-left: 27px;

    @include media('<tablet') {
      margin: 0 5px 25px;
    };
  }

  &__Title {
    @include text(28px, $shark, 28px, bold);

    @include media('<tablet') {
      font-size: 24px;
      line-height: 24px;
    };
  }

  &__Description {
    @include text(16px, $mineShaft, 1.3);
    max-width: 555px;

    @include media('<tablet') {
      font-size: 15px;
    };
  }

  &__Portal {
    position: absolute;
    left: $desktopSideBarWidth;
    right: 0;
    bottom: 0;
    padding-left: 60px;
    padding-bottom: 30px;
    background: radial-gradient(transparent, rgba(255, 255, 255, 0.5));

    @include media('<desktop') {
      left: 0;
      padding-left: 0;
    };
  }

  &__ButtonWrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 760px;

    @include media('<desktop') {
      padding: 0 15px;
      max-width: none;
    };

    @include media('<tablet') {
      max-width: 345px;
      margin: auto;
      padding: 0;
    }
  }

  &__Button {
    width: 100%;
    max-width: 520px;
    pointer-events: all;
  }
}
