body {
  margin: 0;
  padding: 0;
  overflow-y: hidden;
  overflow-x: auto;
}
@import './main.scss';
@import './App.scss';
@import '../components/UIKit/shared.styles.scss';


