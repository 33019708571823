@import '~@styles/colors.scss';
@import '~@styles/mixins.scss';

.NativeSelect {
  max-width: 100%;
  overflow: hidden;
  &__Label {
    @include text(10px, $mineShaft, 10px, 500);
    margin-bottom: 8px;
    display: block;
  }
  &__Select {
    display: block;
    @include text(16px, $mineShaft, 20px, 500);
    line-height: 20px;
    width: 100%;
    max-width: 100%;
    appearance: none;
    background-color: $alabaster;
    background-image: url(../../../img/arrow-down.svg);
    background-repeat: no-repeat, repeat;
    background-position: right 4px top calc(50% - 2px), 0 0;
    background-size: .65em auto, 100%;
    border: none;
    cursor: pointer;
    padding-bottom: 8px;
    padding-left: 0;
    border-bottom: 1px solid $gallery;
    max-width: 100%;

    > options {
      max-width: 100%;
    }

    &::-ms-expand {
      display: none;
    }

    &:focus {
      outline: none;
    }

    option {
      font-weight:normal;
    }

    &.NativeSelect__DefaultOption {
      @include text(16px, transparentize($color: $mineShaft, $amount: 0.7), 20px, 400);
    }
  }
}
