@import '~@styles/colors.scss';
@import '~@styles/mixins.scss';
@import '~@styles/responsive-design.scss';

.StepList {
  margin: 0;
  padding: 0;

  &__Fab {
    margin-left: 10px;
  }

  &__Item {
    height: 40px;
    display: flex;
    align-items: center;
    position: relative;
    @include text(16px, $mineShaft, 19px);

    @include media('<tablet') {
      font-size: 15px;
    };
  }

  &__Index {
    padding-top: 1px;
    background-color: $gold;
    @include size(40px);
    @include center();
    border-radius: 50%;
    z-index: 1;
    margin-right: 10px;
    @include text(16px, $mineShaft, 16px, 500);

    @include media('<tablet') {
      @include size(24px);
      @include text(11px, $mineShaft, 11px, 400);
      flex-shrink: 0;
    };
  }

  &__Connector {
    color: $gold;
    width: 32px;
    height: 56px;
    position: absolute;
    left: 4px;

    @include media('<tablet') {
      display: none;
    };
  }
}
