@import '~@styles/responsive-design.scss';

.PrizesList {
  display: grid;
  grid-template-columns: repeat( auto-fit, minmax(170px, 170px) );
  grid-column-gap: 25px;
  grid-row-gap: 25px;
  width: 100%;

  @include media('<tablet') {
    grid-column-gap: 15px;
    grid-row-gap: 30px;
  };

  @include media('<=phone') {
    justify-content: center;
  }
}
