@import '~@styles/mixins.scss';
@import '~@styles/colors.scss';
@import '~@styles/responsive-design.scss';

$logoWidth: 153px;

.AppBar {
  z-index: 11000;
  width: 100vw;
  background-color: $white;
  padding: 0 20px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  position: relative;
  padding-top: 10px;
  padding-bottom: 10px;

  &__social-links {
    padding: 0 57px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 24px;
  }

  &>div {
    display: flex;
    justify-content: space-between;
  }

  &__top {
    background: #F9D606;
    border-radius: 25px;
    padding: 10px 20px;
    margin-bottom: 20px;
    border: 1px solid #F9D606;

    &.white-style {
      z-index: 2;
      position: relative;
      background: #FFFFFF;
      border: 1px solid #777777;
    }
  }

  &__Icons-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__logo-title {
    font-weight: 700;
    font-size: 22px;
    line-height: 30px;
    color: #222222;
    text-decoration: none;
    margin-right: 20px;

    &.mobile {
      display: block;
    }

    &.desktop {
      display: none;
    }
  }

  &__icon-bottom {
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }
  }

  &--hidden {
    padding-bottom: 10px;
    // display: none;

    .AppBar__Icons-bottom {
      margin-left: 20px;
    }
  }

  &__SideBar {
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.2);
    border-radius: 20px;
  }

  &--mobile {
    @include media('<tablet') {
      box-shadow: none;
      background-color: $gold;
      transition: background-color 230ms ease;
    }
  }

  &__Links {
    padding: 0;
    margin: 0;
    list-style: none;
    margin-left: 25px;

    &.hidden {
      display: none;
    }

    .Link + .Link {
      margin-left: 25px;
    }

    @include media('<desktop') {
      display: none;
    }
  }

  &__Section {
    display: flex;
    align-items: center;
    flex: 1;

    &--right {
     
      justify-content: flex-end;
    }

    &--left {
     
    }
  }

  &__Logo {
    height: 30px;
    min-height: 30px;
    width: 60px;

    &.hidden {
      display: none;
    }

    @include media('<desktop') {
      position: absolute;
      left: calc(50% - #{$logoWidth / 2});
    }

    > svg {
      height: 100%;
      width: 100%;
    }
  }

  &__Dashboard {
    @include media('<desktop') {
      
    }
  }

  .AppBar__Icon {
    @include fixedSize(24px);
    cursor: pointer;

    @include media('>=desktop') {}
  }
}

.AppMore__Menu {
  width: 207px;
  display: flex;
  justify-content: space-between;
  overflow-y: scroll;
}

@media (min-width: 800px) {
  .AppBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 80px;

    &__top {
      width: 570px;
      margin-bottom: 0;
    }

    &__logo-title {
      font-weight: 700;
      font-size: 22px;
      line-height: 30px;
      text-decoration: none;
      color: #222222;

      &.mobile {
        display: none;
      }

      &.desktop {
        display: block;
      }
    }
  }
}

.AppBar__menu {
  background-color: white;
  position: absolute;
  top: -260vh;
  left: 0;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 50px;
}

.AppBar__menu-hide {
  transition: .5s;
  background-color: white;
}

.AppBar__menu-visible {
  transition: .5s;
  background-color: white;
  top: 0;
  overflow: scroll;
  height: 100vh;
}

.AppBar__icon-bottom:nth-child(3) {
  z-index: 9999;
  margin-right: 0;
}

.AppBar__icon-bottom {
  cursor: pointer;
}

.navigations-menu {
  &__title-text {
    font-weight: 700;
    font-size: 25px;
    line-height: 30px;
    text-transform: uppercase;
    color: #060606;
    margin-bottom: 20px;
  }

  &__button-icon {
    margin-right: 36px;
  }

  &__title {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }

  &__subitem {
    margin-bottom: 15px;

    a {
      font-family: 'Mabry Pro';
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      text-transform: lowercase;
      color: #060606;
      text-decoration: none;
    }
  }

  &__text-setup {
    margin-top: 10px;
    font-family: 'Normalidad';
    font-style: normal;
    font-weight: 350;
    font-size: 20px;
    line-height: 24px;
    color: #060606;
    margin-bottom: 15px;
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
  }

  &__button {
    font-family: 'Mabry Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: #060606;
    width: 320px;
    height: 65px;
    background: #FFFFFF;
    border: 1px solid #777777;
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
    text-decoration: none;
  }

  &__wrapper {
    margin-top: 150px;
    background-color: white;
  }
}

.search.desktop {
  display: none;
}

.search.mobile {
  display: block;
  height: auto !important;
}

.accMapAndSearch,
.accAbout,
.accProject {
  transform: rotate(180deg);
}

@media (min-width: 1024px) {

  .search.desktop {
    display: block;
  }

  .search.mobile {
    display: none;
  }

  .AppBar__icon-bottom {
    margin-right: 45px !important;
  }

  .AppBar__icon-bottom:nth-child(3) {
    margin-right: 0 !important;
  }

  .navigations-menu {
    &__wrapper-desktop {
      display: flex;
      justify-content: space-between;
      min-height: 480px;
    }

    &__tabs-links {
      display: flex;
      flex-direction: column;
    }

    &__social-desktop {
      display: flex;
      flex-direction: column;
    }

    &__tabs-link {
      font-weight: 350;
      font-size: 25px;
      line-height: 30px;
      text-decoration: none;
      color: #060606;
      margin-bottom: 35px;

      &.active {
        font-weight: 700;
      }
    }

    &__wrapper-desktop {
      margin-top: 190px;
    }

    &__setup {
      font-family: 'Mabry Pro';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 18px;
      color: #060606;
    }

    &__tabs-content {
      display: none;
      margin: 0;

      &.active {
        display: block;
      }
    }

    &__tabs-content li {
      margin-bottom: 30px;
    }

    &__tabs-content li a {
      font-family: 'Mabry Pro';
      font-weight: 400;
      font-size: 25px;
      line-height: 30px;
      text-transform: lowercase;
      color: #060606;
    }

    &__button-desktop {
      background: #FFFFFF;
      border: 1px solid #777777;
      width: 370px;
      height: 75px;
      font-family: 'Mabry Pro';
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      text-align: center;
      text-transform: uppercase;
      color: #060606;
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
    }
  
    &__button-desktop:nth-child(2) {
      border-radius: 38px;
    }

    &__subscribe {
      font-family: 'Mabry Pro';
      font-weight: 400;
      font-size: 16px;
      line-height: 18px;
      color: #060606;
      margin-bottom: 15px;
    }

    &__block {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &__social-desktop {
      display: flex;
      flex-direction: row;
      align-items: center;

      a {
        height: 50px;
        width: 53px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #EFEFEF;
        border-radius: 10px;
        margin-right: 30px;
      }
    }

    &__setup-app {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-top: 15px;

      a {
        width: 135px;
        height: 50px;
        background: #EFEFEF;
        border-radius: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 30px;
      }
    }

    &__block:nth-child(2) {
      border-left: 1px solid #060606;
      padding-left: 70px;
    }
  }

  .AppBar__logo-title.visible {
    z-index: 2;
  }
}

a {
  cursor: pointer;
}
