@import '~@styles/colors.scss';
@import '~@styles/responsive-design.scss';
@import '~@styles/mixins.scss';

.AppPartners {
  width: 100%;
  background-color: $white;

  &__Container {
    justify-content: center;
  }

  &__Button {
    @include media('<adaptive') {
      display: none;
    }
  }

  &__Content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__Card {
    display: flex;
    flex-direction: column;
    text-decoration: none;
    width: 136px;
    height: 142px;
    background: #FFFFFF;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    border-radius: 30px;
    display: flex;
    display: block;
    border: 0.4px solid rgba(6, 6, 6, 0.2);
    justify-content: center;
    margin-bottom: 30px;

    &:hover,
    &:active {
      cursor: pointer;

      .UIRoundedButton {
        &--partner {
          background-color: $gold;
          color: $mineShaft;
        }
      }
    }
  }

  &__title {
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: #000000;
    border-top: 0.4px solid rgba(6, 6, 6, 0.2);
    padding: 12px 0;
  }

  &__Logo {
    padding: 12px 0;
    text-align: center;
    width: 100%;

    img {
      width: 78px;
      height: 78px;
    }
  }
}

@media screen and (min-width: 1366px) {
  .AppPartners { 
    &__title {
      text-align: center;
      font-weight: 400;
      font-size: 14px;
      line-height: 14px;
      color: #000000;
      border-top: 0.4px solid rgba(6, 6, 6, 0.2);
      padding: 26px 0;
    }

    &__Content {
      display: flex;
      justify-content: left;
      flex-wrap: wrap;
    }
  
    &__Logo {
      @include size(170px);
      @include center();
      padding: 20px 0;
      width: 100%;
    }

    &__Card {
      display: flex;
      flex-direction: column;
      text-decoration: none;
      width: 208px;
      height: 236px;
      background: #FFFFFF;
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
      border-radius: 30px;
      display: flex;
      display: block;
      border: 0.4px solid rgba(6, 6, 6, 0.2);
      justify-content: center;
      margin-right: 30px;
    }
  }
}