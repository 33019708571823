@import '~@styles/colors.scss';

.SelectCity {
  display: flex;
  align-items: center;

  &__Text {
    white-space: nowrap;
    overflow: hidden;
    display: block;
  }

  &__Icon {
    margin-left: 11px;
  }
}

.ButtonPanel {
  &__Icon {
    color: $mineShaft;
    position: absolute;
    right: 15px;
  }

  &__Text {
    width: 100%;
    text-align: center;
    overflow: hidden;
    word-break: break-all;
  }
}

.city-local {
  display: flex;
  align-items: center;
  margin-right: 15px;
  margin-left: 15px;
  cursor: pointer;

  div {
    display: none;
  }

  svg {
    height: 22px;
    min-width: 17px;
    margin-right: 13px;
  }
}

@media (min-width: 1024px) {
  .city-local {
    display: flex;
    align-items: center;
    margin-right: 45px;
    cursor: pointer;
    width: 180px;

    .ButtonPanel__Text {
      text-align: left !important;
    }

    div {
      display: block;
    }

    svg {
      height: 22px;
      min-width: 17px;
      margin-right: 13px;
    }
  }
}
