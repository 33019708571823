.page-about {
    position: relative;
    overflow-y: scroll;
    min-height: 100%;
    height: 100%;

    .container-about .button-about {
        padding: 20px;
        text-align: center;
    }

    .city-info {
        background: white !important;
        padding-top: 0 !important;
    }

    .CONTAINER {
        padding: 0 20px;
    }

    .about__partners-wrapper {
        display: flex;
        justify-content: left;
        flex-wrap: wrap;
    }

    .about__partner-wrapper-img {
        width: 95px;
        height: 30px;
        margin-right: 70px;
        margin-bottom: 25px;

        img {
            height: 100%;
            width: 100%;
            object-fit: contain;
        }
    }

    .breadcrumbs {
        margin-bottom: 50px;

        a {
            font-size: 10px;
            line-height: 12px;
            font-weight: 400;
            color: #777777;
            text-decoration-line: underline;
            margin-right: 5px;
            cursor: pointer;

            &:last-child {
                margin-left: 5px;
            }
        }
    }

    .about__text {
        font-size: 28px;
        line-height: 34px;
        color: #222222;
        margin-bottom: 50px;
    }

    .about__img {
        display: flex;
        justify-content: center;
        margin-bottom: 50px;
    }

    .about__store {
        display: flex;
        justify-content: space-between;

        a {
            background: #FFE000;
            border-radius: 60px;
            width: 157.56px;
            height: 41px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
        }
    }

    .first-block {
        padding-bottom: 50px;
        padding-top: 15px;
        border-bottom: 1px solid rgba(6, 6, 6, 0.3);
        border-top: 1px solid rgba(6, 6, 6, 0.3);
        margin-bottom: 50px;
    }

    .about__city-numbers {
        h2 {
            font-size: 24px;
            line-height: 24px;
            margin-bottom: 50px;
        }
    }

    .about__city-numbers ul {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        border-bottom: 1px solid rgba(6, 6, 6, 0.3);
        margin-bottom: 50px;

        li {
            display: flex;
            justify-content: left;
            margin-bottom: 50px;
            align-items: center;

            span {
                margin-right: 14px;
                background: #F9D606;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 48px;
                height: 48px;
                border-radius: 50%;
            }

            .about__numbers {
                font-size: 36px;
                line-height: 43px;
                color: #000000;
                font-weight: 700;
            }

            .about__p-dots {
                font-size: 12px;
                line-height: 14px;
                color: #000000;
            }
        }
    }

    .about__numbers-dots {
        display: flex;
        flex-direction: column;
    }

    .about__text {
        padding-bottom: 50px;
        border-bottom: 1px solid rgba(6, 6, 6, 0.3);
        margin-bottom: 50px;

    }

    .about__text.f {
        border-bottom: none;
    }

    .about__help-list {
        padding-bottom: 35px;
        border-bottom: 1px solid rgba(6, 6, 6, 0.3);
        margin-bottom: 50px;

        h2 {
            margin-bottom: 50px;
        }

        li {
            margin-bottom: 20px;
            display: flex;
            align-items: center;
            justify-content: left;

            &:last-child {
                margin-bottom: 0;
            }

            span {
                margin-right: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                background: #F9D606;
                border-radius: 50%;
                min-width: 50px;
                height: 50px;
            }

            div {
                font-size: 14px;
                line-height: 17px;
            }
        }
    }

    .container-about {
        margin-bottom: 60px;

        .about__cooperation {
            font-size: 28px;
            line-height: 34px;
            margin-bottom: 30px;
            padding-bottom: 0;
        }

        .button-about {
            background: #F9D606;
            border-radius: 40px;
            width: 239px;
            height: 52.72px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: none;
            margin-bottom: 30px;
            text-decoration: none;
            color: #000000;
        }

        p {
            font-size: 10px;
            line-height: 12px;
            color: #777777;
        }
    }

    .desktop {
        display: none;
    }
}

@media (min-width: '1024px') {
    .page-about {
        .about__subtitle {
            font-weight: 700;
            font-size: 30px;
            line-height: 36px;
            color: #000000;
            margin-bottom: 100px;
        }

        .about__partners-wrapper {
            display: flex;
            justify-content: left;
            flex-wrap: wrap;
            margin-bottom: 60px;
        }

        .about__partner-wrapper-img {
            width: 145px;
            margin-right: 68px;
            margin-bottom: 30px;
            height: auto;
            max-height: 60px;
        }

        .mobile {
            display: none;
        }

        .desktop {
            display: block;
        }

        .first-block {
            border-top: none;
            padding-bottom: 0;
            margin-bottom: 80px;
        }

        .breadcrumbs {
            margin-bottom: 100px;

            a {
                font-size: 15px;
                line-height: 18px;
            }
        }

        .about__text {
            font-size: 50px;
            line-height: 60px;
            border: none;
            padding: 0;
            margin: 0;
            margin-bottom: 30px;
            width: 91%
        }

        .about__store {
            justify-content: left;

            a {
                margin-right: 13px;
            }
        }

        .about__inner-wrapper {
            display: flex;
            justify-content: space-between;
        }

        .about__city-numbers {
            ul {
                flex-wrap: wrap;
                flex-direction: row;
                justify-content: left;
                padding-bottom: 30px;
                margin-bottom: 80px;

                li {
                    margin-right: 67px;

                    span {
                        width: 110px;
                        height: 110px;
                        margin-right: 60px;

                        svg {
                            transform: scale(2);
                        }
                    }

                    .about__numbers {
                        font-size: 65px;
                        line-height: 78px;
                    }

                    .about__p-dots {
                        font-size: 20px;
                        line-height: 24px;
                    }
                }
            }
        }

        .about__help-list {
            ul {
                display: flex;
                flex-wrap: wrap;

                li {
                    width: 363px;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    margin-right: 30px;
                    margin-bottom: 50px;

                    &:nth-child(3n) {
                        margin-right: 0;
                    }

                    span {
                        margin-bottom: 35px;
                        width: 110px;
                        height: 110px;

                        svg {
                            transform: scale(2);
                        }
                    }

                    div {
                        font-size: 20px;
                        line-height: 24px;
                    }
                }
            }
        }

        .container-about {
            .button-about {
                height: 75px;
                width: 340px;
                font-size: 20px;
                line-height: 20px;
                margin-right: 68px;
            }

            p {
                font-size: 16px;
                line-height: 19px;
                width: 472px;
                margin: 0;
            }
        }

        .about__cooperation-wrapper {
            display: flex;
            justify-content: left;
        }

        .about__cooperation {
            width: 60%;
        }

        .AppPartners__Content {
            justify-content: left !important;

            a {
                margin-right: 68px;
            }
        }

        .about-leftblock {
            padding-top: 66px;
        }

        .CONTAINER {
            padding: 0 135px;
            margin: 0 auto;
            max-width: 1440px;
        }

        .about__text.project {
            width: 88%;
            border-bottom: 1px solid rgba(6, 6, 6, 0.3);
            padding-bottom: 80px;
            margin-bottom: 80px;
        }

        .container.about__cooperation {
            font-size: 50px;
            line-height: 60px;
        }

        .about__cooperation-wrapper {
            align-items: center;

            .button-about {
                margin-bottom: 0;
            }
        }

        .about__cooperation {
            font-size: 50px !important;
            line-height: 60px !important;
        }

        ;
    }
}