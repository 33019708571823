@import '~@styles/colors.scss';
@import '~@styles/mixins.scss';
@import '~@styles/responsive-design.scss';


.CardMobile {
  position: relative;
  padding: 40px 45px 41px;
  background-color: $gold;
  border-radius: 5px;

  @include media('<desktop') {
    padding-left: 30px;
    padding-right: 30px;
  }

  @include media('<tablet') {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 20px;
  }

  &__Title {
    @include text(28px, $shark, 28px, 800);
    margin: 0;
    margin-bottom: 12px;

    @include media('<tablet') {
      font-size: 24px;
      line-height: 28px;
    }
  }

  &__Text {
    @include text(16px, $shark, 20px);
    margin: 0;

    @include media('<tablet') {
      font-size: 15px;
    }
  }

  &__Description {
    margin-bottom: 25px;
    padding-right: 35%;

    @include media('<tablet') {
      padding-right: 0;
      text-align: center;
    }
  }

  &__Image {
    position: absolute;
    right: 30px;
    bottom: 40px;

    @include media('<tablet') {
      position: static;
      right: auto;
      bottom: auto;
      margin-bottom: 25px;
    }

    @include media('<desktop') {
      right: 20px;
      bottom: 45px;
    }
  }

  &__Apps {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 280px;

    @include media('<tablet') {
      max-width: none;
      justify-content: center;
    }

    .UIRoundedButton {
      width: 100%;
      max-width: 305px;
    }
  }
}
