@import '~@styles/colors.scss';
@import '~@styles/mixins.scss';

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.Input {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-align: left;
  border-bottom: 1px solid $gallery;
  transition: border-bottom 0.1s ease;
  min-height: 46px;
  position: relative;

  &:focus-within {
    border-bottom: 1px solid $gold;
  }

  &.Input--readOnly,
  &.Input--readOnly:focus-within {
    border-color: transparent;
    user-select: none;
    pointer-events: none;

    .Input__Label {
      color: transparentize($color: $mineShaft, $amount: 0.7);
    }
    .Input__Control {
      cursor: auto;
    }
  }

  &--select, &--dropdown {
    .Input__Control {
      cursor: pointer;
    }
  }

  &--dropdown {
    border-bottom: none!important;
    min-height: auto;

    .Input__Control {
      padding: 0;
      margin: 0;
      min-height: auto;
      font-weight: 400;
    }
  }

  &--custom-error {
    .Input__Label,
    .Input__Control {
      padding-right: 10px;
    }
  }

  &--focused {
    .Input__Label {
      color: $mineShaft;
      transform: scale(.7) translateY(-96%);
      transform-origin: top left;
      font-weight: 500;
      width: 100%;
    }
  }

  &--invalid {
    border-color: $cinnabarapprox!important;
    .Input__Label {
      color: $cinnabarapprox;
    }
  }

  &__Label {
    @include text(16px, transparentize($color: $mineShaft, $amount: 0.7), 20px, 400);
    position: absolute;
    top: 18px;
    transform-origin: bottom left;
    transition: transform 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
    white-space: nowrap;
    overflow: hidden;
    will-change: transform;
    text-align: left;
    z-index: 1;
    width: calc(100% - 30px);
    cursor: text;
  }

  &__Validation {
    position: absolute;
    right: 0;
    top: 0;
    @include text(10px, rgba(0, 0, 0, 0.3), 10px, 500);

    &--star {
      position: absolute;
      top: 20px;
      right: 0px;

      svg {
        @include size(8px);
        display: none;
        color: $gold;

        &.Input__Validation--error {
          color: $flamingo;
        }
      }

      .Input__Validation--required {
        display: inline-block;
      }
    }
  }

  &__Control {
    border: none;
    padding: 0;
    outline: none;
    margin-bottom: 4px;
    @include text(16px, $mineShaft, 20px, 500);
    width: 100%;
    padding-top: 22px;
    background-color: inherit;

    @media (pointer:none), (pointer:coarse) {
      cursor: pointer;
    }

    &::placeholder {
      @include text(16px, $black, 20px);
      color: rgba($color: $mineShaft, $alpha: 0.3)
    }

    &:disabled {
      user-select: all;
      background-color: transparent;
      cursor: pointer;
    }

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    @keyframes onAutoFillStart {
    }

    @keyframes onAutoFillCancel {
    }
    &:-webkit-autofill {
      animation-name: onAutoFillStart;
      transition: background-color 50000s ease-in-out 0s;
      animation-delay: 50ms;
    }
    &:not(:-webkit-autofill) {
      animation-name: onAutoFillCancel;
      animation-delay: 50ms;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active  {
      -webkit-box-shadow: 0 0 0 30px white inset !important;
    }
  }
}
