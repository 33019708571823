@import '~@styles/colors.scss';
@import '~@styles/responsive-design.scss';
@import '~@styles/mixins.scss';
@import '~@styles/variables.scss';

.CityDialog {
  padding: 40px 113px 40px 112px;

  &__Content {
    padding: 0 10px;
    display: flex;
    flex-direction: column;
  }

  @include media('>=tablet', '<desktop') {
    padding: 40px 79px 40px 79px;
  }

  @include media('<tablet') {
    padding: 5px 0 10px;

    .CityDialog__Content {
      padding: 0 5px;
    }
  }

  &__Title {
    @include text(28px, $shark, 28px);
    font-weight: bold;
    margin: 0;
    margin-bottom: 10px;
    order: 1;
    @include media('<tablet') {
      order: 2;
    }
  }

  &__Description {
    @include text(16px, $mineShaft, 20px);
    margin: 0 0 30px;
    margin-bottom: 30px;
    order: 2;
    @include media('<tablet') {
      order: 3;
    }
  }

  &__Image {
    display: flex;
    justify-content: center;
    min-width: 90px;
    min-height: 100px;
    margin-bottom: 30px;
    order: 3;

    @include media('<tablet') {
      margin-bottom: 25px;
      order: 1;
    }
  }


  &__Autocomplete {
    margin-bottom: 60px;
    order: 4;

    > .NativeSelect__Select {
      background-color: white;
    }

    @include media('<tablet') {
      margin-bottom: 30px;
    }
  }

  &__Button {
    order: 5;
  }
}
