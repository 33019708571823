@import '~@styles/mixins.scss';
@import '~@styles/colors.scss';
@import '~@styles/variables.scss';
@import '~@styles/responsive-design.scss';

.prize_delivery {
  width: 100%;
  &__input {
    width: 100%;
    position: relative;
    margin-bottom: 30px;

    label {
      font-family: 'Mabry Pro';
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      text-transform: lowercase;
      color: #060606;
    }

    input {
      width: 100%;
      border: none;
      border-bottom: 1px solid #777777;
      padding: 10px;
    }
  }

  &__textarea {
    margin-bottom: 96px;
    label {
      font-family: 'Mabry Pro';
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      text-transform: lowercase;
      color: #060606;
      margin-bottom: 13px;
      display: block;
    }

    textarea {
      padding: 10px;
      height: 105px;
      width: 100%;
    }
  }

  &__form {
    button {
      margin-top: 96px;
      border: none;
      outline: none;
    }
  }

  .mobile  {
    display: block;
  }

  .desktop {
    display: none;
  }
}

@media (min-width: 1200px) { 
  .prize_delivery {
    .prix_in__nav {
      margin-bottom: 100px;
    }
    
    .prize_congratulation__container {
      max-width: 370px;
      margin: 0 auto;
    }

    .prix_in__title {
      margin-top: 100px;
    }

    .mobile  {
      display: none;
    }

    .desktop {
      display: block;
    }

    svg {
      display: none;
    }
  }
}
