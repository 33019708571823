@import '~@styles/colors.scss';
@import '~@styles/mixins.scss';

.RadioButton {
  &__Container {
    display: block;
    position: relative;
    cursor: pointer;
    user-select: none;
    @include text(16px, $mineShaft, 20px);
    padding-left: 32px;
    margin-bottom: 15px;
    white-space: nowrap;
    line-height: 25px;
  }

  &__Default {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ .RadioButton__Checkmark {
      background: url(../../../img/design/radio-check.svg) no-repeat;
      background-size: contain;
      border: none;
      @include size(24px);
      top: -1px;
      left: -1px;
    }
  }

  &__Checkmark {
    position: absolute;
    top: 0;
    left: 0;
    @include size(21px);
    background-color: $white;
    border: 2px solid $frenchGray;
    border-radius: 50%;
    @include center();
  }
}
