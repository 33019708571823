@import '~@styles/colors.scss';
@import '~@styles/mixins.scss';

.Fab {
  @include size(40px);
  @include center();
  padding: 0;
  background-color: $gold;
  border-radius: 50%;
  border: none;
  cursor: pointer;
}