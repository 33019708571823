@import '~@styles/responsive-design.scss';

@include media('<tablet') {
  .AppReturnLoyaltyButton__BtnText {
    &--long {
      display: none;
    }
    &--short {
      display: inline-block;
    }
  }
}

@include media('>=tablet') {
  .AppReturnLoyaltyButton__BtnText {
    &--long {
      display: inline-block;
    }
    &--short {
      display: none;
    }
  }
}