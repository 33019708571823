@import '~@styles/mixins.scss';
@import '~@styles/colors.scss';
@import '~@styles/variables.scss';
@import '~@styles/responsive-design.scss';

.prize_congratulation {
  a.default {
    background: #ffffff;
    border: 1px solid #060606;
    margin-bottom: 10px;
  }

  &__container {
    padding: 0 20px;
  }

  &__description {

    p {
      font-family: 'CoFo Robert';
      font-weight: 400;
      font-size: 20px;
      line-height: 30px;
      color: #060606;

      &:last-child {
        margin: 0;
      }
    }

    margin-bottom: 50px;
  }

  &__copy-code {
    font-family: 'CoFo Robert';
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #222222;
    text-align: center;
    margin-bottom: 15px;
  }

  &__text-code {
    font-weight: 700;
    font-size: 30px;
    line-height: 45px;
    color: #060606;
    text-align: center;
    margin-bottom: 45px;
  }

  &__arrow {
    margin-top: 32px;
    margin-bottom: 62px;
  }

  button.prix_in__nav {
    border: none !important;
    margin-bottom: 100px;
  }
}

@media (min-width: 1200px) {
  .prize_congratulation {
    &__right-block {
      min-width: 320px;
    }

    &__copy-code {
      text-align: left;
    }

    &__text-code {
      text-align: left;
    }

    svg {
      display: none;
    }

    &__left-block {
      margin-right: 28px;
    }

    .prize_congratulation__container {
      max-width: 1018px;
      margin: 0 auto;
    }

    .prix_in__wrapper-c {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      align-items: baseline;
    }
  }

  .prix_in__title {
    text-transform: uppercase;
    margin-top: 20px;
  }
}
