@import '~@styles/colors.scss';

.Expander {
  &__Content {
    margin-bottom: 20px;
  }

  &__Button {
    position: relative;
    margin: auto;
    width: 95px;
    height: 20px;
    padding: 0;

    &:hover {
      color: $mineShaft;
      background-color: $gold;
    }

    svg {
      display: block;
      width: 10px;
      height: 6px;
    }
  }

  .ReactCollapse--collapse {
    transition: height 0.3s ease;
  }
}
