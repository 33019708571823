.footer {
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
    padding: 0 20px;
    padding-top: 30px;
    background: #F6F6F6;
    width: 100%;

    &__item-lvl2, &__title {
        font-weight: 700;
        font-size: 20px;
        line-height: 27px;
        color: #060606;
        list-style: none;
    }

    &__list-lvl2 a {
        text-decoration: none;
    }

    &__item-lvl2-not-link {
        margin-bottom: 30px;
        margin-top: 30px;
    }

    &__title {
        padding-bottom: 30px;
        border-bottom: 1px solid #060606;
    }

    &__link-lvl2 {
        font-weight: 400;
        font-size: 16px;
        line-height: 16px;
        font-family: 'Mabry Pro';
        color: #060606;
        text-decoration: none;
    }

    &__navbar-lvl2 {
        border-top: 1px solid #060606;
        padding-top: 30px;
        border-bottom: 1px solid #060606;
        padding-bottom: 30px;
        margin-bottom: 30px;
    }

    &__list-lvl2 {
        margin-bottom: 20px;
        display: flex;
        justify-content: left;
        flex-wrap: wrap;

        li {
            margin-right: 12px;

            a {
                font-family: 'Mabry Pro';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 16px;
                color: #060606;
                cursor: pointer;
            }
        }
    }

    &__social-title {
        font-family: 'Mabry Pro';
        font-weight: 400;
        font-size: 16px;
        line-height: 16px;
        color: #060606;
         
    }

    &__list-lvl2:not(:last-child) {
        margin-bottom: 20px;
    }

    &__social-links a {
        height: 60px;
        width: 60px;
        background: #FFFFFF;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 30px;
        margin-top: 30px;
        margin-bottom: 30px;
        cursor: pointer;
        z-index: 2;
    }

    &__social-links a:last-child {
        margin-right: 0px;
    }

    &__social-group {
        display: flex;
    }

    &__social-market {
     display: flex;
     justify-content: space-between;
     
        a {
            width: 140px;
            height: 60px;
            cursor: pointer;
            z-index: 2;
        }
    }
}

ul, li {
    list-style: none;
    padding-left: 0;
}

@media (min-width: 1200px) {
    .footer__list {
        padding: 13px 0;
    }

    .footer__list > li {
        display: flex;
        justify-content: space-between;

        & > ul {
            width: 212px;

            & > li {
                margin-bottom: 10px;
            }
        }
    }

    .footer__item-lvl2-not-link {
        margin-top: 0;
    }

    .footer__list-lvl2 {
        display: flex;
        flex-direction: column;
        margin-bottom: 0;
    }

    .mt {
        margin-top: 30px;
    }

    .footer__navbar-lvl2 > ul {
        display: flex;
        justify-content: space-between;
        margin: 0;
    }

    .footer__list-lvl2 > li {
        margin-bottom: 10px;
    }

    .footer__social-links {
        display: flex;
        justify-content: space-between;
    }

    .footer__social-links div:last-child .footer__social-title {
        text-align: right;
    }
}

.footer__link-b {
    text-decoration: none;
    color: #060606;
    cursor: pointer;
}

.suppotLink {
    text-decoration: none;
    width: 190px !important;
    padding: 20px;
    color: black;
    cursor: pointer;
}