@import '~@styles/mixins.scss';
@import '~@styles/colors.scss';
@import '~@styles/responsive-design.scss';

.accordion-item {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);

  &__poiner {
    cursor: pointer;
  }

  &__sq,
  &__el {
    width: 100%;

    .accordion-title {
      height: 60px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px 30px;
      color: #ffffff;
      font-weight: 700;
      font-size: 20px;
      line-height: 27px;
      text-transform: uppercase;

      a {
        color: #ffffff;
      }
    }
  }

  &__sq {
    .accordion__link {
      color: #060606;
    }
  }

  .accordion__more {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-decoration-line: underline;
    color: #060606;
    text-transform: initial;
  }

  .accordion__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
    padding-right: 20px;
    padding-left: 20px;
  }

  .login-map {
    display: flex;
  }

  .accordion__nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .accordion__links {
    display: flex;
    flex-direction: column;
    margin-top: 45px;
    margin-bottom: 50px;

    a {
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      text-decoration-line: underline;
      text-transform: lowercase;
      margin-bottom: 16px;
    }
  }

  .accordion-content {
    padding-bottom: 30px;
    padding-right: 30px;
    padding-left: 30px;
    overflow-wrap: break-word;
  }

  .accordion__description {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
  }

  .accordion__nav span:first-child {
    text-decoration: underline;
  }

  &__el {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 30px;

    .accordion-title svg path {
      stroke: white;
    }

    .accordion__nav {
      span:first-child {
        color: white;
      }

      path {
        stroke: white;
      }
    }
  }
}

.accordion-item:nth-child(1) {
  background: #f9d606;
  border-radius: 25px;

  .accordion-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 30px;
    color: #060606;
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    text-transform: uppercase;
  }
}

.accordion-item:nth-child(2) {
  background: #5000c1;
}

.accordion-item:nth-child(3) {
  background: #ee02e5;

  .accordion-title a {
    color: #060606;
  }
}

.accordion-item:nth-child(4) {
  background: #060606;
}

.accordion-item:nth-child(5) {
  background: #fe3e2b;

  .accordion-title a {
    color: #060606;
  }
}

.accordion-item__city .accordion-title {
  padding-top: 0;
}

.accordion-item__city a{
  color: #060606;
}

.accordion-item__el {
  .accordion__description {
    color: #ffffff;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
  }

  .accordion__link {
    color: #ffffff;
  }
}

@include media('>=desktop') {

  .AppBar__social-links {
    width: 300px;
    margin: 0 auto;
  }

  .AppBar__social-link {
    background: #EFEFEF;
    height: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;

    svg g:nth-child(2) path {
      fill: #060606;
    }
  }

  .accordion-title div:nth-child(2), .accordion__nav {
    display: none !important;
  }

  .accordion-item:first-child {
    width: 570px;
    height: 300px;
    border-radius: 30px;
    order: 2;

    .accordion__nav {
      display: none;
    }

    .accordion-content {
      display: flex;
      align-items: baseline;
      display: flex;
      align-items: baseline;
      position: relative;
    }

    .accordion__description {
      width: 260px;
    }

    .accordion__links {
      margin-top: 0;
      position: absolute;
      right: 10px;
      top: -40px;
    }
  }

  .accordion-item:nth-child(2) {
    order: 3;
    width: 570px;
    height: 300px;
    border-radius: 150px;
    padding-top: 70px;
    padding-left: 50px;
    padding-right: 50px;
    position: relative;

    .accordion__nav {
      display: none;
    }

    .accordion-content {
      display: flex;
      align-items: baseline;
      display: flex;
      align-items: baseline;
    }

    .accordion__description {
      width: 356px;
    }

    .accordion__links {
      margin-top: 0;
      position: absolute;
      right: 50px;
      top: 90px;
      width: 100px;
    }
  }

  .accordion-item:nth-child(3) {
    order: 5;
    width: 300px;
    height: 300px;

    .accordion-title {
      padding: 30px;
      box-sizing: content-box;
      padding-bottom: 0;
    }
  }

  .accordion-item:nth-child(4) {
    order: 4;
    width: 300px;
    height: 300px;
    border-radius: 50%;

    .accordion-title {
      padding: 0;
      padding-top: 70px;
      text-align: center;
      padding-bottom: 30px;
      display: flex;
      justify-content: center;
    }

    .accordion-content {
      text-align: left;
    }

    .accordion__link:nth-child(2) {
      padding-left: 20px;

      .accordion-content {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
      }
    }
  }

  .accordion-item:nth-child(5) {
    order: 1;
    width: 300px;
    height: 600px;
    border-radius: 150px;

    .accordion-title {
      padding-top: 169px;
    }
  }
}
