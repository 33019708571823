@import '~@styles/colors.scss';

@mixin text($fontSize: 16px, $color: $black, $lineHeight: 16px, $fontWeight: 400) {
  font-family: 'Muller';
  font-style: normal;
  color: $color;
  font-size: $fontSize;
  line-height: $lineHeight;
  font-weight: $fontWeight;
}


@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
}

@mixin fixedSize($width, $height: $width) {
  @include size($width, $height);
  min-width: $width;
  max-height: $height;
}

@mixin center() {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin scrollbar2() {
  position: relative;

  &::-webkit-scrollbar {
    display: block;
    width: 2px;
  }

  &::-webkit-scrollbar-track {
      background: transparent;
  }

  &::-webkit-scrollbar-thumb {
      background-color: $gold;
      border-right: none;
      border-left: none;
  }

  &::-webkit-scrollbar-track-piece:end {
      background: transparent;
      margin-bottom: 25px;
  }

  &::-webkit-scrollbar-track-piece:start {
      background: transparent;
      margin-top: 25px;
  }
}

@mixin scrollbar() {
  &::-webkit-scrollbar {
    width: 14px;
    height: 18px;
  }
  &::-webkit-scrollbar-thumb {
    height: 6px;
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    -webkit-border-radius: 7px;
    background-color: rgba(0, 0, 0, 0.15);
    -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
  }
  &::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
  }
  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }
}

@mixin scrollbar3() {
  position: relative;

  &::-webkit-scrollbar {
    display: block;
    height: 2px;
  }

  &::-webkit-scrollbar-track {
      background: transparent;
  }

  &::-webkit-scrollbar-thumb {
      background-color: $gold;
      border-right: none;
      border-left: none;
  }

  &::-webkit-scrollbar-track-piece:end {
      background: transparent;
  }

  &::-webkit-scrollbar-track-piece:start {
      background: transparent;
  }
}

@mixin appearing() {
  &-enter,
  &-appear {
    opacity: 0;
    transform: scale(0.9);
  }
  &-enter-active,
  &-appear-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 200ms, transform 200ms;
  }
  &-exit {
    opacity: 1;
  }
  &-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 200ms, transform 200ms;
  }
}

@mixin scaling() {
  &-enter,
  &-appear {
    opacity: 0;
    transform: 0;
  }
  &-enter-active,
  &-appear-active {
    opacity: 1;
    transform: scale(1);
    transition: opacity 200ms, transform 200ms;
  }
  &-exit {
    opacity: 1;
    transform: scale(1);
  }
  &-exit-active {
    opacity: 0;
    transform: scale(0);
    transition: opacity 200ms, transform 200ms;
  }
}

@mixin page($maxWidth: 520px) {
  max-width: $maxWidth;

  &__Title {
    margin: 0 0 10px;

    @include media('>=tablet') {
      @include text(28px, $shark, 28px, bold)
    }

    @include media('<tablet') {
      @include text(24px, $shark, 24px, bold)
    }
  }

  &__Description {
    @include text(16px, $mineShaft, 21px);
    margin: 0 0;
  }
}

@mixin linkButton() {
  background-color: transparent;
  transition: color 0.1s ease;

  &:hover {
    background-color: transparent;
    color: $gold;
  }

  &.active {
    background-color: transparentize($color: #000000, $amount: 0.95);
    color: $mineShaft;
  }

  @include media('<tablet') {
    height: 35px;
    padding: 7.5px 25px;
  }
}
