@import '~@styles/colors.scss';
@import '~@styles/mixins.scss';
@import '~@styles/responsive-design.scss';

.Task {
  position: relative;
  padding: 25px;
  display: flex;
  width: 100%;
  min-height: 180px;
  border-radius: 20px;

  @include media('<tablet') {
    flex-direction: column-reverse;
  };

  &__Content {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
    flex: 0 0 calc(100% - 170px);
    max-width: calc(100% - 170px);

    @include media('<tablet') {
      align-items: center;
      flex-basis: 100%;
      max-width: 100%;
    };
  }

  .Shield {
    position: absolute;
    top: 15px;
    right: 15px;

    @include media('<tablet') {
      top: 10px;
      right: 10px;
    };
  }

  &__Controls {
    display: flex;
  }

  &__Button {
    @include text(16px, $mineShaft, 16px, 500);

    &:first-child {
      margin-right: 20px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &--primary {
    background-color: $white;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  }

  &--secondary {
    border: 5px solid #{$gold};
    background-color: $gold;
  }

  &--disabled {
    .Task__Description,
    .Score__Points,
    .Score__Currency {
      color: $silverChalice;
    }

    .TaskDescription__Tip {
      display: inline-block;
    }
  }

  &__Score {
    @include center();
    flex: 1;

    @include media('<tablet') {
      margin-bottom: 20px;
    };
  }
}

.Score {
  &__Points {
    @include text(48px, $mineShaft, 48px);
    margin-bottom: 5px;
    @include media('<desktop') {
      text-align: center;
    }
  }

  &__Currency {
    @include text(16px, $mineShaft, 16px);
    text-align: center;
  }

  &__Container {
    flex-direction: column;
    display: inline-flex;
    width: auto;
  }
}


.TaskDescription {
  width: 100%;
  &__Content {
    @include text(16px, $mineShaft, 19px);
    margin: 0;
    margin-bottom: 25px;
    padding: 5px 0 0 5px;
    max-height: 62px;
    overflow: hidden;

    p {
      &:first-child {
        margin-top: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }

    @include media('<tablet') {
      margin-bottom: 20px;
      padding: 0;
      text-align: center;
    };
  }

  &__Tip {
    @include text(11px, $black, 11px);
    margin: 0 0 15px;
    display: none;
    padding-left: 5px;
  }
}
