.loyaltyProgram {
  width: 100%;

  h2 {
    font-weight: 700;
    font-size: 30px;
    line-height: 30px;
    color: #060606;
  }

  &__text {
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #222222;
    margin-bottom: 60px;
  }

  ul {
    display: flex;
    flex-direction: column;
    padding: 0;

    li {
      padding: 0;
      margin: 0;
      display: flex;
      margin-bottom: 30px;
      font-size: 15px;
      line-height: 21px;

      span {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        background: #222222;
        border-radius: 50%;
        font-weight: 400;
        font-size: 16px;
        line-height: 27px;
        color: #FFFFFF;
        margin-right: 27px;
        min-width: 30px;
      }
    }
  }

  &__link {
    border: 1px solid #060606;
    border-radius: 40px;
    width: 100%;
    max-width: 317px;
    background-color: #FFFFFF;
    height: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    color: #060606;
    text-transform: uppercase;
    text-decoration: none;
    cursor: pointer;
    margin-bottom: 80px;
  }

  &__wrapper {
    border-bottom: 0.5px solid #777777;
    margin-bottom: 60px;
  }

  &__wrapper-img {
    background: #F9D606;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 60px;
    margin-right: 20px;
  }

  &__card {
    display: flex;
    margin-bottom: 30px;

    div {
      font-weight: 500;
      font-size: 14px;
      line-height: 14px;
      color: #222222;

    }

    h3 {
      margin: 0;
      margin-bottom: 15px;
    }
  }

  &__cards {
    margin-bottom: 40px;
    border-bottom: 0.5px solid #777777;
  }

  &__prim-wrapper {
    background: #FFE04D;
    text-align: center;
    padding: 20px;
    padding-bottom: 0;
  }

  &__left-text {
    font-size: 36px;
  }

  &__icons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 40px;
  }

  &__right {
    position: relative;
    bottom: -4px;
    margin-bottom: 120px;
  }

  &__email, &__email a {
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    color: #777777;
    margin-bottom: 50px;
    text-decoration: none;
  }

  &__left-text {
    text-align: left;
  }

  &__desk {
      display: none;
  }
}

@media screen and (min-width: 1366px) {
  .loyaltyProgram {
    .CONTAINER {
      max-width: 1170px;
      margin: 0 auto;
    }

    &__desk {
        display: block;
    }

    &__inner-wrapper {
      max-width: 568px;
      margin-right: 140px;
    }

    &__wrapper {
      display: flex;
    }

    &__cards {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    &__wrapper-text {
      max-width: 373px;
    }

    &__wrapper-img {
      width: 110px;
      min-width: 110px;
      height: 110px;

      svg {
        height: 110px;
        width: 40px;
      }
    }

    &__card {
      display: flex;
      align-items: center;
    }

    &__prim-wrapper {
      height: 310px;
      display: flex;
      margin-bottom: 29px;
    }

    &__icons {
      justify-content: left;
      margin-bottom: 0;

      svg {
        margin-right: 40px;
      }
    }

    &__left {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-bottom: 20px;

      svg {
        margin-top: 0;
      }
    }

    &__left-text {
      font-size: 50px;
    }

    &__right {
        bottom: -7px;
    }
  }
}
