@import '~@styles/colors.scss';
@import '~@styles/mixins.scss';

$notificationWidth: 345px;

.Notification {
  width: $notificationWidth;
  z-index: 15000;
  background-color: $white;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  padding: 20px;
  display: flex;
  will-change: transform;
  position: absolute;
  right: -#{$notificationWidth + 100px};
  top: 0;

  &__Content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  &__Portal {
    top: 75px;
    position: absolute;
    right: 0;
  }

  &--success {
    color: $success;
  }

  &--fail {
    color: $cinnabarapprox;
  }

  &__Icon {
    margin-right: 15px;
    width: 40px;
    height: 40px;
    margin-top: 4px;
    color: inherit
  }

  &__Title {
    @include text(16px, inherit, 19px);
    margin: 0 0 5px; 

    &--no-message {
      margin: 0;
    }
  }

  &__Message {
    @include text(12px, $mineShaft, 14px);
    margin: 0;
  }

  &-enter {
    transform: translateX(0);
  }

  &-enter-active {
    transform: translateX(-100%) translateX(-115px );
    transition: transform 130ms ease;
  }

  &-enter-done {
    transform: translateX(-100%) translateX(-115px);
  }

  &-exit {
    transform: translateX(-100%) translateX(-115px);
  } 

  &-exit-active {
    transform: translateX(0);
    transition: transform 130ms ease-out;
  }

  &-exit-done {
    transform: translateX(0);
  }
}