.business-partner-ship {
  &__primary-block {
    background: rgba(255, 224, 77, 0.69);
    padding: 40px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 373px;
    margin-top: 30px;

    a {
      font-size: 12px !important;
      line-height: 12px !important;
      color: #060606;
    }
  }

  &__text {
    font-weight: 400;
    font-size: 36px;
    line-height: 43px;
    color: #000000;
  }
}

.about-sub {
  &__text-big p {
    font-weight: 400;
    font-size: 26px;
    line-height: 31px;
    color: #222222;
  }
}

@media (min-width: 1366px) {
  .about-sub {
    &__text-big p {
      font-weight: 400;
      font-size: 50px;
      line-height: 60px;
      color: #222222;
    }
  }

  .business-partner-ship {

    &__text {
      width: 50%;
    }

    &__links {
      display: flex;

      a {
        margin-right: 60px;
        font-weight: 300;
        font-size: 25px !important;
        line-height: 25px !important;
      }
    }
  }
}
