@media (min-width: 1366px) {
    .medianetwork {
        .CONTAINER {
            margin: 0 auto;
            max-width: 1170px;
        }

        &__text {
            max-width: 770px;
            p {
                font-weight: 400;
                font-size: 30px;
                line-height: 36px;
                color: #222222;
            }
        }
    }
}