.about-sub {
  &__text {
    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #222222;
    }
  }

  &__img {
    display: flex;
    justify-content: center;
    margin-bottom: 80px;
  }

  &__button {
    background: #F9D606;
    border-radius: 40px;
    width: 340px;
    height: 75px;
    font-size: 16px;
    line-height: 16px;
    font-weight: 700 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    color: #060606;
  }
}

@media (min-width: 1366px) {
  .about-sub {
    &__flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__text {
      p {
        font-weight: 400;
        font-size: 30px;
        line-height: 36px
      }
    }
  }

  .citizens-partner-ship {
    &__left-block {
      max-width: 768px;
    }
  }
}
