@import '~@styles/colors.scss';
@import '~@styles/responsive-design.scss';
@import '~@styles/mixins.scss';

.dots-container {
  padding: 0 20px;
}

.dots__arrowMap {
  display: flex;
  align-items: center;
  margin: 30px 0;
  color: #000000;
  text-decoration: none;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  cursor: pointer;

  svg {
    margin-right: 12px;
  }
}

.dots__title {
  font-weight: 700;
  font-size: 30px;
  line-height: 30px;
  margin-bottom: 10px;
}

.dots-stage__text {
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
}

.dots-stage__img {
  margin-right: 30px;
}

.dots__subtitle {
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
}

.dots-stage_1 {
  &__desc {
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    margin-bottom: 36px;
  }

  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 33px;

    &:last-child {
      margin-bottom: 44px;
    }
  }
}

.dots__button {
  display: flex;
  width: 100%;
  height: 64px;
  background: #F9D606;
  border-radius: 30px;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-bottom: 25px;
}

.dots__save-late {
  font-size: 16px;
  line-height: 20px;
  text-decoration-line: underline;
  text-align: center;
  display: block;
  margin-bottom: 60px;
  color: #000000;
}

.dots__select {
  margin-bottom: 30px;
}

@media (min-width: 1366px) {

  .dots-container, .dots-action__desc-container {
    position: relative;
  }

  .dots__arrowMap {
    position: absolute;
    left: -300px;
    top: 0;
  }

  .dots-select .div {
    min-width: 100% !important;
  }

  .dots__title {
    font-size: 40px;
    line-height: 48px;
    margin-bottom: 20px;
  }

  .dots-stage__text {
    font-size: 16px;
  }

  .dots-container {
    max-width: 572px;
    margin: 0 auto;
    margin-top: 40px;
    padding: 0;
  }

  .dots-stage_1 {
    &__desc {
      font-size: 16px;
      line-height: 22px;
    }
  }

  .dots__flex-container {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
    margin-bottom: 127px;
  }

  .dots__save-late {
    margin-bottom: 0;
    cursor: pointer;
  }

  .dots__button {
    width: 227px;
    margin-bottom: 0;
    text-decoration: none;
    color: black;
  }
}
