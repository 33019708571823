@import '~@styles/colors.scss';
@import '~@styles/responsive-design.scss';
@import '~@styles/mixins.scss';
@import '~@styles/variables.scss';

.AppSideBar {
  &__Content {
    color: $mineShaft;
    width: $desktopSideBarWidth;
    min-width: $desktopSideBarWidth;
    height: 100%;
    padding: 15px;
    background-color: $gold;
    pointer-events: auto;
    padding-top: 70px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: auto;
    overflow-x: hidden;

    @include scrollbar();

    @include media('<tablet') {
      width: 100%;
      min-width: 100%;;
      border-radius: 0;
    }
  }

  &__Main {
    margin-bottom: 6px;
  }

  &__Loyalty,
  &__Footer {
    .UIRoundedButton + .UIRoundedButton {
      margin-top: 6px;
    }
  }

  &__Auth {
    margin-bottom: 24px;

    .UIRoundedButton + .UIRoundedButton {
      margin-top: 10px;
    }
  }

  &__Icon {
    color: inherit;
    margin-right: 16px;
    @include size(24px);
    position: relative;
  }

  &__SignOutIcon {
    transform: scale(1.05);
    position: absolute;
    left: -0.09em;
    top: -0.02em;
  }

  &__Link {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    > span {
      position: relative;
      top: 1px;
    }
  }
}

