@import '~@styles/colors.scss';
@import '~@styles/responsive-design.scss';
@import '~@styles/mixins.scss';

.SocialAuth {
  &__List {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    padding-bottom: 30px;
  }

  &__ListItem {
    + .SocialAuth__ListItem {
      margin-left: 25px;
    }
  }

  &__Title {
    @include text(16px, $mineShaft, 16px);
    margin: 0 0 15px;
  }

  &__Icon {
    color: $shark;
    @include center();
  }
}

.SocialButton {
  @include size(40px);
  @include center();
  background-color: $wildSand;
  border-radius: 8px;
  border: none;
  outline: none;
  padding: 0;

  &__Icon {
    display: flex;
    align-items: center;
  }

  &--vk {
    .SocialButton__Icon {
      height: 100%;
    }
  }

  &--google {
    .SocialButton__Icon {
      height: 100%;
    }
  }

  &--fb {
    .SocialButton__Icon {
      height: 100%;
    }
  }
  
  &:not(.SocialButton--readOnly) {
    &:hover {
      background-color: $shark;
      cursor: pointer;
  
      .SocialButton__Icon {
        color: $white;
      }
    }
  }
}