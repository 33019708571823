@import '~@styles/colors.scss';
@import '~@styles/responsive-design.scss';
@import '~@styles/mixins.scss';

.UserAccount {
  background-color: $white;
  border-radius: 40px;
  padding: 25px 0 32px;

  &__Header {
    display: flex;
    margin-bottom: 21px;
    padding: 0 25px 0 35px;
    text-decoration: none;
    border: none;
    margin-right: 0;
    width: 100%;
    outline: none;
    background-color: transparent;
    cursor: pointer;
  }

  &__User {
    display: flex;
    flex-direction: column;
    flex: 1;
    max-width: calc(100% - 60px);
    text-align: left;
  }

  &__UserName {
    @include text(16px, $mineShaft, 21px);
    min-height: 21px;
    margin-bottom: 5px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__UserBalance {
    @include text(28px, $shark, 28px, bold);
    white-space: nowrap;

    @include media('<=phone') {
      @include text(24px, $shark, 24px, bold);
    }
  }

  &__UserAvatar {
    @include size(60px);
    border-radius: 30px;
  }

  &__Menu {
    display: flex;
    flex-direction: column;

    .AppSideBar__Link {
      padding: 0 25px;
    }
  }

  .UserAccount__MenuItem {
    padding: 0;
    border-radius: 0;
    &--active {
      background-color: transparentize($color: $mineShaft, $amount: 0.95);
    }

    + .UserAccount__MenuItem {
      margin-top: 1px;
    }
  }
}