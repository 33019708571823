@import '~@styles/mixins.scss';
@import '~@styles/colors.scss';
@import '~@styles/variables.scss';
@import '~@styles/responsive-design.scss';

.prize_thank {
  width: 100%;

  &__text {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #222222;
    margin-bottom: 20px;
  }

  &__adress {
    font-size: 20px;
    line-height: 24px;
    color: #222222;
    margin-bottom: 188px;
  }

  &__nav-adress {
    margin-bottom: 22px;
    text-align: center;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #F14E3A;
    text-decoration: none;
    display: block;
  }
}

@media (min-width: 1200px) {
  .prize_thank {

    .prize_congratulation__arrow {
      display: none;
    }

    .prix_in__title {
      text-align: center;
      margin-bottom: 110px;
      margin-top: 100px;
    }

    &__text {
      text-align: center;
    }

    &__adress {
      text-align: center;
      margin-bottom: 94px;
    }

    &__wrapper {
      display: flex;
      flex-direction: column
    };

    &__nav-adress {
      order: 2;
      margin-bottom: 100px;
    }

    &__button {
      width: 100%;
      display: flex;
      justify-content: center;

      a {
        width: 340px;
      }
    }

    .prix_in__nav {
      margin-bottom: 20px;
    }
  }
}
