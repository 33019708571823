@import '~@styles/colors.scss';
@import '~@styles/mixins.scss';
@import '~@styles/responsive-design.scss';

.UIMenu {
  background-color: #fff;
  padding: 10px 0 0;
  border-radius: 20px;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0;
  z-index: 10000;
  width: 100%;
  top: 0;
  height: 100vh;
  cursor: initial;

  &__item {
    @include text(16px, $mineShaft, 20px);
  }

  @include appearing();

  &__option {
    &--text {
      @include text(16px, $mineShaft, 20px, 500);
      padding: 0 10px;
    }

    + .UIMenu__option:not(:empty) {
      margin-top: 15px;
    }
  }
}

.UIMenuContent {
  display: flex;
  flex-direction: column;
  padding: 15px 20px;
  padding-top: 0;
  position: relative;

  + .UIMenuContent {
    border-top: 1px solid rgba($color: $shark, $alpha: 0.1);
  }
}

@include media('>=desktop') {
  .UIMenuContent {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 640px;
    width: 1170px;
    margin: 0 auto;
    padding: 0;
    padding-top: 20px;
  }
}


