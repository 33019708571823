@import '~@styles/colors.scss';
@import '~@styles/responsive-design.scss';
@import '~@styles/mixins.scss';

.dots-stage_3 {
    &__box {
        margin-bottom: 30px;
    }
}

.dots__button--transparent {
    background-color: white;
    border: 1px solid #EBEBEB;
}