.dots__button {
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    text-align: center;
    cursor: pointer;
    color: black;
}

.input-wrapper-field-label-kazan {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #333333;
    width: 100% !important;
    margin-top: 30px !important;
    margin-bottom: 10px !important;
}

.dots-p {
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #333333;
}

.bigForm {
    .ecocoin {
        bottom: 40px;
    }
}

.dots__wrapper-image-first-block {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
}

.dots__text-first-block {
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
}

.dots__wrapper-images-second {
    overflow-y: scroll;
    width: 100%;

    &>div {
        width: 200px;
        display: flex;
    }
}

.dots__wrapper-image-second {
    display: flex;

    &>div {
        margin-right: 20px;
        flex-direction: column;

        &>p {
            font-weight: 400;
            font-size: 15px;
            line-height: 21px;
        }
    }

    &>div>div {
        width: 180px;
        height: 180px;
        margin-right: 0;

        &>img {
            border-radius: 30px;
            height: 100%;
            width: 100%;
        }
    }
}

.dots__item-first-block {
    text-align: center;

    div {
        margin-bottom: 5px;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
    }
}

.dots-stage_2__desc.kazan {
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
}

.MuiInputBase-root.dots-select fieldset {
    border: none;
}

.MuiInputBase-root.dots-select .css-p0rm37 {
    color: #333 !important;
    opacity: .3;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
}

.css-12jl4iq .MuiInput-root {
    width: 100%;
}

.dots__button.kazan {
    border-radius: 15px;
    height: 60px;
    margin-bottom: 30px;
}

.bigFormKazan {
    .icon-star {
        background: #F9D606 !important;
        border-radius: 10px 10px 0 0 !important;
        padding: 5px 20px !important;
        top: -28px !important;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #333333;
    }

    .dots__input>div:before {
        border: 1px solid #EBEBEB;
        height: 58px;
        max-height: 60px;
    }

    .input-wrapper-field {
        height: 60px;
    }

    input {
        padding-bottom: 16px;
        padding-left: 20px;
    }

    .dots-cooperation {
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #333333;
        opacity: 0.3;
        margin-bottom: 10px;

        a {
            text-decoration: underline;
        }
    }
}

.bigFormKazan .Mui-error:after {
    border: 1px solid #d32f2f !important;
    height: 58px;
}

.bigFormKazan .Mui-error {
    color: #333333 !important;
}

.bigFormKazan .input-wrapper-field {
    color: #d32f2f;
    font-size: 14px;
}

@media screen and (min-width: 1366px) {
    .bigFormKazan .dots__flex-container {
        justify-content: right;
    }

    .bigForm {
        .dots__button {
            margin-right: 20px;
        }

        .ecocoin {
            bottom: 40px;
        }
    }

    .dots__firs-block-cosmetic {
        display: flex;
        flex-direction: row-reverse;
        margin-bottom: 30px;

        .dots__grey-text {
            font-weight: 400;
            font-size: 15px;
            line-height: 21px;
            color: #333333;
        }

        .dots__text-first-block {
            font-weight: 700;
            font-size: 30px;
            line-height: 42px;
            margin-left: 0;
            margin-right: auto;
        }
    }

    .dots__text-first-block {
        font-weight: 700;
        font-size: 30px;
        line-height: 42px;
        margin-left: 0;
        margin-right: auto;
        margin-bottom: 20px;
    }

    .dots__list-first-block {
        display: flex;

        li {
            margin-right: 10px;
            display: flex;

            svg {
                transform: rotate(270deg);
                margin-left: 10px;
            }
        }
    }

    .dots__text-first-block-d {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-right: auto;
    }

    .dots__wrapper-images-second {
        overflow-y: inherit;
    }
}