@import '~@styles/colors.scss';
@import '~@styles/mixins.scss';

.ymaps-2-1-68-svg-icon {
  background-image: url(../img/svg/balon.svg) !important;
}
.modal-blog {
  position: absolute;
  z-index: 999;
}
.modal-blog__link {
  text-decoration: none;
  color: inherit;
}
.ymaps-2-1-68-svg-icon-content {
  font-family: 'Muller' !important;
  font-size: 22px !important;
  top: 10px!important;
}

.clusterer-list {
  max-height: 200px;
  padding: 0;
  margin: 0;
  overflow: auto;
}

.clusterer-list__icon {
  width: 43px;
  height: 43px;
  margin-right: 5px;
}

.clusterer-list__item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
}

.clusterer-list__item:not(:last-child) {
  margin-bottom: 5px;
}

* {
  font-family: 'Muller';
}

.Main.noList {
  height: 100%;

  .sc-htpNat.bDLZdG {
    height: 100%;

    #map_container {
      height: 100%;

      // ymaps {
      //   height: 100% !important;
      // }
    }
  }
}

.Main,
.App {
  position: relative;
  overflow-x: hidden !important;
}

.Main.hidden {
  overflow: hidden !important;
}

.App.hidden {
  overflow: hidden !important;
}

@media (max-width: 799px) {
  .Main,
  .App {
  overflow: scroll;
  }
}

.App {
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  overflow: scroll;
}

.scrollBar > div::-webkit-scrollbar {
  display: none;
}

.Main {
  width: 100%;
}

.TopBox {
  &__Store {
    padding: 0 13px;
  }

  &__Icon {
    color: $silverChalice;
    @include fixedSize(25px);
  }

  &__Link {
    @include fixedSize(25px);
    transition: transform .2s ease-in;

    + .TopBox__Link {
      margin-left: 10px;
    }

    &:hover {
      transform: scale(1.1);
    }

    &--Google {
      @include fixedSize(23px);
    }
  }
}

.controls-map {
  position: absolute;
  z-index: 9999;
  right: 40px;
  top: 110px;
}

.main-block-container {
  width: 100%;
  position: absolute;
  z-index: 11000;
  top: 0;
  background: #fff;
  display: flex;
  justify-content: space-between;
  height: 57px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
  padding: 0 25px;
}

@media (max-width: 800px) {
  .main-block-container {
    display: none;
  }
}

.ymaps-2-1-68-b-cluster-tabs__menu-item_current_yes {
  background: transparent !important;
}
.ymaps-2-1-68-balloon__layout {
  background: #f9d606 !important;
  -webkit-box-shadow: 0 3px 25px -7px rgba(0, 0, 0, 0.2) !important;
  box-shadow: 0 3px 25px -7px rgba(0, 0, 0, 0.2) !important;
  padding: 10px 5px 10px 16px !important;
  -webkit-box-sizing: border-box !important;
  -moz-box-sizing: border-box !important;
  box-sizing: border-box !important;
  -webkit-border-radius: 15px !important;
  border-radius: 15px !important;
  min-width: 260px !important;
}
.ymaps-2-1-68-balloon__content {
  background-color: transparent !important;
  font-size: 11px !important;
  line-height: 1.2 !important;
}
.ymaps-2-1-68-balloon-content__header {
  font-size: 14px !important;
  line-height: 1 !important;
  font-weight: bold !important;
  margin-bottom: 5px !important;
}
.ymaps-2-1-68-balloon {
  left: 5px !important;
  box-shadow: none !important;
}
.ymaps-2-1-68-balloon__tail {
  display: none !important;
}
.ymaps-2-1-68-hint{
  background: transparent !important;
  border: none !important;
  padding: 0 !important;
  box-shadow: none !important;

}
.ymaps-2-1-68-hint__text {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
  padding: 0 !important;
}
.hintText {
  white-space: normal !important;
  width: 300px !important;
  text-align: left !important;
  word-wrap: break-word !important;
  background-color: transparent !important;
}

.ymaps-2-1-68-outer-panes {
  min-width: 260px !important;
  max-width: 500px !important;
}

.ymaps-2-1-68-outerHint-pane {
  min-width: 260px !important;
  max-width: 500px !important;
}
.modal-box {
  position: absolute;
  top: 209px;
  z-index: 9999;
  left: 50%;
  transform: translateX(-50%);
}

.modal-box--new {
  left: 50%;
  transform: translateX(-50%);
}

.with-custom-scroll {
  padding-right: 0 !important;
}

.form-with-custom-scroll {
  padding-right: 40px !important;
}

.react-select__control {
  width: 100% !important;
  border: 0 !important;
  border-bottom: 1px solid #f9d606 !important;
  outline: transparent !important;
  font-family: 'Muller' !important;
  font-size: 18px !important;
  line-height: 1.5 !important;
  font-weight: normal !important;
  color: #000000 !important;
  padding: 4px 0 !important;
  -webkit-box-sizing: border-box !important;
  box-sizing: border-box !important;
  background-color: transparent !important;
}

textarea {
  resize: none;
}

.filter-blocks {
  width: 100%;
  height: 100%;
}

.error .react-select__control {
  border-color: #ff001d !important;
  color: #ff001d !important;
}

.react-select__control-is-focused {
  border: 0 !important;
  box-shadow: none;
}

.fix-height {
  height: 670px !important;
}
.active-time {
  width: 260px !important;
  height: 50px !important;
  border-radius: 10px !important;
  border: solid 1px black !important;
  font-family: 'Muller';
  font-size: 17px;
  line-height: 1;
  font-weight: 500;
  text-decoration: none;
  background-color: transparent !important;
  word-wrap: normal;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}
.active-time img {
  width: 35px;
  height: 35px;
}

.active-time:hover {
  color: #000000 !important;
}

.DayPickerInput {
  width: 100%;
}
.DayPickerInput input {
  width: 100%;
  border: 0;
  border-bottom: 2px solid #f9d606;
  outline: transparent;
  font-family: 'Muller';
  font-size: 34px;
  line-height: 1.5;
  font-weight: normal;
  background-color: transparent;
  color: #000000;
  padding: 4px 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.DayPickerInput input::-webkit-input-placeholder {
  color: #cccccc;
}
.DayPickerInput input:-moz-placeholder {
  color: #cccccc;
}
.DayPickerInput input::-moz-placeholder {
  color: #cccccc;
}
.DayPickerInput input:-ms-input-placeholder {
  color: #cccccc;
}
.DayPickerInput input::placeholder {
  color: #cccccc;
}
.react-select__control {
  box-shadow: none!important;
  border-radius: 0!important;
}
.react-select__placeholder {
  color: #ccc!important;
}
.error .DayPickerInput input {
  border-color: #ff001d;
  color: #ff001d;
}
.track-vertical-custom {
  width: 5px !important;
  right: 5px;
  top: 0;
  bottom: 0;
  background-color: transparent;
  border-radius: 7px!important;
}

.track-vertical-custom div {
  opacity: 0.1;
  background-color: #000000 !important;
  border-radius: 7px!important;
}
.track-vertical-custom:hover div:hover {
  opacity: 0.2;
  background-color: #000000 !important;
}
.services-block-children {
  list-style-type: none;
  overflow: hidden;
  cursor: pointer;
}

.map_container {
  width: 100%;
  height: 50vh;
}

.spinner {
  transition: all 0.3s linear;
  animation: spinner-spin 1s linear infinite;
  width: 52px;
  height: 52px;
  border: 4px solid transparent;
  border-top: 4px solid #f9d606;
  margin: auto;
  border-radius: 100%;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
@keyframes spinner-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.preload {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99999;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ymaps-2-1-72-hint {
  padding: 0!important;
  box-shadow: none!important;
}
.ymaps-2-1-72-hint__text {
  padding: 0!important;
  background: none!important;
}
.ymaps-2-1-73-hint {
  padding: 0!important;
  box-shadow: none!important;
}
.ymaps-2-1-73-hint__text {
  padding: 0!important;
  background: none!important;
}

ymaps[class$="-hint__text"] {
  padding: 0!important;
  background: none!important;
}

ymaps > ymaps[class$="-hint"] {
  padding: 0!important;
  background: none!important;
  box-shadow: none!important;
}

.hint {
  white-space: normal;
  min-width: 120px;
  max-width: 200px;
  padding: 10px 15px;
  color: #000;
  font-size: 11px;
  line-height: 13px;
  font-family: 'Muller', sans-serif;;
  background-color: #f9d609;
  border-radius: 10px;
  box-shadow: 0 5px 10px 0 rgba(0,0,0,0.07);
  overflow: hidden;
}
.mapIconTemp {
  width: 22px;
  height: 22px;
  transform: translate(28px, -2px);
}

.mapIconStar {
  width: 15px;
  height: 15px;
  transform: translate(28px, -7px);
  background-image: url(../img/svg/star.svg);
}

.mapIconActiveStar {
  width: 15px;
  height: 15px;
  transform: translate(28px, -7px);
  background-image: url(../img/svg/active-star.svg);
}

.mapIconShadowed {
  width: 43px;
  height: 43px;
  background: #ededed;
  opacity: 0.6;
  border-radius: 40px;
}

@media (max-width: 800px) {
  .Main,
  .App {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: scroll;
    position: relative;
    min-width: 100%;
  }

  .scrollBar {
    overflow: scroll !important;
    max-width: 799px;
    width: 100% !important;
  }
  
  .controls-map {
    display: none;
  }
  .services-block {
    display: none;
  }
  #root > div > div.Main > div:nth-child(5) > button {
    display: none;
  }
  .main-block{
    display:none;
  }
  .point-modal {
    width: 100vw;
    top: 97px
  }
  .spinner{
    top: 42%;
    left: 42%;
  }

  .services-block-wrapper{
    position: absolute;
    left:0;
    bottom: 0;
    padding: 0;
    border-radius: 0;
    width: 100%;
    z-index: 100;
    display: none;
  }

  /* .map_container {
    height: -webkit-fill-available;
  } */
}

@media screen and (min-width: 1366px) {
  .controls-map {
    top: 85vh;
  }

  .GeoContainer {
    top: 88vh;
  }
}
