.rekvizity p {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #222222;
  font-family: 'Mabry Pro';
}

.rekvizity-wrapper span {
  margin-left: 5px;
  font-family: 'Mabry Pro';
}

.rekvizity-wrapper div {
  font-family: 'Mabry Pro';
}

@media (min-width: 1366px) {
  .rekvizity p {
    font-size: 20px;
    line-height: 24px;
  }

  .rekvizity-wrapper {
      max-width: 570px;
  }

  .rekvizity {
    .CONTAINER {
        margin: 0 auto;
        max-width: 1170px;
      }
  }
}

.rekvizity {
  margin-bottom: 90px;
}
