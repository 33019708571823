@import '~@styles/mixins.scss';
@import '~@styles/colors.scss';
@import '~@styles/responsive-design.scss';

.CityPopover {
  z-index: 12001;

  &__MoreMenu {
    .Popover-tip {
      display: none;
    }

    .CityPopover__Container {
      background-color: $gold;
    }
  }

  .UIRoundedButton + .UIRoundedButton {
    margin-top: 10px;
  }

  &__Container {
    background-color: $white;
    padding: 25px;
    box-shadow: 0px 2px 10px rgba(0,0,0,0.25);
    border-radius: 10px;
  }

  &__Title {
    @include text(16px, $mineShaft, 20px, 500);
    margin: 0 0 25px;
    text-align: center;
  }

  .Popover-body {
    transform: translateY(-5px);
  }

  .Popover-tip {
    fill: $white;
  }
}
