@import '~@styles/colors.scss';
@import '~@styles/mixins.scss';
@import '~@styles/responsive-design.scss';

.UIRoundedButton {
  height: 40px;
  @include text(16px, $mineShaft, 16px, 500);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  border: none;
  padding: 10px 25px;
  outline: none;
  cursor: pointer;
  white-space: nowrap;
  text-decoration: none;
  transition: all 0.1s ease;
  width: 100%;

  &--primary,
  &--danger,
  &--primary-to-link {
    background-color: $wildSand;
  }

  &--border {
    background-color: $white;
    border: 1px solid $black;
  }

  &--king {
    border-radius: 30%;
    width: 100%;

    &:hover {
      box-shadow: none!important;
    }

    &:not(:disabled) {
      box-shadow: 0px 2px 5px rgba(249, 214, 6, 0.3);
    }
  }

  &--secondary,
  &--king {
    background-color: $gold;
  }

  &--white,
  &--white-king {
    background-color: $white;
  }

  &--transparent {
    background-color: transparentize($color: #000000, $amount: 0.95);
  }

  &.UIRoundedButton--partner {
    @include text(10px, $mineShaft, 10px);
    padding-top: 15px;
    padding-bottom: 15px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  }

  &.UIRoundedButton--link,
  &.UIRoundedButton--link--danger {
    @include linkButton();
  }

  &.UIRoundedButton--no-padding {
    padding: 0;
    height: auto;
    margin: 0;
  }

  &.UIRoundedButton--link--danger {
    &:hover {
      color: $flamingo;
    }
  }

  &.UIRoundedButton--primary-to-link {
    @include media('<tablet') {
      @include linkButton();
    }
  }

  &.UIRoundedButton--danger {
    &:hover {
      background-color: $flamingo;
      color: $white;
    }
  }

  &--left {
    justify-content: flex-start;
  }

  &:disabled,
  &:disabled:hover,
  &:disabled:active {
    background-color: $wildSand;
    color: transparentize($color: $mineShaft, $amount: 0.7);
    cursor: auto;
  }

  &:hover,
  &:active {
    background-color: $mineShaft;
    color: $white;
  }

  &.link--fadeInOut {
    &:hover,
    &:active {
      transition: color 0.1s ease;
      color: transparentize($color: $mineShaft, $amount: 0.3);
    }
  }

  &.no-radius {
    border-radius: 0;
  }

  &--large, &--king, &--white-king {
    height: 60px;
    width: 100%;
    @include text(21px, $mineShaft, 21px, 500);
    border-radius: 40px;

    @include media('<tablet') {
      @include text(18px, $mineShaft, 18px, 500);
    }
  }
}
