.ads {
  width: 100%;

  &__block {
    border-bottom: 0.5px solid #777777;
    margin-bottom: 30px;
  }

  &__title {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #222222;
    margin-bottom: 15px;
  }

  &__description {
    font-weight: 400;
    font-size: 20px;
    line-height: 19px;
    color: #222222;
    margin-bottom: 15px;
    margin-bottom: 50px;

    &+div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 50px;
    }
  }

  .ads__cooperation a {
    font-size: 12px;
    color: rgba(34,34,34,0.8);
  }

  a {
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: #060606;
    text-decoration: none;

    svg {
      margin: 0 5px;
    }
  }

  &__wrapper-img {
    display: flex;
    justify-content: center !important;

    &+div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 50px;
    }

    &:last-child + div {
      margin-bottom: 0 !important;
    }
  }

  &__cooperation {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: rgba(34, 34, 34, 0.8);
    margin-bottom: 40px;
  }

  &__m0 {
    margin-bottom: 0 !important;
  }

  &__wrapper-img-desk {
    display: none;
  }
}

@media screen and (min-width: 1366px) {
  .ads {
    .CONTAINER {
      max-width: 1170px;
      margin: 0 auto;
    }

    &__wrapper {
      max-width: 641px;
      margin-right: 150px;
    }

    &__block {
      display: flex;
    }

    &__wrapper-img-desk {
      display: block;
      position: relative;
      bottom: -4px;
    }

    &__links {
      justify-content: left !important;
      margin-bottom: 40px !important;

      a {
        margin-right: 70px;
      }
    }

    &__wrapper-img {
      display: none !important;
    }

    &__wrapper-img-desk {

    }
  }
}
