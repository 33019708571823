@import '~@styles/colors.scss';
@import '~@styles/responsive-design.scss';
@import '~@styles/mixins.scss';

.dots-stage_3 {
    &__box {
        margin-bottom: 30px;
    }
}

.dots__button--transparent {
    background-color: white;
    border: 1px solid #EBEBEB;
}

@media (min-width: 1366px) {
    .dots-stage_2__desc--fol {
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        margin-top: 50px !important;
    }

    .dots-formgroup-checkbox {
        height: 200px;
        flex-wrap: wrap;
        display: flex;

        label {
            margin-right: 40px;
        }
    }

    .dots-stage_2__formControl--radio > div {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        
    }

    .dots-stage_2__formControl--radio {
        margin-bottom: 0 !important;
    }

    .dots__flex-container.max {
        width: 100% !important;
    }
}