@import '~@styles/colors.scss';
@import '~@styles/responsive-design.scss';
@import '~@styles/mixins.scss';

.dispNone {
    display: none;
}

.dots__button.default {
    border: 1px solid #333333;
    background-color: #ffffff;
    margin-top: 58px;
    color: #333333;
}

@media (min-width: 1366px) {
    .dots-stage_5 {
        margin-bottom: 364px;
    }

    .dots-stage_5__buttons {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 80px;
    }

    .dots__button.default {
        margin-top: 0;
    }
}