.contacts {
  width: 100%;
  padding-bottom: 50px;

  a {
    color: #060606;
    text-decoration: none;
    cursor: pointer;
  }

  .dots__input label, input {
    padding-left: 0 !important;
  }

  .dots__input label {
    transform: translateX(0);
  }

  .css-1ptx2yq-MuiInputBase-root-MuiInput-root.Mui-error:after {
    border-bottom-color:#EBEBEB;;
  }

  &__left {
    margin-bottom: 35px;
  }

  &__bold-text {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #222222;
    margin-bottom: 15px;
  }

  &__normal-text {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #222222;
  }

  .form__title {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #222222;
  }

  &__right-wrapper {
    background: #FFFFFF;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.06);
    border-radius: 30px;
    padding: 50px 18px;
    box-sizing: border-box;
  }

  .dots__input input,
  .dots__input label {
    font-weight: 400;
    font-size: 15px;
    line-height: 15px;
    color: #060606;
  }

  .dots__input fieldset {
    border-top: none;
    border-right: none;
    border-left: none;
  }

  .form-checkbox-contacts {
    margin-top: 30px;
    margin-bottom: 30px;

    span {
      font-style: normal;
      font-weight: 300;
      font-size: 13px;
      line-height: 13px;
      color: #060606;
    }
  }

  .about-sub__button.contacts-b {
    width: 100%;
    height: 57px;
    border: none;
    cursor: pointer;
  }

  .contacts-label {
    font-weight: 400;
    font-size: 16px;
    line-height: 15px;
    color: #060606;
    margin-top: 30px;
    margin-bottom: 16px;
  }
}

@media screen and (min-width: 1366px) {
  .contacts {
    .CONTAINER {
      margin: 0 auto;
      max-width: 1170px;
    }

    &__wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__right-wrapper {
      max-width: 729px;
      padding: 80px 50px;
    }

    .about-sub__button.contacts-b {
      width: 383px;
      height: 75px;
      margin: 0 auto;
    }

    &__left-wrapper {
      max-width: 470px;
      margin-right: 130px;
    }
  }
}

.whiteBtn {
  background-color: #FFFFFF;
  border: 1px solid #222222 !important;
  cursor: initial !important;
}
