.dots__button {
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    text-align: center;
    cursor: pointer;
    color: black;
}

.bigForm {
    .ecocoin {
        bottom: 40px;
    }
}

.MuiInputBase-root.dots-select fieldset {
    border: none;
}

.MuiInputBase-root.dots-select .css-p0rm37 {
    color: #333 !important;
    opacity: .3;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
}

.css-12jl4iq .MuiInput-root {
    width: 100%;
}

@media screen and (min-width: 1366px) {
    .bigForm {
        .dots__button {
            margin-right: 20px;
        }

        .ecocoin {
            bottom: 40px;
        }
    }
}