@import '~@styles/mixins.scss';
@import '~@styles/colors.scss';
@import '~@styles/variables.scss';
@import '~@styles/responsive-design.scss';

// .AppBar__top+div {
//   margin-bottom: 20px;
// }

.prix_in {
  margin-bottom: 50px;

  &__price {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 16px;
    padding-top: 16px;
    border-bottom: 1px solid #060606;
    border-top: 1px solid #060606;
    margin-bottom: 10px;
  }

  &__block-price {
    display: flex;
  }

  &__logo {
    margin-right: 15px;
  }

  &__number {
    font-weight: 700;
    font-size: 25px;
    line-height: 30px;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: #060606;
    display: flex;

    &__euro {
      margin-left: 6px;
    }
  }

  &__container {
    padding: 0 20px;
  }

  &__nav {
    width: 100%;
    background: #F9D606;
    border-radius: 40px;
    text-decoration: none;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #060606;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 30px;
    cursor: pointer;
  }

  &__title {
    color: #060606;
    font-weight: 350;
    font-size: 32px;
    line-height: 32px;
    font-weight: 350;
    margin-bottom: 28px;
  }

  p {
    margin: 0;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #222222;
    font-family: 'CoFo Robert';
    margin-bottom: 28px;
  }

  &__cards {
    display: flex;
  }

  &__wrapper-price {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 17px 0;
  }

  &__price-number {
    font-weight: 700;
    font-size: 32px;
    line-height: 120%;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: #060606;
    display: flex;
    align-items: flex-start;
  }

  &__euro-small {
    height: 15px;
  }

  &__wrapper-img {
    width: 100%;
    height: auto;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .prix_in__title.mobile {
    display: block;
  }

  .prix_in__title.desktop {
    display: none;
  }
}

@media (min-width: 1200px) {
  .prix_in {
    &__wrapper-desktop {
      display: flex;
      justify-content: space-between;
    }

    &__right-block {
      order: 2;
      width: 40%;
    }

    &__left-block {
      max-width: 570px;
      display: flex;
      flex-direction: column;
      height: auto;
      justify-content: space-between;
    }

    &__price {
      max-width: 570px;
      border-top: none;
    }

    &__wrapper-price {
      display: none;
    }

    &__nav {
      order: 3;
      width: 340px;
      margin-bottom: 0;
    }

    &__container {
      max-width: 1170px;
      margin: 0 auto;
    }

    .prizes__card-wrapper-img {
      width: 190px;
      height: 190px;
    }

    .prizes__card-default {
      width: 300px;
      display: flex;
      align-items: center;
      flex-direction: column;
      padding: 40px 55px;
      height: 430px;
      border: none;

      &:hover {
        border: 0.5px solid #999999;
      }
    }

    .prix_in__cards {
      flex-wrap: wrap;
      max-width: 1170px;
      margin: 0 auto;
    }

    .prizes__title-purchases {
      color: #777777;
      font-size: 14px;
      margin-bottom: 30px;
    }

    .prizes__title-text {
      margin-bottom: 20px;
      font-size: 20px;
      line-height: 24px;
    }

    .prizes__title-number {
      font-size: 25px;
    }

    .prix_in__title.mobile {
      display: none;
    }

    .prix_in__title.desktop {
      display: block;
      font-weight: 700;
      font-size: 30px;
      line-height: 32px;
      text-transform: uppercase;
      color: #000000;
      padding-bottom: 30px;
      border-bottom: 0.5px solid #060606;
      margin-bottom: 30px;
      margin-top: 74px;

    }
  }
}
