@import '~@styles/colors.scss';
@import '~@styles/mixins.scss';
@import '~@styles/responsive-design.scss';


.Dropzone {
  position: relative;
  outline: none;

  &__Area {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 500px;
    min-height: 190px;
    background-color: $wildSand;
    border-radius: 20px;
    outline: none;
    cursor: pointer;

    &:hover {
      background-color: $gold;

      .Dropzone__Icon, .Dropzone__Text {
        color: $mineShaft;
      }
    }
  }

  &__Icon {
    margin-bottom: 10px;
    color: $silver;
  }

  &__Close {
    position: absolute;
    top: -20px;
    right: -20px;
    padding: 0;
    width: 40px;
    height: 40px;

    svg {
      width: 10px;
      height: 10px;
    }
  }

  &__Text {
    @include text(16px, $shark, 1.2);
    margin: 0;
    color: $silver;
    @include media('<tablet') {
      font-size: 15px;
    };
  }

  &__Placeholder {
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: 220px;
    text-align: center;
    user-select: none;
  }

  &__Preview {
    border-radius: 5px;
    overflow: hidden;

    img, video {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  &--mobile {
    input {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: block;
      width: 100%;
      min-width: 100%;
      filter: alpha(opacity=0);
      opacity: 0;
      outline: none;
      background: transparent;
    }
  }
}
