@import '~@styles/colors.scss';
@import '~@styles/mixins.scss';


.PrizeCard {
  display: flex;
  flex-direction: column;
  max-width: 170px;
  min-height: 183px;
  border-radius: 20px;
  background-color: $white;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  overflow: hidden;

  &__Image, &__Content {
    flex-grow: 1;
    flex-basis: 0;
    text-align: center;
  }

  &__Image {
    min-height: 170px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  &__Content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 15px;

  }

  &__Text, &__Amount {
    margin: 0;
  }

  &__Text {
    @include text(10px, $mineShaft, 1.2);
    margin-bottom: 5px;
  }

  &__Amount {
    @include text(16px, $mineShaft, 1, 800);
    margin-bottom: 10px;
    white-space: nowrap;
  }

  &__Button {
    width: 100%;

    svg {
      position: relative;
      top: -2px;
      margin-right: 10/16 + em;
      width: 18px;
      height: 19px;
    }
  }
}
